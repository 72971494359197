import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import FormModal from "components/modal/FormModal";
import AlertModal from "components/modal/AlertModal";
import {
  checkTruckTypeFromTruckCode,
  createTruckType,
  getTruckType,
} from "../../../../utils/api/masterData/truckType";
import { getAllListTruckDrivingLicense } from "utils/api/masterData/register";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./CreateTruckTypeModal.module.css";

const CreateTruckTypeModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const last_current_form = 4;
  const [currentForm, setCurrentForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [listTruckDrivingLicense, setListTruckDrivingLicense] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: {},
  });
  const form_confim_btn = useRef(null);

  useEffect(() => {
    const getListTruckDrivingLicense = async () => {
      try {
        const listTruckDrivingLicense_resp =
          await getAllListTruckDrivingLicense();
        if (listTruckDrivingLicense_resp.data.code == 1000) {
          setListTruckDrivingLicense(
            listTruckDrivingLicense_resp.data.data.results
          );
        }
      } catch (error) {
        console.log("error CreateTruckTypeModal: ", error);
      }
    };

    getListTruckDrivingLicense();
  }, []);

  const updateVolumeField = () => {
    const width = getValues("width") ? getValues("width") : null;
    const height = getValues("height") ? getValues("height") : null;
    const length = getValues("length") ? getValues("length") : null;

    if (width && height && length) {
      const volume = parseFloat(width * height * length).toFixed(2);
      setValue("volume", volume);
      clearErrors("volume");
    } else {
      setValue("volume", null);
      setError("volume", "");
    }
  };

  const createTruckTypeData = async () => {
    if (currentForm < last_current_form) {
      if (currentForm === 1) {
        setIsLoading(true);
        try {
          const resp = await checkTruckTypeFromTruckCode(
            getValues().truck_code
          );

          if (!R.isNil(resp.data.data.results)) {
            setError(`truck_code`, {
              message: `มีรหัสประเภทรถนี้ในระบบแล้ว`,
            });
          } else {
            clearErrors(`truck_code`);
            setCurrentForm(currentForm + 1);
          }
        } catch (error) {
          console.log("error", error);
        }

        setIsLoading(false);
      } else {
        setCurrentForm(currentForm + 1);
      }
    } else {
      try {
        setIsLoading(true);

        let _truckInfo = getValues();
        let order_number = 1;

        const obj_truck_type = await getTruckType({
          page: null,
          per_page: null,
        });
        if (obj_truck_type.data.code === 1000) {
          order_number = obj_truck_type.data.data.results.length + 1;
        }

        _truckInfo = {
          ..._truckInfo,
          has_tail: _truckInfo.has_tail === `true` ? true : false,
          // freight_minimum: _truckInfo.truck_freight.freight_minimum,
          // truck_freight_km: _truckInfo.truck_freight.truck_freight_km,
          order_number: order_number,
          active: true,
        };
        _truckInfo = R.omit(["truck_freight"], _truckInfo);

        await createTruckType(_truckInfo)
          .then((create_resp) => {
            if (create_resp.data.code === 1000) {
              setAlertModal({
                open: true,
                title: `บันทึก`,
                description: `บันทึกข้อมูลสำเร็จ`,
              });
              // setOpen(false);
            }
          })
          .catch((error) => {
            console.log("error createTruckType: ", error);
            setAlertModal({
              open: true,
              title: `บันทึกไม่สำเร็จ`,
              description: error.response.data.description,
            });
            // setOpen(false);
          });

        setOpen(false);
        setIsLoading(false);
      } catch (error) {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description,
        });
        setIsLoading(false);
        setOpen(false);
      }
    }
  };

  const renderForm1 = () => {
    const form1_menu = [
      {
        name: "group",
        field_type: "select",
        field_label: "กลุ่มประเภทรถ*",
        rules: {
          validate: (value) => {
            if (R.isNil(value) || R.isEmpty(value)) {
              return "กรุณาเลือกกลุ่มประเภทรถ";
            }
            return true;
          },
        },
        options: [
          { value: "รถกระบะ 4 ล้อ", name: `รถกระบะ 4 ล้อ` },
          { value: "รถบรรทุก 6 ล้อ", name: `รถบรรทุก 6 ล้อ` },
          { value: "รถบรรทุก 10 ล้อ", name: `รถบรรทุก 10 ล้อ` },
          { value: "รถบรรทุก 18 ล้อ", name: `รถบรรทุก 18 ล้อ` },
          { value: "รถบรรทุก 22 ล้อ", name: `รถบรรทุก 22 ล้อ` },
        ],
      },
      {
        name: "truck_code",
        field_type: "text",
        field_label: "รหัสประเภทรถ*",
        rules: {
          required: "กรุณาใส่รหัสประเภทรถ",
        },
      },
      {
        name: "name",
        field_type: "text",
        field_label: "ประเภทรถ*",
        rules: {
          required: "กรุณาเลือกประเภทรถ",
        },
      },
      {
        name: "image",
        nameDescription: "(ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น)",
        field_type: "upload",
        field_label: "รูปภาพรถ*",
        rules: {
          required: "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB",
          validate: async (file) => {
            const { width: image_width, height: image_height } =
              await helper.convertFileSizeToPixel({ file: file });

            if (!helper.isJpgFile({ file: file })) {
              return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
            } else if (helper.isFileOverSize({ file: file, size: 10 })) {
              return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
            } else if (image_width !== 175 && image_height !== 50) {
              return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
            }

            return true;
          },
        },
      },
      {
        name: "has_tail",
        field_type: "select",
        field_label: "มีหาง*",
        onChange: (value) => {
          if (value === false) {
            setValue("min_cover_insurance_tail", null);
            setValue("premium_per_trip_tail", null);
          }
        },
        rules: {
          validate: (value) => {
            if (R.isNil(value) || R.isEmpty(value)) {
              return "กรุณาเลือกสถานะมีหาง";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
      },
      {
        name: "width",
        field_type: "text",
        field_label: "ความกว้างของกระบะบรรทุก (เมตร)*",
        onBlur: (e) => {
          const value = parseFloat(getValues().width).toFixed(2);
          if (!isNaN(value)) {
            setValue(`width`, value);
          } else {
            setValue(`width`, parseFloat(0).toFixed(2));
            clearErrors(`width`);
          }
          updateVolumeField();
        },
        rules: {
          required: "กรุณาใส่ความกว้างของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "length",
        field_type: "text",
        field_label: "ความยาวของกระบะบรรทุก (เมตร)*",
        onBlur: () => {
          const value = parseFloat(getValues().length).toFixed(2);
          if (!isNaN(value)) {
            setValue(`length`, value);
          } else {
            setValue(`length`, parseFloat(0).toFixed(2));
            clearErrors(`length`);
          }
          updateVolumeField();
        },
        rules: {
          required: "กรุณาใส่ความยาวของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "height",
        field_type: "text",
        field_label: "ความสูงของกระบะบรรทุก (เมตร)*",
        onBlur: () => {
          const value = parseFloat(getValues().height).toFixed(2);
          if (!isNaN(value)) {
            setValue(`height`, value);
          } else {
            setValue(`height`, parseFloat(0).toFixed(2));
            clearErrors(`height`);
          }
          updateVolumeField();
        },
        rules: {
          required: "กรุณาใส่ความสูงของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "volume",
        variant: "filled",
        disabled: true,
        field_type: "text",
        field_label: "ปริมาตรของกระบะบรรทุก (เมตร)*",
        rules: {
          required: "",
        },
      },
    ];

    return (
      currentForm === 1 && (
        <div>
          {form1_menu.map((menu, index) => {
            return (
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />
            );
          })}
        </div>
      )
    );
  };

  const renderForm2 = () => {
    const form2_menu = [
      {
        name: "weight",
        field_type: "text",
        field_label: "นํ้าหนักบรรทุก (กิโลกรัม)*",
        rules: {
          required: "กรุณาใส่นํ้าหนักบรรทุก (กิโลกรัม)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "consumption_rate",
        field_label: "อัตราการสิ้นเปลืองเชื้อเพลิง (กิโลเมตร/ลิตร)*",
        rules: {
          required: "กรุณาใส่อัตราการสิ้นเปลืองเชื้อเพลิง (กิโลเมตร/ลิตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "average_speed_per_hour",
        field_label: "ความเร็วเฉลี่ย (กิโลเมตร/ชั่วโมง)*",
        rules: {
          required: "กรุณาใส่ความเร็วเฉลี่ย (กิโลเมตร/ชั่วโมง)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_cover_insurance_head",
        field_label: "วงคุ้มครองขั้นต่ำ (ส่วนหัว*)",
        rules: {
          required: "กรุณาใส่วงคุ้มครองขั้นต่ำ (ส่วนหัว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_cover_insurance_tail",
        nameDescription: "(ถ้าไม่มีหางไม่สามารถระบุได้)",
        disabled: getValues().has_tail === "true" ? false : true,
        value: getValues().has_tail === "true" ? "" : null,
        field_label: "วงคุ้มครองขั้นต่ำ (ส่วนหาง)*",
        rules: {
          required:
            getValues().has_tail === "true"
              ? "กรุณาใส่วงคุ้มครองขั้นต่ำ (ส่วนหาง)"
              : null,
          pattern:
            getValues().has_tail === "true"
              ? {
                  value: helper.RegexValidate(`number`),
                  message: "ระบุเป็นตัวเลขได้เท่านั้น",
                }
              : null,
        },
      },
      {
        name: "premium_per_trip_head",
        field_label: "เบี้ยประกันภัยรายเที่ยว (ส่วนหัว)*",
        rules: {
          required: "กรุณาใส่เบี้ยประกันภัยรายเที่ยว (ส่วนหัว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "premium_per_trip_tail",
        nameDescription: "(ถ้าไม่มีหางไม่สามารถระบุได้)",
        disabled: getValues().has_tail === "true" ? false : true,
        value: getValues().has_tail === "true" ? "" : null,
        field_label: "เบี้ยประกันภัยรายเที่ยว (ส่วนหาง)*",
        rules: {
          required:
            getValues().has_tail === "true"
              ? "กรุณาใส่เบี้ยประกันภัยรายเที่ยว (ส่วนหาง)"
              : null,
          pattern:
            getValues().has_tail === "true"
              ? {
                  value: helper.RegexValidate(`number`),
                  message: "ระบุเป็นตัวเลขได้เท่านั้น",
                }
              : null,
        },
      },
      {
        name: "maintenance",
        field_label: "ค่าซ่อมบำรุง (บาท/กิโลเมตร)*",
        rules: {
          required: "กรุณาใส่ค่าซ่อมบำรุง (บาท/กิโลเมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
    ];

    return (
      currentForm === 2 && (
        <div>
          {form2_menu.map((menu, index) => {
            return (
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />
            );
          })}
        </div>
      )
    );
  };

  const renderForm3 = () => {
    const form3_menu = [
      {
        name: "allowance",
        field_label: "ค่าเบี้ยเลี้ยง (บาท/เที่ยว)*",
        rules: {
          required: "กรุณาใส่ค่าเบี้ยเลี้ยง (บาท/เที่ยว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "saas",
        field_label: "ค่าดำเนินการ (%)*",
        rules: {
          required: "กรุณาใส่ค่าดำเนินการ (%)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_labor_load",
        field_label: "จำนวนคนงานยกสินค้าขึ้นต่ำสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าขึ้นต่ำสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "max_labor_load",
        field_label: "จำนวนคนงานยกสินค้าขึ้นสูงสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าขึ้นสูงสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_labor_unload",
        field_label: "จำนวนคนงานยกสินค้าลงต่ำสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าลงต่ำสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "max_labor_unload",
        field_label: "จำนวนคนงานยกสินค้าลงสูงสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าลงสูงสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "labor_fee",
        field_label: "ค่าคนงานยกสินค้า*",
        rules: {
          required: "กรุณาใส่ค่าคนงานยกสินค้า",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "left_over_fee",
        field_label: "ค่าตกค้าง*",
        rules: {
          required: "กรุณาใส่ค่าตกค้าง",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
    ];

    return (
      currentForm === 3 && (
        <div>
          {form3_menu.map((menu, index) => {
            return (
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />
            );
          })}
        </div>
      )
    );
  };

  const renderForm4 = () => {
    const form4_menu = [
      {
        name: "add_drop_point_fee",
        field_label: "ค่าเพิ่มจุดส่งสินค้า (บาท/จุด)*",
        rules: {
          required: "กรุณาใส่ค่าเพิ่มจุดส่งสินค้า (บาท/จุด)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "document_return_fee",
        field_label: "ค่าเอกสารนำกลับ (บาท/งาน)*",
        rules: {
          required: "กรุณาใส่ค่าเอกสารนำกลับ (บาท/งาน)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      // {
      //   name: "truck_freight.freight_minimum",
      //   field_label: "ราคาเริ่มต้น (บาท)*",
      //   rules: {
      //     required: "กรุณาใส่ราคาเริ่มต้น (บาท)",
      //     pattern: {
      //       value: helper.RegexValidate(`number`),
      //       message: "ระบุเป็นตัวเลขได้เท่านั้น",
      //     },
      //   },
      //   error:
      //     errors.truck_freight && errors.truck_freight.freight_minimum
      //       ? !!errors.truck_freight.freight_minimum
      //       : false,
      //   helperText:
      //     errors.truck_freight && errors.truck_freight.freight_minimum
      //       ? errors.truck_freight.freight_minimum.message
      //       : false,
      // },
      {
        name: "truck_driving_license",
        nameDescription: "ระบบจะดึงจากฐานข้อมูลสามารถเลือกได้หลายรายการ",
        field_type: "select",
        field_label: "ประเภทใบขับขี่ที่สามารถขับได้*",
        multiSelect: true,
        rules: {
          required: "กรุณาเลือกประเภทใบขับขี่",
        },
        options: listTruckDrivingLicense.map((item) => {
          return {
            value: item.id,
            name: item.name,
          };
        }),
      },
    ];

    // const form4_truck_freight_km = [
    //   {
    //     km_start: 1,
    //     km_end: 100,
    //     baht_per_km: null,
    //   },
    //   {
    //     km_start: 101,
    //     km_end: 200,
    //     baht_per_km: null,
    //   },
    //   {
    //     km_start: 201,
    //     km_end: 300,
    //     baht_per_km: null,
    //   },
    //   {
    //     km_start: 301,
    //     km_end: 400,
    //     baht_per_km: null,
    //   },
    //   {
    //     km_start: 401,
    //     km_end: 500,
    //     baht_per_km: null,
    //   },
    // ].map((item, index) => {
    //   return {
    //     name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
    //     field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
    //     onChange: (e) => {
    //       setValue(`truck_freight.truck_freight_km[${index}]`, {
    //         km_start: item.km_start,
    //         km_end: item.km_end,
    //         baht_per_km: e.target.value,
    //       });
    //     },

    //     onBlur: (e) => {
    //       setValue(`truck_freight.truck_freight_km[${index}]`, {
    //         km_start: item.km_start,
    //         km_end: item.km_end,
    //         baht_per_km:
    //           !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
    //             ? parseFloat(e.target.value).toFixed(2)
    //             : parseFloat(0).toFixed(2),
    //       });
    //     },
    //     rules: {
    //       required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
    //       pattern: {
    //         value: helper.RegexValidate("number"),
    //         message: "ระบุเป็นตัวเลขได้เท่านั้น",
    //       },
    //     },
    //     error:
    //       errors.truck_freight &&
    //       errors.truck_freight.truck_freight_km &&
    //       errors.truck_freight.truck_freight_km[index] &&
    //       errors.truck_freight.truck_freight_km[index].baht_per_km
    //         ? true
    //         : false,
    //     helperText:
    //       errors.truck_freight &&
    //       errors.truck_freight.truck_freight_km &&
    //       errors.truck_freight.truck_freight_km[index] &&
    //       errors.truck_freight.truck_freight_km[index].baht_per_km
    //         ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
    //         : null,
    //   };
    // });

    return (
      currentForm === 4 && (
        <div>
          {form4_menu.map((menu, index) => {
            return (
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />
            );
          })}

          {/* {form4_truck_freight_km.map((menu, index) => {
            return (
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />
            );
          })} */}
        </div>
      )
    );
  };

  // const renderForm5 = () => {
  //   const form5_truck_freight_km = [
  //     {
  //       km_start: 501,
  //       km_end: 600,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 601,
  //       km_end: 700,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 701,
  //       km_end: 800,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 801,
  //       km_end: 900,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 901,
  //       km_end: 1000,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1001,
  //       km_end: 1100,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1101,
  //       km_end: 1200,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1201,
  //       km_end: 1300,
  //       baht_per_km: null,
  //     },
  //   ].map((item, index) => {
  //     index = index + 6;
  //     return {
  //       name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
  //       field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
  //       // onChange: (e) => {
  //       //   setValue(`truck_freight.truck_freight_km[${index}]`, {
  //       //     km_start: item.km_start,
  //       //     km_end: item.km_end,
  //       //     baht_per_km: e.target.value,
  //       //   });
  //       // },
  //       onBlur: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km:
  //             !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
  //               ? parseFloat(e.target.value).toFixed(2)
  //               : parseFloat(0).toFixed(2),
  //         });
  //       },

  //       rules: {
  //         required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
  //         pattern: {
  //           value: helper.RegexValidate("number"),
  //           message: "ระบุเป็นตัวเลขได้เท่านั้น",
  //         },
  //       },
  //       error:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? true
  //           : false,
  //       helperText:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
  //           : null,
  //     };
  //   });

  //   return (
  //     currentForm === 5 && (
  //       <div>
  //         {form5_truck_freight_km.map((menu, index) => {
  //           return (
  //             <TemplateForm
  //               form_info={menu}
  //               index={index}
  //               control={control}
  //               errors={errors}
  //               trigger={trigger}
  //             />
  //           );
  //         })}
  //       </div>
  //     )
  //   );
  // };

  // const renderForm6 = () => {
  //   const form6_truck_freight_km = [
  //     {
  //       km_start: 1301,
  //       km_end: 1400,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1401,
  //       km_end: 1500,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1501,
  //       km_end: 1600,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1601,
  //       km_end: 1700,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1701,
  //       km_end: 1800,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1801,
  //       km_end: 1900,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1901,
  //       km_end: 2000,
  //       baht_per_km: null,
  //     },
  //   ].map((item, index) => {
  //     index = index + 14;
  //     return {
  //       name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
  //       field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
  //       // onChange: (e) => {
  //       //   setValue(`truck_freight.truck_freight_km[${index}]`, {
  //       //     km_start: item.km_start,
  //       //     km_end: item.km_end,
  //       //     baht_per_km: e.target.value,
  //       //   });
  //       // },
  //       onBlur: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km:
  //             !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
  //               ? parseFloat(e.target.value).toFixed(2)
  //               : parseFloat(0).toFixed(2),
  //         });
  //       },

  //       rules: {
  //         required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
  //         pattern: {
  //           value: helper.RegexValidate("number"),
  //           message: "ระบุเป็นตัวเลขได้เท่านั้น",
  //         },
  //       },
  //       error:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? true
  //           : false,
  //       helperText:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
  //           : null,
  //     };
  //   });

  //   const form6_menu = [
  //     {
  //       name: "truck_driving_license",
  //       nameDescription: "ระบบจะดึงจากฐานข้อมูลสามารถเลือกได้หลายรายการ",
  //       field_type: "select",
  //       field_label: "ประเภทใบขับขี่ที่สามารถขับได้*",
  //       multiSelect: true,
  //       rules: {
  //         required: "กรุณาเลือกประเภทใบขับขี่",
  //       },
  //       options: listTruckDrivingLicense.map((item) => {
  //         return {
  //           value: item.id,
  //           name: item.name,
  //         };
  //       }),
  //     },
  //   ];

  //   return (
  //     currentForm === 6 && (
  //       <div>
  //         {form6_truck_freight_km.map((menu, index) => {
  //           return (
  //             <TemplateForm
  //               form_info={menu}
  //               index={index}
  //               control={control}
  //               errors={errors}
  //               trigger={trigger}
  //             />
  //           );
  //         })}

  //         {form6_menu.map((menu, index) => {
  //           return (
  //             <TemplateForm
  //               form_info={menu}
  //               index={index}
  //               control={control}
  //               errors={errors}
  //               trigger={trigger}
  //             />
  //           );
  //         })}
  //       </div>
  //     )
  //   );
  // };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            setCurrentForm(1);
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มประเภทรถ`}
        open={open}
        setIsModalOpen={setOpen}
        confirmTextBtn={currentForm === last_current_form ? "บันทึก" : "ถัดไป"}
        cancelTextBtn={currentForm > 1 ? "ย้อนกลับ" : "ยกเลิก"}
        disabledCancelBtn={
          currentForm === last_current_form && isLoading === true
        }
        disabledComfirmBtn={
          !R.isEmpty(errors) ||
          (currentForm === last_current_form && isLoading === true)
        }
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          if (currentForm === 1) {
            reset();
            setOpen(false);
          } else {
            clearErrors();
            setCurrentForm(currentForm - 1);
          }
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            createTruckTypeData();
          })}
        >
          <div style={{ width: "400px" }}>
            {renderForm1()}
            {renderForm2()}
            {renderForm3()}
            {renderForm4()}
            {/* {renderForm5()}
            {renderForm6()} */}
          </div>

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateTruckTypeModal;
