import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import helper from "utils/helper";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { PiFlagPennantFill } from "react-icons/pi";
import ModalAddTarget from "./ModalAddTarget";
import styles from "./ModalAddTarget.module.css";
import LightTooltip from "components/tooltip/LightTooltip";

const GraphFavorite = ({
  profile_id,
  project_id,
  type = "",
  data = null,
  title = "",
  maxValueY = 100,
  typeDataSet = "",
  refresh = () => {},
}) => {
  console.log("maxValueY:", maxValueY);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [modalUploadTarget, setModalUploadTarget] = useState(false);
  useEffect(() => {
    if (data) {
      setGraph1(data[data.length - 2]);
      setGraph2(data[data.length - 1]);
    }
  }, [data]);
  const abbreviateNumberToMillions = (numStr) => {
    if (numStr) {
      const num =
        typeof numStr === "number"
          ? numStr
          : parseFloat(numStr?.replace(/,/g, ""));
      if (isNaN(num)) {
        return null;
      }
      const absNum = Math.abs(num);

      // Handle only millions
      if (absNum >= 1e6) {
        const abbreviatedNum = num / 1e6; // Convert to millions
        const rounded = parseFloat(abbreviatedNum.toFixed(2)); // Round to 2 decimal places
        return `${rounded} M`;
      }

      // If the number is less than a million, return with 0.xx M
      const abbreviatedNum = num / 1e6;
      const rounded = parseFloat(abbreviatedNum.toFixed(2));
      return `${rounded} M`;
    } else {
      return "0";
    }
  };

  const abbreviateNumber = (numStr) => {
    if (numStr) {
      const num =
        typeof numStr === "number"
          ? numStr
          : parseFloat(numStr?.replace(/,/g, ""));
      if (isNaN(num)) {
        return null;
      }
      const absNum = Math.abs(num);
      const units = [
        { value: 1e12, suffix: "T" }, // Trillion
        { value: 1e9, suffix: "B" }, // Billion
        { value: 1e6, suffix: "M" }, // Million
        { value: 1e3, suffix: "K" }, // Thousand
      ];
      for (let i = 0; i < units.length; i++) {
        if (absNum >= units[i].value) {
          const abbreviatedNum = num / units[i].value;
          const rounded = parseFloat(abbreviatedNum.toFixed(2));
          return `${rounded} ${units[i].suffix}`;
        }
      }
      return num.toString();
    } else {
      return "0";
    }
  };

  const CustomTooltipFav = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "target"
                ? "เป้าหมาย : "
                : entry.dataKey == "sum2"
                ? "รายได้เมื่อปีที่แล้ว "
                : "รายได้ : "}
              {helper.formatNumber(entry.value) + " บาท"}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomLegendFav = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            paddingTop: `20px`,
            display: "flex",
            justifyContent: "center",
            gap: 15,
            // width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>
                {entry.dataKey == "target"
                  ? "เป้าหมาย "
                  : entry.dataKey == "sum2"
                  ? "รายได้เมื่อปีที่แล้ว "
                  : "รายได้ "}
              </span>
            </div>
          ))}
          <div
            onClick={() => setModalUploadTarget(true)}
            style={{ cursor: "pointer" }}
          >
            <PiFlagPennantFill size={20} color="#8ba2bb" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Paper
        style={{
          backgroundColor: "white",
          padding: "15px",
          borderRadius: "10px",
          height: "100%",
          boxShadow: "none",
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <LightTooltip title={title}>
            <div
              style={{ flex: 0.5, fontSize: "18px", fontWeight: 500 }}
              className={styles.ellipsisDetail2Line}
            >
              {title}
            </div>
          </LightTooltip>
          <div
            style={{ flex: 0.5, display: "flex", justifyContent: "flex-end" }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <div>
                  <div
                    style={{
                      textAlign: "start",
                      fontSize: "12px",
                    }}
                  >
                    {!(graph2?.sum === 0 && graph1?.sum === 0)
                      ? Number(graph2?.sum - graph1?.sum) > 0
                        ? "สูงกว่าช่วงก่อนหน้า"
                        : Number(graph2?.sum - graph1?.sum) < 0
                        ? "ต่ำกว่าช่วงก่อนหน้า"
                        : Number(graph2?.sum - graph1?.sum) === 0
                        ? "เท่ากับช่วงก่อนหน้า"
                        : "-"
                      : "-"}
                  </div>
                  <div
                    style={{
                      textAlign: "start",
                      fontSize: "12px",
                    }}
                  >
                    {graph1 && graph2
                      ? helper.addComma(graph2.sum - graph1.sum) + " บาท"
                      : "-"}{" "}
                  </div>
                  <div
                    style={{
                      textAlign: "start",
                      fontSize: "12px",
                    }}
                  >
                    {type !== "truck_tenant" &&
                      (graph1 && graph2
                        ? `(${helper.formatNumber(
                            (graph2?.count - graph1?.count).toFixed(2)
                          )} ${type === "trade" ? "หน่วย" : "งาน"})`
                        : `(-)`)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {helper.calculatePercentage(graph1?.sum, graph2?.sum) > 0 ? (
                    <FaArrowTrendUp color="#27CE88" size={40} />
                  ) : helper.calculatePercentage(graph1?.sum, graph2?.sum) <
                    0 ? (
                    <FaArrowTrendDown color="#FF316F" size={40} />
                  ) : (
                    "-"
                    // <FaWindowMinimize color="#FFC675" size={40} />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: 500,
                    color:
                      Number(
                        helper.calculatePercentage(graph1?.sum, graph2?.sum)
                      ) > 0
                        ? "#27CE88"
                        : Number(
                            helper.calculatePercentage(graph1?.sum, graph2?.sum)
                          ) < 0
                        ? "#FF316F"
                        : "#FFC675",
                  }}
                >
                  {graph1 && graph2
                    ? helper.addComma(
                        helper.calculatePercentage(graph1.sum, graph2.sum)
                      ) + " %"
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ResponsiveContainer width={"100%"} height={200}>
          <ComposedChart
            data={data}
            margin={{
              top: 40,
              right: 50,
              left: 30,
            }}
            onClick={(chartData) => {
              if (chartData && chartData.activeLabel) {
                const index = chartData.activeTooltipIndex;
                const clickedData = data.find(
                  (d) => d.xname === chartData.activeLabel
                );

                if (clickedData) {
                  setGraph1(data[index - 1]);
                  setGraph2(clickedData);
                } else {
                  setGraph1(null);
                  setGraph2(null);
                }
              }
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#e1e9ea" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#e1e9ea" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={"xname"}
              style={{ fontSize: "10px", fontWeight: 500 }}
              dy={25}
            />
            <YAxis
              style={{ fontSize: "10px", fontWeight: 500 }}
              tickFormatter={helper.formatNumber}
              domain={[0, maxValueY]}
              dx={-25}
            />
            <Tooltip content={<CustomTooltipFav />} />
            <Legend content={<CustomLegendFav />} width={"100%"} />
            <CartesianGrid stroke="#f5f5f5" />
            <Area
              dataKey="sum2"
              stroke={`#729094`}
              fillOpacity={1}
              fill="url(#colorUv)"
            >
              <LabelList
                dataKey="sum2"
                // position="top"
                // angle={-45}
                fill={`#729094`}
                // style={{
                //   fontSize: 14,
                //   fontWeight: 500,
                //   textAnchor: "start",
                // }}
                // formatter={abbreviateNumber}
                content={(props) => {
                  const { x, y, value, width, target, fill } = props;
                  return (
                    <text
                      x={x - 3}
                      y={y + 12}
                      fill={fill}
                      textAnchor="end"
                      fontSize="14px"
                      fontWeight="500"
                      transform={`rotate(-45, ${x}, ${y})`}
                    >
                      {Number(value) === 0 ? "-" : abbreviateNumber(value)}
                    </text>
                  );
                }}
              />
            </Area>
            <Line strokeWidth={2} dataKey="sum" stroke={`#48d050`} dot={false}>
              <LabelList
                dataKey="sum"
                position="top"
                angle={-45}
                fill={`#48d050`}
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  textAnchor: "start",
                }}
                formatter={abbreviateNumber}
              />
            </Line>
            <Line
              strokeWidth={2}
              dataKey="target"
              stroke={`rgba(255, 49, 111,0.5)`}
              dot={false}
              strokeDasharray="5 5"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Paper>
      {modalUploadTarget &&
        (type === "trade" ? (
          <ModalAddTarget
            type="target_trade_project"
            project_id={project_id}
            open={modalUploadTarget}
            handleClose={() => {
              setModalUploadTarget(false);
              refresh();
            }}
          />
        ) : type === "truck_tenant" ? (
          <ModalAddTarget
            type="target_truck_tenant_project"
            project_id={project_id}
            open={modalUploadTarget}
            handleClose={() => {
              setModalUploadTarget(false);
              refresh();
            }}
          />
        ) : (
          <ModalAddTarget
            type="target_profile"
            profile_id={profile_id}
            open={modalUploadTarget}
            handleClose={() => {
              setModalUploadTarget(false);
              refresh();
            }}
          />
        ))}
    </>
  );
};

export default GraphFavorite;
