import React, { useState, useEffect } from "react";
import styles from "./ModalAddTarget.module.css";
import { useForm, Controller } from "react-hook-form";
import Loading from "components/loading/Loading";
import { editTargetKPI, getTargetKPI } from "utils/api/dashboard/newDashboard";
import FieldInput from "components/field/FieldInput";
import { Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonCustom from "components/ButtonCustom";
const ModalAddTargetKPI = ({ open = false, handleClose = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  useEffect(() => {
    getTarget();
  }, []);

  useEffect(() => {
    if (data) {
      setValue("kpi_inbound", data.kpi_inbound);
      setValue("kpi_delivery", data.kpi_delivery);
      setValue("kpi_document", data.kpi_document);
      setValue("kpi_in24hr", data.kpi_in24hr);
      setValue("kpi_matching", data.kpi_matching);
    }
  }, [data]);
  const getTarget = async () => {
    await getTargetKPI().then((res) => {
      console.log("res.data", res.data);
      if (res.data.code === 1000) {
      }
      setData(res.data.data[0]);
    });
  };

  const handleEdit = async (data) => {
    console.log();
    await editTargetKPI(data)
      .then((res) => {
        if (res.data.code) {
          handleClose();
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {/* {loading && <Loading loading={loading} />} */}
      <Modal
        open={open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? handleClose() : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon sx={{ color: "grey.main" }} onClick={handleClose} />
          </div>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: 500,
              paddingBottom: "10px",
            }}
          >
            อัพเดตเป้าหมาย
          </Typography>
          <form id="form-content" onSubmit={handleSubmit(handleEdit)}>
            <Controller
              name="kpi_in24hr"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="KPI รับงานภายใน 24 ชั่วโมง"
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.kpi_in24hr}
                    errorText={
                      errors.kpi_in24hr ? errors.kpi_in24hr.message : ""
                    }
                    type="number"
                  />
                </div>
              )}
            />
            <Controller
              name="kpi_matching"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="KPI จับคู่งานสำเร็จ"
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.kpi_matching}
                    errorText={
                      errors.kpi_matching ? errors.kpi_matching.message : ""
                    }
                    type="number"
                  />
                </div>
              )}
            />
            <Controller
              name="kpi_inbound"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="KPI เข้ารับสินค้าตรงเวลา"
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.kpi_inbound}
                    errorText={
                      errors.kpi_inbound ? errors.kpi_inbound.message : ""
                    }
                    type="number"
                  />
                </div>
              )}
            />
            <Controller
              name="kpi_delivery"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="KPI จัดส่งสินค้าตรงเวลา"
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.kpi_delivery}
                    errorText={
                      errors.kpi_delivery ? errors.kpi_delivery.message : ""
                    }
                    type="number"
                  />
                </div>
              )}
            />
            <Controller
              name="kpi_document"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldInput
                    label="KPI จัดส่งเอกสารนำกลับตรงเวลา"
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.kpi_document}
                    errorText={
                      errors.kpi_document ? errors.kpi_document.message : ""
                    }
                    type="number"
                  />
                </div>
              )}
            />
            <ButtonCustom name="อัพเดต" type="submit" fullWidth />
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddTargetKPI;
