import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuTradeFinance } from "utils/submenuoptions";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import { cancelPaid, getPaidGroup } from "utils/api/trade-finance/carrier";
import * as currency from "currency.js";
import CancelStatusButton from "components/button/finance/CancelStatusButton";
import SelectRowValue from "components/button/SelectRowValue";
import FieldTextArea from "components/field/FieldTextArea";
import AuthContext from "components/context/AuthContext";

const TradeSellerPayGroup = () => {
  const { adminAuth } = useContext(AuthContext);
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "pay_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [cancelDetail, setCancelDetail] = useState("");
  const [errorDetail, setErrorDetail] = useState(false);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการซื้อขาย",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขนส่ง",
        path: null,
      },
      {
        label: "ประวัติการแจ้งโอน",
        path: null,
      },
    ]);
    handleSearch();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuTradeFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(20);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const customer_id = rowSelected[0].customer_id;
      const project_name = rowSelected[0].project_name;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (
            item.customer_id !== customer_id ||
            item.project_name !== project_name
          ) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getPaidGroup(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.customer_id)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const customer_id = rowSelected[0].customer_id;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = customer_id !== item.customer_id;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleOnclickUpdateStatusButton = () => {
    if (rowSelected.length > 0) {
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  useEffect(() => {
    if (cancelDetail !== "") {
      setErrorDetail(false);
    }
  }, [cancelDetail]);

  const handleCancelStatus = async () => {
    if (cancelDetail === "") {
      setErrorDetail(true);
    } else {
      try {
        const body = {
          detail: cancelDetail,
        };
        const response = await cancelPaid(rowSelected[0].pay_in_code, body);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Notification Error",
          error.response.data.description
        );
        return false;
      }
    }
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "pay_date", name: "วันที่แจ้งโอนล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่คําสั่งซื้อ" },
                { value: "invoice_code", name: "เลขที่ใบตั้งเบิก" },
                { value: "bill_code", name: "เลขที่ใบเตรียมแจ้งโอน" },
                { value: "pay_code", name: "เลขที่ใบแจ้งโอน" },
                { value: "id", name: "ไอดี" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <CancelStatusButton
              title="ยกเลิกการแจ้งโอน"
              description="ยืนยันการยกเลิกการแจ้งโอน?"
              onClick={handleOnclickUpdateStatusButton}
              onConfirmCancel={handleCancelStatus}
              selectedLength={rowSelected.length}
              openNotificationWithIcon={openNotificationWithIcon}
            >
              <FieldTextArea
                placeholder="กรุณากรอกเหตุผลในการยกเลิก"
                onChange={(e) => setCancelDetail(e.target.value)}
                value={cancelDetail}
                error={errorDetail}
                errorMessage="*จำเป็นต้องระบุ"
              />
            </CancelStatusButton>
            <FormControl>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "id",
        label: "ไอดี",
        tdclass: "text-center",
        render: "id_group",
      },
      {
        key: "actual_date",
        label: "วันที่แจ้งโอน",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "pay_in_code",
        label: "เลขที่ใบแจ้งโอน",
        tdclass: "text-center",
      },
      // {
      //   key: "customer_name",
      //   label: "ผู้ซื้อ",
      // },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "trade_jobber",
        label: "ผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "amount_per_trip",
        label: "ราคาผู้ขนส่ง",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "vat_per_trip",
        label: "ภาษีหัก ณ ที่จ่าย",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "total_per_trip",
        label: "ราคาผู้ขนส่งสุทธิ",
        tdclass: "text-end",
        render: "currency",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="seller"
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TradeSellerPayGroup;
