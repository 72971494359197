import instance from "utils/axiosInstance";

export const getWork = async (params = {}) => {
  return await instance.get(`api/xlsx/work/create`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 6000000,
  });
};

export const getWorkByMatching = async (params = {}) => {
  return await instance.get(`api/xlsx/work/matching`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 6000000,
  });
};

export const getProject = async (params = {}) => {
  return await instance.get(`api/xlsx/project`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};
export const getUser = async (params = {}) => {
  return await instance.get(`api/xlsx/user`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
  });
};
export const getProfile = async (params = {}) => {
  return await instance.get(`api/xlsx/profile`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};
export const getTruck = async (params = {}) => {
  return await instance.get(`api/xlsx/truck`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};
export const getReceiptGinkgo = async (params = {}) => {
  return await instance.get(`api/xlsx/ginkgo_t01`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};
export const getEWHTGinkgo = async (params = {}) => {
  return await instance.get(`api/xlsx/ginkgo_ewht`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTradeOrder = async (params = {}) => {
  return await instance.get(`api/xlsx/trade_order`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTradeTransport = async (params = {}) => {
  return await instance.get(`api/xlsx/trade_transport`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getDueDateInvoice = async (params = {}) => {
  return await instance.get(`api/xlsx/due_date/invoice`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getDueDateShipper = async (params = {}) => {
  return await instance.get(`api/xlsx/due_date/shipper`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getDueDateBuyerInvoice = async (params = {}) => {
  return await instance.get(`api/xlsx/due_date_buyer/invoice`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getDueDateBuyerShipper = async (params = {}) => {
  return await instance.get(`api/xlsx/due_date_buyer/shipper`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getUnpiadShipper = async (params = {}) => {
  return await instance.get(`api/xlsx/unpaid/shipper`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getUnpiadInvoice = async (params = {}) => {
  return await instance.get(`api/xlsx/unpaid/invoice`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getUnpiadBuyerShipper = async (params = {}) => {
  return await instance.get(`api/xlsx/unpaid_buyer/shipper`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getUnpiadBuyerInvoice = async (params = {}) => {
  return await instance.get(`api/xlsx/unpaid_buyer/invoice`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getฺBuyerVat = async (params = {}) => {
  return await instance.get(`api/xlsx/buyer_vat`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getฺSellerVat = async (params = {}) => {
  return await instance.get(`api/xlsx/seller_vat`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTradeCarrierWHT = async (params = {}) => {
  return await instance.get(`api/xlsx/trade_carrier_wht`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTradePPD53 = async (params = {}) => {
  return await instance.get(`/api/xlsx/trade_ppd_53`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getReceiptGinkgoTrade = async (params = {}) => {
  return await instance.get(`api/xlsx/ginkgo_t01_trade`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getDailyIncome = async (params = {}) => {
  return await instance.get(`api/xlsx/daily_income`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getMonthlyIncome = async (params = {}) => {
  return await instance.get(`api/xlsx/monthly_income`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getPpd3 = async (params = {}) => {
  return await instance.get(`api/xlsx/ppd/3`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getPpd53 = async (params = {}) => {
  return await instance.get(`api/xlsx/ppd/53`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getRentalOrderXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/order`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTenantGinkgoXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/tenant_ginkgo`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTenantDueDateXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/tenant_due_data`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getTenantVatXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/tenant_vat`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getLessorVatXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/lessor_vat`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getLessorWhtXlsx = async (params = {}) => {
  return await instance.get(`api/xlsx/rental/lessor_wht`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};

export const getRentalPPD53Xlsx = async (params = {}) => {
  return await instance.get(`/api/xlsx/rental/ppd_53`, {
    baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    params: params,
    responseType: "blob",
    timeout: 180000,
  });
};
