import React, { useEffect, useRef, useState } from "react";
import FormModal from "components/modal/FormModal";
import {
  Box,
  useTheme,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./ModalCreateUser.module.css";
import helper from "utils/helper";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import FieldSelect from "components/field/FieldSelect";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import { findCompanyName as findCompanyNameAPI } from "utils/api/profile/profile";
import { getTruckType } from "utils/api/masterData/truckType";
import {
  getUserPermission,
  getDrivingLicense,
  checkDrivingLicense,
  createUser,
  checkUniqueFields,
} from "utils/api/user/user";
import Loading from "components/loading/Loading";
import FieldPassword from "components/field/FieldPassword";
import FieldUpload from "components/field/FieldUpload";
import FieldDatePicker from "components/field/FieldDatePicker";

const ModalCreateUser = (props) => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const theme = useTheme();
  const inputFile = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [linkFile, setLinkFile] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [items, setItems] = useState([]);
  // const [isMain, setIsMain] = useState(null);
  const [userCode, setUserCode] = useState(null);
  const [masterTruck, setMasterTruck] = useState([]);
  const [masterDrivingLicense, setMasterDrivingLicense] = useState([]);
  const [roleM, setRoleM] = useState([]);
  const [roleS, setRoleS] = useState([]);
  const [date, setDate] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const isMain = watch("is_main");
  const checkRole = watch("role");
  const driving_license = watch("driving_license");
  const driver_truck_id = watch("driver_truck_id");
  const checkUserPermission = watch("userPermission");
  const driver_crime_history = watch("driver_crime_history");

  const handleIsMainChange = () => {
    if (isMain === "true") {
      setValue("role", roleM);
    } else if (isMain === "false") {
      setValue("role", roleS);
    }
  };
  useEffect(() => {
    handleIsMainChange();
  }, [isMain, roleM, roleS]);

  useEffect(() => {
    if (checkRole?.length === 0 || checkRole === "") {
      setError("role", { message: "กรุณาเลือกบทบาท" });
    } else {
      clearErrors("role");
    }
  }, [checkRole]);

  // default value permission

  useEffect(() => {
    console.log("checkRole", checkRole);
    const rolesMapping = {
      shipper: [3, 4, 5],
      carrier: [6, 7, 8, 9, 10],
      driver: [10],
    };

    const mainItems = [1, 2];

    if (!checkRole || checkRole.length === 0) return;

    let selectedItems = [];

    if (isMain === "true") {
      selectedItems.push(...mainItems);
    }

    Object.entries(rolesMapping).forEach(([role, items]) => {
      if (checkRole.includes(role)) {
        selectedItems.push(...items);
      }
    });

    [...new Set(selectedItems)].forEach((item) => {
      handleCheckboxClick(item);
    });
  }, [checkRole, isMain]);

  useEffect(() => {
    if (driver_crime_history === "true") {
      setValue("driver_crime_history_consent", "");
    } else if (driver_crime_history === "false") {
      setValue("driver_crime_history_file", "");
    }
  }, [driver_crime_history]);

  const onClick = (event) => {
    inputFile.current.click();
    event.stopPropagation();
  };

  useEffect(() => {
    console.log("items", items);
  }, [items]);

  useEffect(() => {
    getUserPermissionMS();
    getTruckTypeMS();
    getDrivingLicenseMS();
  }, []);

  const getUserPermissionMS = async () => {
    // const convertParam = helper.ToConvertParams(param);
    const response = await getUserPermission();
    console.log("responseresponse", response.data.data.results);
    let results = response.data.data.results;
    setItems(
      ...items,
      results?.map((res) => ({
        user_permission_id: res.id,
        name: res.name,
        visit: true,
        create: true,
        deleteP: true,
        edit: true,
        print: true,
        exportP: true,
        checked: false,
      }))
    );
  };

  const getTruckTypeMS = async () => {
    const params = {
      sort_field: "order_number",
      sort_order: 1,
    };
    const response = await getTruckType(params);
    console.log("response", response.data.data);
    let results = response.data.data.results;
    setMasterTruck(
      ...masterTruck,
      results?.map((res) => ({
        value: res.id,
        name: res.name,
      }))
    );
  };

  const getDrivingLicenseMS = async () => {
    const response = await getDrivingLicense();
    console.log("response", response.data.data);
    let results = response.data.data.results;
    setMasterDrivingLicense(
      ...masterDrivingLicense,
      results?.map((res) => ({
        value: res.id,
        name: res.name,
      }))
    );
  };

  const handleCheckboxClick = (itemId) => {
    console.log("itemIditemId", itemId);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.user_permission_id === itemId
          ? { ...item, checked: !item["checked"] }
          : item
      )
    );
  };
  useEffect(() => {
    const modifiedItems = items?.map(
      ({ deleteP, exportP, name, checked, ...item }) =>
        checked === true && {
          delete: deleteP,
          export: exportP,
          ...item,
        }
    );
    const filteredItems = modifiedItems.filter((item) => {
      return Object.keys(item).some(
        (key) => key !== "user_permission_id" && item[key]
      );
    });

    if (filteredItems) {
      setValue("userPermission", filteredItems);
      clearErrors("userPermission");
    }
  }, [items]);

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  const SearchCompany = async (user_code) => {
    setLoading(true);
    await findCompanyNameAPI(user_code)
      .then((response) => {
        console.log("usercode", response.data.data);
        if (response.data.code === 1000) {
          setLoading(false);
          clearErrors("profile");
          setValue("profile", response.data.data);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setValue("profile", "");
          setError("profile", {
            type: 400,
            message: error.response.data.description,
          });
        } else {
          clearErrors("profile");
        }
      });
  };

  const checkMatchDrivingLicense = async (license, truck) => {
    setLoading(true);
    const params = {
      driving_license_id: license,
      truck_type_id: truck,
      page: 1,
      per_page: 10,
    };
    await checkDrivingLicense(params)
      .then((response) => {
        console.log("response match driving", response.data.data.results);
        if (response.data.code === 1000) {
          if (response.data.data.results.length === 0) {
            setLoading(false);
            setError("driving_license", {
              type: 400,
              message:
                "ประเภทใบอนุญาตขับขี่ที่คุณมีไม่สามารถขับรถที่คุณเลือกได้",
            });
          } else {
            clearErrors("driving_license");
          }
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setError("driving_license", {
            type: 400,
            message: "ประเภทใบอนุญาตขับขี่ที่คุณมีไม่สามารถขับรถที่คุณเลือกได้",
          });
        } else {
          clearErrors("driving_license");
        }
      });
  };

  useEffect(() => {
    if (driving_license) {
      checkMatchDrivingLicense(driving_license, driver_truck_id);
    }
  }, [driving_license]);

  const checkUnique = async (data) => {
    console.log("checkUnique", data);
    const uniqueError = {};
    await checkUniqueFields(data)
      .then((res) => {
        console.log("res");
      })
      .catch((error) => {
        if (error.response.data.code === 4404) {
          Object.keys(error.response.data.description).forEach((field) => {
            uniqueError[field] = error.response.data.description[field];
          });
        }
      });
    if (Object.keys(uniqueError).length > 0) {
      Object.keys(uniqueError).forEach((field) => {
        setError(field, {
          type: "manual",
          message: uniqueError[field],
        });
      });
      return false;
    }
    return true;
  };

  const validateImage = (file, fieldName) => {
    console.log("validateImage", file, fieldName);
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!allowedTypes.includes(file.type)) {
      setError(String(fieldName), {
        type: "typeError",
        message:
          "อัพโหลดไฟล์นามสกุลไฟล์ JPG และ PNG เท่านั้น และขนาดไม่เกิน 10 MB",
      });
      return false;
    }

    if (file.size > maxSize) {
      setError(String(fieldName), {
        type: "sizeError",
        message:
          "อัพโหลดไฟล์นามสกุลไฟล์ JPG และ PNG เท่านั้น และขนาดไม่เกิน 10 MB",
      });
      return false;
    }
    // clearErrors(String(fieldName));
    return true;
  };

  const validateFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!file) {
      return true; // No error when the file is empty
    }

    if (!allowedTypes.includes(file.type)) {
      return "อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB";
    }
    if (file.size > maxSize) {
      return "อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB";
    }
    return true;
  };

  const validatePermission = () => {
    if (checkUserPermission && checkUserPermission.length === 0) {
      setError("userPermission", { message: "กรุณาเลือกสิทธิ์การใช้งาน" });
      return false;
    }
    return true;
  };

  const handlePrevious = () => {
    if (step == 1) {
      props?.handleClose();
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async (data) => {
    console.log("data handleNext", data);
    if (step === 1) {
      if (data.image) {
        const checkFile = validateImage(data.image, "image");
        console.log("checkFile", checkFile);
        if (!checkFile) return;
      }
    }
    if (step === 2) {
      console.log("checkUserPermission", checkUserPermission);
      const checkPerm = validatePermission();
      if (!checkPerm) return;
    }
    if (step === 3) {
      const check = await checkUnique(data);
      console.log("checkcheck", check);
      if (!check) return;
    }
    if (step < 4) {
      setStep(step + 1);
    }
  };

  const onSubmit = async (data) => {
    console.log("datadatadatadata", data);
    if (step === 4) {
      setLoading(true);
      console.log("dataaaaaaa", data);
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "userPermission" && data["userPermission"] !== undefined) {
          GForm.append(key, JSON.stringify(data[key]));
        } else if (
          key === "driver_license_expire_date" &&
          data["driver_license_expire_date"] !== undefined
        ) {
          GForm.append(key, new Date(data[key]).toISOString());
        } else if (key === "role" && data["role"] !== undefined) {
          const roles = data[key];
          GForm.append("is_shipper", roles.includes("shipper"));
          GForm.append("is_carrier", roles.includes("carrier"));
          GForm.append("is_driver", roles.includes("driver"));
        } else if (key === "profile" && data["profile"] !== undefined) {
          GForm.append("profile_id", data[key].id);
        } else if (data[key] === undefined) {
          GForm.delete(key);
        } else {
          GForm.append(key, data[key]);
        }
      });

      GForm.delete("role");
      GForm.delete("profile");
      GForm.delete("confirmPassword");
      for (const [key, value] of GForm.entries()) {
        console.log(`${key}: ${value}`);
      }
      await createUser(GForm)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            props.handleClose();
            setLoading(false);
            props.alertSuccess();
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              props.alertError({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    }
  };

  const renderFormPage1 = () => {
    return (
      <div>
        <Controller
          name="image"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <input
                style={{ display: "none" }}
                ref={inputFile}
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    validateImage(file, "image");
                    onChange(file);
                  }
                  // setLinkFile(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
              />

              {value ? (
                <img
                  src={URL.createObjectURL(value)}
                  width={"209px"}
                  height={"209px"}
                  alt="image profile"
                  style={{ objectFit: "cover" }}
                  onClick={onClick}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "209px",
                    height: "209px",
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: "center",
                    justifyContent: "center",

                    borderRadius: "10px",
                  }}
                  onClick={onClick}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: 134,
                      color: "grey.main",
                    }}
                  />
                </div>
              )}
              {errors?.image && (
                <p
                  style={{ color: theme.palette.error.main, fontSize: "10px" }}
                >
                  {errors?.image.message}
                </p>
              )}
            </div>
          )}
        />
        <div className={styles.titleUserRole} style={{ paddingTop: "27px" }}>
          <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
            ประเภทและบทบาทผู้ใช้งาน
          </Typography>
        </div>
        <Controller
          control={control}
          name="is_main"
          rules={{ required: "กรุณาเลือกประเภทผู้ใช้งาน" }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <FieldSelect
                name={"ประเภทผู้ใช้งาน*"}
                options={[
                  { value: "true", name: "ผู้ใช้งานหลัก" },
                  { value: "false", name: "ผู้ใช้งานรอง" },
                ]}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.is_main}
                errorText={errors.is_main ? errors.is_main.message : ""}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <FieldInput
            label="รหัสผู้ใช้งานหลัก*"
            subLabel={`(บังคับถ้าเพิ่มผู้ใช้รอง)`}
            style={{ width: "-webkit-fill-available" }}
            value={userCode}
            onChange={(e) => setUserCode(e.target.value)}
            readOnly={isMain === "true" ? true : false}
          />
        </div>
        <div style={{ width: "100%" }}>
          <ButtonCustom
            name="ค้นหา"
            onClick={() => SearchCompany(userCode)}
            fullWidth={true}
            disabled={isMain === "true" ? true : false}
          />
        </div>
        <Controller
          name="profile"
          control={control}
          rules={{ required: isMain === "false" ? true : false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldInput
                label="ชื่อโปรไฟล์"
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                readOnly={true}
                value={value?.company_name ? value?.company_name : ""}
                error={!!errors.profile}
                helperText={errors.profile ? "ไม่พบโปรไฟล์" : ""}
              />
            </div>
          )}
        />
        {/* {isMain === "true" ? "main user" : "sub user"}
        <Controller
          name={isMain === "true" ? "role_main" : "role_sub"}
          control={control}
          rules={{ required: true }}
          defaultValue={isMain === "true" ? [] : ""}
          render={({ field: { onChange, onBlur, value } }) => ( */}
        {isMain === "false" ? (
          <div style={{ width: "100%" }}>
            <FieldSelect
              name={"บทบาทที่ต้องใช้งานในโปรไฟล์*"}
              options={[
                { value: "shipper", name: "ลูกค้า" },
                { value: "carrier", name: "ผู้ขนส่ง" },
                { value: "driver", name: "พนักงานขับรถ" },
              ]}
              // value={isMain === "true" ? value || [] : value || ""}
              value={roleS}
              onChange={(e) => {
                setRoleS(e);
                setItems(
                  items.map((res) => ({
                    ...res,
                    checked: false,
                  }))
                );
              }}
              style={{ width: "-webkit-fill-available" }}
              // multiSelect={isMain === "true" ? true : false}
              error={!!errors.role}
              errorText={errors.role ? "กรุณาเลือกบทบาท" : ""}
            />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <FieldSelect
              name={"บทบาทที่ต้องใช้งานในโปรไฟล์*"}
              options={[
                { value: "shipper", name: "ลูกค้า" },
                { value: "carrier", name: "ผู้ขนส่ง" },
                { value: "driver", name: "พนักงานขับรถ" },
              ]}
              // value={isMain === "true" ? value || [] : value || ""}
              value={roleM}
              onChange={(e) => {
                setRoleM(e);
                setItems(
                  items.map((res) => ({
                    ...res,
                    checked: false,
                  }))
                );
              }}
              style={{ width: "-webkit-fill-available" }}
              multiSelect={true}
              error={!!errors.role}
              errorText={errors.role ? "กรุณาเลือกบทบาท" : ""}
            />
          </div>
        )}
        {/* )}
         /> */}
      </div>
    );
  };

  const renderFormPage2 = () => {
    return (
      <div>
        <div className={styles.titleUserRole}>
          <Typography fontSize={14} color={"grey.main"} fontWeight={500}>
            {`สิทธิ์การใช้งาน(สามารถกำหนดได้ตามบทบาทที่ได้รับเท่านั้น)`}
          </Typography>
        </div>
        {errors?.userPermission ? (
          <Typography color={"error.main"} fontSize={"12px"}>
            {errors?.userPermission.message}
          </Typography>
        ) : (
          ""
        )}
        <FormGroup>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
            }}
          >
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              ข้อมูลทั่วไป*
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label={items[0].name}
              checked={items[0].checked}
              onChange={() => handleCheckboxClick(items[0].user_permission_id)}
              disabled={isMain === "false" ? true : false}
              sx={
                isMain === "false"
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[1].name}
              checked={items[1].checked}
              onChange={() => handleCheckboxClick(items[1].user_permission_id)}
              disabled={isMain === "false" ? true : false}
              sx={
                isMain === "false"
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              ลูกค้า*
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label={items[2].name}
              checked={items[2].checked}
              onChange={() => handleCheckboxClick(items[2].user_permission_id)}
              disabled={checkRole.includes("shipper") ? false : true}
              sx={
                !checkRole.includes("shipper")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[3].name}
              checked={items[3].checked}
              onChange={() => handleCheckboxClick(items[3].user_permission_id)}
              disabled={checkRole.includes("shipper") ? false : true}
              sx={
                !checkRole.includes("shipper")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[4].name}
              checked={items[4].checked}
              onChange={() => handleCheckboxClick(items[4].user_permission_id)}
              disabled={checkRole.includes("shipper") ? false : true}
              sx={
                !checkRole.includes("shipper")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              ผู้ขนส่ง*
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label={items[5].name}
              checked={items[5].checked}
              onChange={() => handleCheckboxClick(items[5].user_permission_id)}
              disabled={checkRole.includes("carrier") ? false : true}
              sx={
                !checkRole.includes("carrier")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[6].name}
              checked={items[6].checked}
              onChange={() => handleCheckboxClick(items[6].user_permission_id)}
              disabled={checkRole.includes("carrier") ? false : true}
              sx={
                !checkRole.includes("carrier")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[7].name}
              checked={items[7].checked}
              onChange={() => handleCheckboxClick(items[7].user_permission_id)}
              disabled={checkRole.includes("carrier") ? false : true}
              sx={
                !checkRole.includes("carrier")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={items[8].name}
              checked={items[8].checked}
              onChange={() => handleCheckboxClick(items[8].user_permission_id)}
              disabled={checkRole.includes("carrier") ? false : true}
              sx={
                !checkRole.includes("carrier")
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              พนักงานขับรถ*
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label={items[9].name}
              checked={items[9].checked}
              onChange={() => handleCheckboxClick(items[9].user_permission_id)}
              disabled={
                checkRole.includes("driver") || checkRole?.includes("carrier")
                  ? false
                  : true
              }
              sx={
                !(
                  checkRole.includes("driver") || checkRole?.includes("carrier")
                )
                  ? {
                      background: `rgba(140, 163, 186, 0.2)`,
                      color: "grey.main",
                      margin: 0,
                    }
                  : { margin: 0 }
              }
            />
          </div>
        </FormGroup>
      </div>
    );
  };

  const renderFormPage3 = () => {
    return (
      <div>
        <Controller
          control={control}
          name="prefix"
          rules={{ required: "กรุณาเลือกคำนำหน้า" }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldSelect
                name={"คำนำหน้า*"}
                options={[
                  { value: 0, name: "นาย" },
                  { value: 1, name: "นางสาว" },
                  { value: 2, name: "นาง" },
                ]}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.prefix}
                errorText={errors.prefix ? errors.prefix.message : ""}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name="firstname"
          rules={{
            required: "กรุณากรอกชื่อ",
            pattern: {
              value: /^[A-Za-zก-๏]+$/,
              message: "ระบุเป็นภาษาไทยและภาษาอังกฤษได้เท่านั้น",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="ชื่อ*"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={!!errors.firstname}
                helperText={errors.firstname ? errors.firstname.message : ""}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="lastname"
          rules={{
            required: "กรุณากรอกนามสกุล",
            pattern: {
              value: /^[A-Za-zก-๏ ]+$/,
              message: "ระบุเป็นภาษาไทยและภาษาอังกฤษได้เท่านั้น",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="นามสกุล*"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={!!errors.lastname}
                helperText={errors.lastname ? errors.lastname.message : ""}
              />
            </div>
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "กรุณากรอกเบอร์มือถือ",
            pattern: {
              value: /^(?:\+66|0)[689]\d{8}$/,
              message: "กรุณากรอกเบอร์มือถือให้ถูกต้อง",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="เบอร์มือถือ*"
                type="number"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={(e) => handleMaxDigit(e, "phone", 10)}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
              />
            </div>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "รูปแบบอีเมลไม่ถูกต้อง",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="อีเมล"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: "กรุณากรอกรหัสผ่าน",
            minLength: {
              value: 6,
              message:
                "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
              message:
                "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldPassword
                name="รหัสผ่าน*"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={!!errors.password}
                textError={errors.password ? errors.password.message : ""}
              />
            </div>
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: "กรุณากรอกยืนยันรหัสผ่าน",
            minLength: {
              value: 6,
              message:
                "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
              message:
                "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
            },
            validate: (value) =>
              value === getValues().password || "รหัสผ่านไม่ตรงกัน",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldPassword
                name="ยืนยันรหัสผ่าน*"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={!!errors.confirmPassword}
                textError={
                  errors.confirmPassword ? errors.confirmPassword.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="citizen_id"
          control={control}
          rules={{
            required:
              checkRole === "shipper" ||
              (checkRole.length === 1 && checkRole[0] === "shipper")
                ? false
                : "กรุณากรอกรหัสประจำตัวบัตรประชาชน",
            pattern: {
              value: /^[0-9]+$/,
              message: "ระบุเป็นตัวเลขได้เท่านั้น",
            },
            minLength: {
              value: 13,
              message: "รหัสประจำตัวบัตรประชาชนต้องมี 13 ตัว",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldInput
                label="รหัสประจำตัวบัตรประชาชน*"
                subLabel={`(บังคับเฉพาะผู้ใช้งานที่มีบทบาทเป็นผู้ขนส่งและพนักงานขับรถ)`}
                style={{ width: "-webkit-fill-available" }}
                value={helper.formatThaiCitizenId(value)}
                onChange={(e) => handleMaxDigit(e, "citizen_id", 17)}
                error={!!errors.citizen_id}
                helperText={errors.citizen_id ? errors.citizen_id.message : ""}
              />
            </div>
          )}
        />

        <Controller
          name="citizen_id_file"
          control={control}
          rules={{
            required:
              checkRole === "shipper" ||
              (checkRole.length === 1 && checkRole[0] === "shipper")
                ? false
                : "กรุณาอัพโหลดไฟล์บัตรประจำตัวประชาชน",
            validate: validateFile,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div>
              <FieldUpload
                name="ไฟล์บัตรประจำตัวประชาชน*"
                accept="image/jpeg, image/png, application/pdf"
                nameDescription={`(บังคับเฉพาะผู้ใช้งานที่มีบทบาทเป็นผู้ขนส่งและพนักงานขับรถ)`}
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={onChange}
                error={errors.citizen_id_file}
                textError={
                  errors.citizen_id_file
                    ? errors.citizen_id_file.message
                    : false
                }
              />
            </div>
          )}
        />
      </div>
    );
  };

  const renderFormPage4 = () => {
    return (
      <div>
        <div className={styles.titleUserRole}>
          <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
            {`ข้อมูลคนขับ*(บังคับถ้าเลือกบทบาทเป็นคนขับ)`}
          </Typography>
        </div>

        <Controller
          control={control}
          name="driver_truck_id"
          rules={{
            required: checkRole.includes("driver")
              ? "กรุณาเลือกประเภทรถที่ขับ"
              : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "20px" }}>
              <FieldSelect
                name={"ประเภทรถที่ขับ*"}
                options={masterTruck}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.driver_truck_id}
                errorText={
                  errors.driver_truck_id ? errors.driver_truck_id.message : ""
                }
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name="driving_license"
          rules={{
            required: checkRole.includes("driver")
              ? "กรุณาเลือกประเภทใบอนุญาตขับขี่"
              : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={"ประเภทใบอนุญาตขับขี่*"}
                options={masterDrivingLicense}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.driving_license}
                errorText={
                  errors.driving_license ? errors.driving_license.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="driver_license_front_file"
          control={control}
          rules={{
            required: checkRole.includes("driver")
              ? "กรุณาอัพโหลดไฟล์เอกสารใบขับขี่ด้านหน้า"
              : false,
            validate: validateFile,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldUpload
                name="ไฟล์เอกสารใบขับขี่ด้านหน้า*"
                style={{ width: "-webkit-fill-available" }}
                accept="image/jpeg, image/png, application/pdf"
                value={value}
                onChange={onChange}
                error={!!errors.driver_license_front_file}
                textError={
                  errors.driver_license_front_file
                    ? errors.driver_license_front_file.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="driver_license_back_file"
          control={control}
          rules={{
            required: checkRole.includes("driver")
              ? "กรุณาอัพโหลดไฟล์เอกสารใบขับขี่ด้านหลัง"
              : false,
            validate: validateFile,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldUpload
                name="ไฟล์เอกสารใบขับขี่ด้านหลัง*"
                style={{ width: "-webkit-fill-available" }}
                accept="image/jpeg, image/png, application/pdf"
                value={value}
                onChange={onChange}
                error={!!errors.driver_license_back_file}
                textError={
                  errors.driver_license_back_file
                    ? errors.driver_license_back_file.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="driver_license_expire_date"
          control={control}
          // rules={{ required: "กรุณากรอกวันหมดอายุใบขับขี่" }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldDatePicker
                label="วันหมดอายุใบขับขี่*"
                onChange={onChange}
                value={value}
                className="w-100"
              />
            </div>
          )}
        />

        <Controller
          name="driver_crime_history"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={"การตรวจประวัติอาชญากรรม*"}
                options={[
                  { value: "true", name: "มี" },
                  { value: "false", name: "ไม่มี" },
                ]}
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                value={value}
                error={!!errors.driver_crime_history}
                textError={
                  errors.driver_crime_history ? errors.driver_crime_history : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="driver_crime_history_file"
          control={control}
          rules={{
            required: driver_crime_history === "true" ? "กรุณาอัพโหลด" : false,
            validate: validateFile,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldUpload
                name="ไฟล์หลักฐานการตรวจประวัติอชญากรรม*"
                nameDescription={`(บังคับถ้ามีการตรวจประวัติอชญากรรม)`}
                accept="image/jpeg, image/png, application/pdf"
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                value={value}
                readOnly={driver_crime_history === "true" ? false : true}
                error={!!errors.driver_crime_history_file}
                textError={
                  errors.driver_crime_history_file
                    ? errors.driver_crime_history_file.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="driver_crime_history_consent"
          control={control}
          rules={{
            required: driver_crime_history === "false" ? "กรุณาเลือก" : false,
          }}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={"ยินยอมส่งเอกสารในภายหลัง*"}
                nameDescription={`(บังคับถ้าไม่มีการตรวจประวัติอชญากรรม)`}
                descriptionUnderField="กรณีไม่มีเอกสารฯ จะยินยอมให้ส่งเอกสารภายใน 15 วันหากมีการร้องขอ"
                options={[
                  { value: "true", name: "ยินยอม" },
                  { value: "false", name: "ไม่ยินยอม" },
                ]}
                readOnly={driver_crime_history === "true" ? true : false}
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                value={value}
                error={!!errors.driver_crime_history_consent}
                textError={
                  errors.driver_crime_history_consent
                    ? errors.driver_crime_history_consent.message
                    : ""
                }
              />
            </div>
          )}
        />
      </div>
    );
  };

  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form id="form-content" onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && renderFormPage1()}
              {step === 2 && renderFormPage2()}
              {step === 3 && renderFormPage3()}
              {step === 4 && renderFormPage4()}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={handlePrevious}
                  type={"cancel"}
                />
              </div>
              {step < 4 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name="ถัดไป"
                    onClick={handleSubmit(handleNext)}
                    fullWidth={true}
                  />
                </div>
              )}
              {step === 4 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name={"บันทึก"}
                    // type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateUser;
