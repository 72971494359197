import { Container, Grid, Paper, Typography } from "@mui/material";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import React, { useState, useEffect } from "react";
import helper from "utils/helper";
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Customized,
  LabelList,
  Legend,
  Line,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Star, StarBorder } from "@mui/icons-material";
import { PiFlagPennantFill } from "react-icons/pi";
import {
  getDashboardTrade,
  getDashboardTruckRental,
  getDashboardWork,
} from "utils/api/dashboard/newDashboard";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { getAllIndustry } from "utils/api/masterData/tradeMaster";
import LightTooltip from "components/tooltip/LightTooltip";
import { set } from "lodash";

const FinanceDashboard = () => {
  const [dataCurrent, setDataCurrent] = useState([]);
  const [dataMerge, setDataMerge] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [barData, setBarData] = useState(null);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [workData, setWorkData] = useState([]);
  const [workMax, setWorkMax] = useState(0);
  const [tradeData, setTradeData] = useState([]);
  const [tradeMax, setTradeMax] = useState(0);
  const [truckRentalData, setTruckRentalData] = useState([]);
  const [truckRentalMax, setTruckRentalMax] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalTarget, setTotalTarget] = useState(0);
  const [allIndustry, setAllIndustry] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [pieData, setPieData] = useState([]);
  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
  const [filterDashboard, setFilterDashboard] = useState({
    type_data_set: "day",
    start_date: firstDayOfMonth,
    end_date: yesterday,
    display: ["profit", "target"],
  });
  const subDashboard = [
    {
      label: "งานขนส่ง",
      path: "/dashboard/work",
    },
    {
      label: "งานซื้อขาย",
      path: "/dashboard/trade",
    },
    {
      label: "งานเช่า",
      path: "/dashboard/truck-rental",
    },
    {
      label: "การเงิน",
      path: "/dashboard/finance",
    },
  ];
  const breadcrumbs = [
    {
      label: "การเงิน",
      path: null,
    },
  ];

  console.log("pieData", pieData);

  useEffect(() => {
    getIndustry();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // const work = await getWorkData();
      const workArray = await Promise.all(
        [true, false].map((value) =>
          getWorkData({ ...filterDashboard, on_dashboard: value })
        )
      );
      const tradeArray = await Promise.all(
        allIndustry.map((industry) =>
          getTradeData(filterDashboard, industry.value)
        )
      );
      const rental = await getTruckRentalData();
      const result = summarizeArray(
        [...workArray, ...tradeArray, rental],
        allIndustry
      );
      setDataCurrent(result);
      console.log("setDataCurrent", result);
      const total_sum = result.reduce((sum, item) => sum + Number(item.sum), 0);
      const total_target = result.reduce(
        (sum, item) => sum + Number(item.target),
        0
      );
      const total_cost = result.reduce(
        (sum, item) => sum + Number(item.sum_cost),
        0
      );
      setTotalCost(total_cost);
      setTotalIncome(total_sum);
      setTotalTarget(total_target);
      setBarData(result[result.length - 1]);
      setGraph1(result[result.length - 2]);
      setGraph2(result[result.length - 1]);
      const max = Math.max(...result.map((item) => item.sum));
      const maxTarget = Math.max(...result.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      setMaxValue(niceMax > 0 ? niceMax : 100);
    };
    if (allIndustry.length > 0) {
      fetchData();
    }
  }, [filterDashboard, allIndustry]);

  useEffect(() => {
    // custom value for pie chart
    if (barData) {
      console.log("barData", barData);
      const total = barData.sum;
      const total_cost = barData.sum_cost;
      const total_profit = total - total_cost;

      const filteredData = Object.entries(barData)
        .filter(
          ([key, value]) =>
            ![
              "xname",
              "sum",
              "sum2",
              "sum_cost",
              "sum_cost_work_project",
              "sum_cost_work_3pl",
              "sum_cost_truck_rental",
              "labelDiff",
              "labelCost",
              ...allIndustry.map(
                (industry) => `sum_cost_trade_${industry.value}`
              ), // Exclude sum_cost_trade keys
              "target",
            ].includes(key)
        )
        .map(([key, value]) => {
          let industryName =
            key === "sum_work_project"
              ? "งานขนส่ง - งานอื่นๆที่ไม่ใช่ 3PL"
              : key === "sum_work_3pl"
              ? "งานขนส่ง - 3PL"
              : key === "sum_truck_rental"
              ? "งานเช่า"
              : allIndustry.find(
                  (industry) => key === `sum_trade_${industry.value}`
                )?.name;

          // Find cost for trade industries
          const costKeyTrade = `sum_cost_trade_${key.replace(
            "sum_trade_",
            ""
          )}`;
          const costValueTrade = barData[costKeyTrade]
            ? parseFloat(barData[costKeyTrade])
            : 0;

          // Find cost for work categories (work_project, work_3pl, truck_rental)
          const costKeyWork =
            key === "sum_work_project"
              ? "sum_cost_work_project"
              : key === "sum_work_3pl"
              ? "sum_cost_work_3pl"
              : key === "sum_truck_rental"
              ? "sum_cost_truck_rental"
              : null;

          const costValueWork =
            costKeyWork && barData[costKeyWork]
              ? parseFloat(barData[costKeyWork])
              : 0;

          // Final cost is either trade cost or work cost
          const totalCost = costValueTrade + costValueWork;
          const totalProfit = parseFloat(value) - totalCost;
          return {
            name: industryName,
            value: parseFloat(value),
            percentage: ((parseFloat(value) / total) * 100).toFixed(2),
            cost: totalCost, // Include both trade and work costs
            percentageCost: ((totalCost / total_cost) * 100).toFixed(2),
            profit: totalProfit,
            percentageProfit: ((totalProfit / total_profit) * 100).toFixed(2),
          };
        })
        .sort((a, b) => b.value - a.value); // Sort by value

      console.log("filteredData", filteredData);

      let newFilteredData = filteredData?.filter(
        (item) => !(item.cost == 0 && item.value == 0)
      );
      console.log("newFilteredData", newFilteredData);
      setPieData(newFilteredData);
    }
  }, [barData]);
  const getRandomRGBA = (alpha = 0.8) => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.random() * 20;
    const lightness = 50 + Math.random() * 10;

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
      s /= 100;
      l /= 100;
      const k = (n) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

      return [
        Math.round(f(0) * 255),
        Math.round(f(8) * 255),
        Math.round(f(4) * 255),
      ];
    };

    const [r, g, b] = hslToRgb(hue, saturation, lightness);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;
    const firstDataKey = "sum";
    const secondDataKey = "sum_cost";

    const firstSeries = formattedGraphicalItems.find(
      (item) => item.item.props.dataKey === firstDataKey
    );
    const secondSeries = formattedGraphicalItems.find(
      (item) => item.item.props.dataKey === secondDataKey
    );
    if (
      !firstSeries ||
      !secondSeries ||
      !firstSeries.props?.points ||
      !secondSeries.props?.points
    ) {
      return null;
    }

    return firstSeries.props.points.map((firstPoint, index) => {
      const secondPoint = secondSeries.props.points[index];

      if (!secondPoint) return null;

      const yDifference = firstPoint.y - secondPoint.y;
      const rectHeight = Math.abs(yDifference);
      const rectY = Math.min(firstPoint.y, secondPoint.y);

      return (
        <Rectangle
          key={firstPoint.payload.name}
          width={10}
          height={rectHeight}
          x={secondPoint.x - 5}
          y={rectY}
          fill={
            yDifference > 0
              ? "rgba(255, 49, 111, 0.5)"
              : yDifference < 0
              ? "rgba(72, 208, 80, 0.5)"
              : "none"
          }
        />
      );
    });
  };
  const summarizeArray = (arrays, allIndustry) => {
    console.log("arrays", arrays);
    const summary = {};
    arrays.flat().forEach((item) => {
      const {
        xname,
        sum_work_project = 0,
        sum_work_3pl = 0,
        sum_truck_rental = 0,
        sum_cost_work_project = 0,
        sum_cost_work_3pl = 0,
        sum_cost_truck_rental = 0,
        sum = 0,
        target = 0,
        sum_cost = 0,
        ...tradeSums
      } = item;

      if (!summary[xname]) {
        summary[xname] = {
          sum_work_project: 0,
          sum_work_3pl: 0,
          sum_truck_rental: 0,
          sum_cost_work_project: 0,
          sum_cost_work_3pl: 0,
          sum_cost_truck_rental: 0,
          sum: 0,
          target: 0,
          sum_cost: 0,
        };

        allIndustry?.forEach(({ value }) => {
          summary[xname][`sum_trade_${value}`] = 0;
        });
        allIndustry?.forEach(({ value }) => {
          summary[xname][`sum_cost_trade_${value}`] = 0;
        });
      }

      summary[xname].sum_work_project += parseFloat(sum_work_project);
      summary[xname].sum_work_3pl += parseFloat(sum_work_3pl);
      summary[xname].sum_truck_rental += parseFloat(sum_truck_rental);
      summary[xname].sum_cost_work_project += parseFloat(sum_cost_work_project);
      summary[xname].sum_cost_work_3pl += parseFloat(sum_cost_work_3pl);
      summary[xname].sum_cost_truck_rental += parseFloat(sum_cost_truck_rental);
      summary[xname].sum += parseFloat(sum);
      summary[xname].target += parseFloat(target);
      summary[xname].sum_cost += parseFloat(sum_cost);

      // Aggregate dynamic trade sums
      allIndustry?.forEach(({ value }) => {
        const key = `sum_trade_${value}`;
        if (tradeSums[key] !== undefined) {
          summary[xname][key] += Number(tradeSums[key] || 0);
        }
      });
      allIndustry?.forEach(({ value }) => {
        const key = `sum_cost_trade_${value}`;
        if (tradeSums[key] !== undefined) {
          summary[xname][key] += parseFloat(tradeSums[key] || 0);
        }
      });
    });

    return Object.entries(summary).map(([xname, values]) => {
      const formatted = { xname };

      let diffTotal = helper.calculatePercentage2(values.sum, values.sum_cost);
      let diffCost = (values.sum_cost / values.sum) * 100;
      let labelDiff = isNaN(diffTotal)
        ? "-"
        : `${helper.abbreviateNumber(values.sum) ?? 0},${
            diffTotal > 0
              ? `+${helper.abbreviateNumber(values.sum - values.sum_cost) ?? 0}`
              : helper.abbreviateNumber(values.sum - values.sum_cost) ?? 0
          },${diffTotal}`;
      let labelCost = isNaN(diffCost)
        ? "-"
        : `${helper.abbreviateNumber(values.sum_cost) ?? 0},
           ${diffCost.toFixed(2)}`;
      Object.keys(values).forEach((key) => {
        formatted[key] = values[key];
        formatted[`labelDiff`] = labelDiff;
        formatted[`labelCost`] = labelCost;
      });

      return formatted;
    });
  };

  useEffect(() => {
    if (dataCurrent) {
      getYearAgoData();
    }
  }, [dataCurrent]);

  const getIndustry = async () => {
    await getAllIndustry({ type: "list_select" })
      .then((res) => {
        const results = res?.data?.results;
        setAllIndustry(
          results?.map((res) => ({
            value: res.id,
            name: `งานซื้อขาย - ${res.name}`,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getYearAgoData = async (fil = filterDashboard) => {
    let _filter = {
      ...fil,
      start_date: moment(fil.start_date).subtract(1, "year"),
      end_date: moment(fil.end_date).subtract(1, "year"),
    };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    delete _filter.display;
    delete _filter.display_count;

    let workYearAgoData = [];
    let workYearAgoMax = 0;
    let tradeYearAgoData = [];
    let tradeYearAgoMax = 0;
    let truckRentalYearAgoData = [];
    let truckRentalYearAgoMax = 0;
    await getDashboardWork(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { company_name, shipper_freight_before_tax }) => {
                if (!acc[company_name]) {
                  acc[company_name] = {
                    company_name,
                    count: 0,
                    income: 0,
                  };
                }
                acc[company_name].count += 1;
                acc[company_name].income += shipper_freight_before_tax;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
      });

      workYearAgoData = mapGroups;
      const max = Math.max(...response.map((item) => item.sum));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      workYearAgoMax = niceMaxValue;
    });

    await getDashboardTrade(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          label: `${helper.formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, amount_per_trip, sell_per_trip }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    count: 0,
                    income: 0,
                    amount_per_trip: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].income += sell_per_trip;
                acc[project_name].amount_per_trip += amount_per_trip;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
      });
      tradeYearAgoData = mapGroups;
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      tradeYearAgoMax = niceMax;
    });

    await getDashboardTruckRental(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          label: `${helper.formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, total_tenant, tenant_amount }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    amount: 0,
                    sum: 0,
                    count: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].sum += total_tenant;
                acc[project_name].amount += tenant_amount;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.sum - a.sum),
        };
      });
      truckRentalYearAgoData = mapGroups;
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      truckRentalYearAgoMax = niceMax > 0 ? niceMax : 100;
    });

    const result = summarizeArray([
      workYearAgoData,
      tradeYearAgoData,
      truckRentalYearAgoData,
    ]);

    const newDataSet = dataCurrent.map((item, index) => ({
      ...item,
      sum2: result.find((e, i) => i === index)?.sum || 0,
    }));
    setDataMerge(newDataSet);
    const max = Math.max(...result.map((item) => item?.sum));
    const maxTarget = Math.max(...result.map((item) => item.target));
    const niceMaxValue = Math.ceil(max + max * 0.1);
    const niceMaxTargetValue = Math.ceil(maxTarget);
    const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
    filterDashboard.display.includes("compare") &&
      setMaxValue((prev) => (prev > niceMax ? prev : niceMax));
  };
  const getWorkData = async (fil = filterDashboard) => {
    try {
      let _filter = { ...fil };

      if (_filter.project_type === "all") delete _filter.project_type;
      delete _filter.display;
      delete _filter.display_count;

      const res = await getDashboardWork(_filter);
      const response = res?.data?.data || [];

      const mapGroups = response.map((item) => {
        const result = {
          ...item,
          [`sum_work_${_filter.on_dashboard ? "3pl" : "project"}`]: item.sum,
          [`sum_cost_work_${_filter.on_dashboard ? "3pl" : "project"}`]:
            item.sum_cost,
          groups: Object.values(
            (item.groups || []).reduce(
              (acc, { company_name, shipper_freight_before_tax }) => {
                if (!acc[company_name]) {
                  acc[company_name] = { company_name, count: 0, income: 0 };
                }
                acc[company_name].count += 1;
                acc[company_name].income += shipper_freight_before_tax;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
        //because it use same taget
        if (_filter.on_dashboard === true) {
          delete result.target;
        }
        return result;
      });

      setWorkData(mapGroups);
      const max = Math.max(0, ...response.map((item) => item.sum || 0));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      setWorkMax(niceMaxValue);
      return mapGroups;
    } catch (error) {
      console.error("Error fetching work data:", error);
      return [];
    }
  };
  const getTradeData = async (fil = filterDashboard, industry) => {
    try {
      let _filter = { ...fil, industry_id: industry };

      if (_filter.on_dashboard === "all") delete _filter.on_dashboard;
      if (_filter.project_type === "all") delete _filter.project_type;
      delete _filter.display;
      delete _filter.display_count;

      const res = await getDashboardTrade(_filter);
      const response = res.data.data || [];
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          [`sum_trade_${industry}`]: item.sum,
          [`sum_cost_trade_${industry}`]: item.sum_cost,
          label: `${helper.formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, amount_per_trip, sell_per_trip }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    count: 0,
                    income: 0,
                    amount_per_trip: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].income += sell_per_trip;
                acc[project_name].amount_per_trip += amount_per_trip;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
      });
      setTradeData(mapGroups);
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      setTradeMax(niceMax > 0 ? niceMax : 100);
      return mapGroups;
    } catch (error) {
      console.error("Error fetching trade data:", error);
      return [];
    }
  };
  const getTruckRentalData = async (fil = filterDashboard) => {
    try {
      let _filter = { ...fil };
      if (_filter.on_dashboard === "all") delete _filter.on_dashboard;
      if (_filter.project_type === "all") delete _filter.project_type;
      delete _filter.display;
      delete _filter.display_count;
      const res = await getDashboardTruckRental(_filter);
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          sum_truck_rental: item.sum,
          sum_cost_truck_rental: item.sum_cost,
          label: `${helper.formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, total_tenant, tenant_amount }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    amount: 0,
                    sum: 0,
                    count: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].sum += total_tenant;
                acc[project_name].amount += tenant_amount;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.sum - a.sum),
        };
      });
      setTruckRentalData(mapGroups);
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      setTruckRentalMax(niceMax > 0 ? niceMax : 100);
      return mapGroups;
    } catch (error) {
      console.error("Error fetching truck rental data:", error);
      return [];
    }
  };
  const renderFilterBar = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px 20px 0px 20px",
          borderRadius: "10px",
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldSelect
              name={"แสดงตาม"}
              options={[
                { value: "year", name: "รายปี" },
                { value: "month", name: "รายเดือน" },
                { value: "week", name: "รายสัปดาห์" },
                { value: "day", name: "รายวัน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"day"}
              value={filterDashboard.type_data_set}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  type_data_set: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterDashboard.start_date ?? firstDayOfMonth,
                end_date: filterDashboard.end_date ?? yesterday,
              }}
              style={{
                width: "-webkit-fill-available",
              }}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
            paddingTop: "80px",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>
                {entry.value === "sum"
                  ? "รายได้"
                  : entry.value === "sum_cost"
                  ? "ต้นทุน"
                  : entry.value === "sum2"
                  ? "รายได้เมื่อปีที่แล้ว"
                  : entry.value === "target"
                  ? "เป้าหมาย"
                  : entry.value === "sum_work_project"
                  ? "รายได้งานขนส่ง - งานอื่นๆที่ไม่ใช่ 3PL"
                  : entry.value === "sum_work_3pl"
                  ? "รายได้งานขนส่ง - 3PL"
                  : entry.value === "sum_trade"
                  ? "รายได้งานซื้อขาย"
                  : entry.value === "sum_truck_rental"
                  ? "รายได้งานเช่า"
                  : allIndustry.map((industry) => {
                      if (entry.value === `sum_trade_${industry.value}`) {
                        return `${industry.name}`;
                      }
                    })}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey === "target"
                ? "เป้าหมาย : "
                : entry.dataKey === "sum2"
                ? "รายได้เมื่อปีที่แล้ว : "
                : entry.dataKey === "sum"
                ? "รายได้ : "
                : entry.dataKey === "sum_cost"
                ? "ต้นทุน : "
                : entry.dataKey === "sum_work_project"
                ? "รายได้งานขนส่ง - งานอื่นๆที่ไม่ใช่ 3PL : "
                : entry.dataKey === "sum_work_3pl"
                ? "รายได้งานขนส่ง - 3PL : "
                : entry.dataKey === "sum_trade"
                ? "รายได้งานซื้อขาย : "
                : entry.dataKey === "sum_truck_rental"
                ? "รายได้งานเช่า : "
                : allIndustry.map((industry) => {
                    if (entry.dataKey === `sum_trade_${industry.value}`) {
                      return `รายได้${industry.name} : `;
                    }
                  })}
              {helper.formatNumber(Number(entry.value).toFixed(2)) + " บาท"}
            </span>
          </div>
        ))}
        {filterDashboard.display.includes("target") &&
          (filterDashboard.display.includes("revenue") ||
            filterDashboard.display.includes("profit")) && (
            <div>
              <div style={{ fontSize: 10, paddingLeft: 15 }}>
                ส่วนต่างจากเป้า :
                <span
                  style={{
                    fontSize: 10,
                    color:
                      Number(payload[0].payload.sum) -
                        Number(payload[0].payload.target) >
                      0
                        ? "green"
                        : Number(payload[0].payload.sum) -
                            Number(payload[0].payload.target) <
                          0
                        ? "red"
                        : "primary.main",
                  }}
                >
                  {helper.formatNumber(
                    (
                      Number(payload[0].payload.sum) -
                      Number(payload[0].payload.target)
                    ).toFixed(2)
                  ) + " บาท"}{" "}
                  (
                  {helper.formatNumber(
                    helper.calculatePercentage(
                      Number(payload[0].payload.target),
                      Number(payload[0].payload.sum)
                    )
                  ) + " %"}
                  )
                </span>
              </div>
              <div style={{ fontSize: 10, paddingLeft: 15 }}>
                กำไร :
                <span
                  style={{
                    fontSize: 10,
                    color:
                      Number(payload[0].payload.sum) -
                        Number(payload[0].payload.sum_cost) >
                      0
                        ? "green"
                        : Number(payload[0].payload.sum) -
                            Number(payload[0].payload.sum_cost) <
                          0
                        ? "red"
                        : "primary.main",
                  }}
                >
                  {helper.formatNumber(
                    (
                      Number(payload[0].payload.sum) -
                      Number(payload[0].payload.sum_cost)
                    ).toFixed(2)
                  ) + " บาท"}{" "}
                  (
                  {helper.calculatePercentage2(
                    payload[0].payload.sum,
                    payload[0].payload.sum_cost
                  )}
                  %)
                </span>
              </div>
            </div>
          )}
        {filterDashboard.display.includes("service") && (
          <div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              FTL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {helper.formatNumber(
                  (
                    (Number(payload[0].payload.sum_ftl) /
                      Number(payload[0].payload.sum)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              STL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {helper.formatNumber(
                  (
                    (Number(payload[0].payload.sum_stl) /
                      Number(payload[0].payload.sum)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const CustomTooltipProportion = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                background:
                  entry.dataKey === "profit"
                    ? `linear-gradient(to right, #27df1c 50%, rgba(255, 49, 111, 0.75) 50%)`
                    : entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey === "value"
                ? "รายได้ : "
                : entry.dataKey === "cost"
                ? "ต้นทุน : "
                : entry.dataKey === "profit"
                ? "กำไร : "
                : ""}
              {helper.formatNumber(Number(entry.value).toFixed(2)) + " บาท"}
            </span>
          </div>
        ))}
      </div>
    );
  };
  const CustomLegendProportion = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  // backgroundColor: entry.color,
                  background:
                    entry.value === "profit"
                      ? `linear-gradient(to right, #27df1c 50%, rgba(255, 49, 111, 0.75) 50%)`
                      : entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>
                {entry.value === "value"
                  ? "รายได้"
                  : entry.value === "cost"
                  ? "ต้นทุน"
                  : entry.value === "profit"
                  ? "กำไร"
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const CustomBarShape = (props) => {
    const { x, y, width, height, fill, stroke } = props;
    const borderRadius = 5;
    const borderColor = stroke;
    const borderWidth = 1;
    if (height <= 0) {
      return null;
    }
    return (
      <path
        d={`
          M${x},${y + height} 
          L${x},${y + borderRadius} 
          Q${x},${y} ${x + borderRadius},${y} 
          L${x + width - borderRadius},${y} 
          Q${x + width},${y} ${x + width},${y + borderRadius} 
          L${x + width},${y + height} 
          Z
        `}
        fill={fill}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
    );
  };
  return (
    <Container>
      <SubMenu
        menus={subDashboard.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `การเงิน` ? true : false,
          };
        })}
        breadcrumbs={breadcrumbs}
      />
      {renderFilterBar()}
      <Grid
        container
        spacing={1}
        sx={{ alignItems: "stretch", marginTop: "10px" }}
      >
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <div
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={1.5}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  fontSize={18}
                  style={{
                    textAlign: "start",
                    fontWeight: 500,
                  }}
                >
                  รายได้รวม
                </Typography>
              </Grid>
              <Grid
                style={{ paddingRight: "20px" }}
                item
                xs={11.5}
                sm={6}
                md={6}
                lg={2.4}
              >
                <FieldSelect
                  name={"แสดงผล"}
                  options={[
                    { value: "target", name: "เป้าหมาย" },
                    { value: "profit", name: "ต้นทุน,รายได้, กำไร" },
                    { value: "cost", name: "ต้นทุน" },
                    { value: "revenue", name: "รายได้" },
                    { value: "compare", name: "ช่วงนี้เมื่อปีที่แล้ว" },
                    {
                      value: "work_project",
                      name: "งานขนส่ง - งานอื่นๆที่ไม่ใช่ 3PL",
                    },
                    { value: "work_3pl", name: "งานขนส่ง - 3PL" },
                    ...allIndustry,
                    { value: "truck_rental", name: "งานเช่า" },
                  ]}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  value={filterDashboard.display}
                  defaultValue={[]}
                  multiSelect={true}
                  onChange={(val) =>
                    setFilterDashboard({
                      ...filterDashboard,
                      display: val,
                    })
                  }
                  closePaddingBottom={true}
                />
              </Grid>
            </Grid>
            <ResponsiveContainer width="100%" height={430}>
              <ComposedChart
                width={730}
                height={434}
                data={dataMerge}
                onClick={(data) => {
                  if (data.activePayload) {
                    setBarData(data?.activePayload[0]?.payload);
                    if (data?.activeTooltipIndex > 0) {
                      setGraph1(dataMerge[data?.activeTooltipIndex - 1]);
                    } else {
                      setGraph1(null);
                    }
                    setGraph2(data?.activePayload[0]?.payload);
                  }
                }}
                margin={{
                  top: 124,
                  right: 65,
                  left: 20,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#e1e9ea" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#e1e9ea" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey={"xname"}
                  style={{ fontSize: "10px", fontWeight: 500 }}
                  dy={80}
                />
                <YAxis
                  style={{ fontSize: "10px", fontWeight: 500 }}
                  tickFormatter={helper.abbreviateNumber}
                  domain={[0, maxValue]}
                  dx={-10}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  contentStyle={{ zIndex: 999999 }}
                />
                <Legend content={<CustomLegend />} />
                <CartesianGrid stroke="#f5f5f5" />
                <Customized component={CustomizedRectangle} />
                {filterDashboard.display.includes("target") && (
                  <Line
                    strokeWidth={2}
                    dataKey="target"
                    stroke={`rgba(255, 49, 111,0.5)`}
                    dot={false}
                    strokeDasharray="5 5"
                  />
                )}
                {filterDashboard.display.includes("compare") && (
                  <Area
                    dataKey="sum2"
                    stroke={`#729094`}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  >
                    <LabelList
                      dataKey="sum2"
                      position="top"
                      angle={-90}
                      fill={`#729094`}
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        textAnchor: "start",
                      }}
                      formatter={helper.abbreviateNumber}
                    />
                  </Area>
                )}
                {filterDashboard.display.includes("profit") && (
                  <>
                    <Line
                      strokeWidth={2}
                      dataKey={"sum"}
                      fill={`rgba(78, 85, 212,0.3)`}
                      stroke={`#4e55d4`}
                    >
                      <LabelList
                        dataKey="labelDiff"
                        fill={`#4e55d4`}
                        content={(props) => {
                          const { x, y, value, width, target, fill } = props;
                          const val = value.split(",");
                          return (
                            <text
                              x={x + 5}
                              y={y - 10}
                              fill={fill}
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-90, ${x + 5}, ${y})`}
                            >
                              <tspan>
                                {Number(val[0]) === 0
                                  ? "-"
                                  : helper.formatNumber(val[0])}
                              </tspan>
                              <tspan> </tspan>
                              <tspan
                                fill={
                                  Number(val[2]) > 0
                                    ? "rgb(72, 208, 80)"
                                    : Number(val[2]) < 0
                                    ? "rgb(255, 49, 111)"
                                    : "#FFC675"
                                }
                              >
                                {val[2] ? `${val[1]}, ${val[2]} %` : ""}
                              </tspan>
                            </text>
                          );
                        }}
                      />
                    </Line>
                    <Line
                      strokeWidth={2}
                      dataKey={"sum_cost"}
                      fill={`rgba(249, 196, 54,0.5)`}
                      stroke={`#f9c436`}
                    >
                      <LabelList
                        dataKey="labelCost"
                        fill={`#ff9e00`}
                        content={(props) => {
                          const { x, y, value, width, target, fill } = props;
                          const val = value.split(",");
                          return (
                            <text
                              x={x - 3}
                              y={y + 12}
                              fill={fill}
                              textAnchor="end"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-90, ${x}, ${y})`}
                            >
                              <tspan>
                                {Number(val[0]) === 0
                                  ? "-"
                                  : helper.formatNumber(val[0])}
                              </tspan>
                              <tspan>, </tspan>
                              <tspan fill={"#ff0000"}>
                                {val[1] ? `${val[1]} %` : ""}
                              </tspan>
                            </text>
                          );
                        }}
                      />
                    </Line>
                  </>
                )}
                {filterDashboard.display.includes("revenue") && (
                  <Bar
                    dataKey="sum"
                    barSize={20}
                    fill={`rgba(78, 85, 212,0.3)`}
                    stroke={`#4e55d4`}
                    onClick={(data, index) => {
                      setBarData(data.payload);
                      if (index > 0) {
                        setGraph1(dataCurrent[index - 1]);
                      } else {
                        setGraph1(null);
                      }
                      setGraph2(data.payload);
                    }}
                    shape={<CustomBarShape />}
                  >
                    <LabelList
                      dataKey="sum"
                      content={(props) => {
                        const { x, y, value, width } = props;
                        // const lines = value.split("\n");
                        const offset = 40;
                        return (
                          <text
                            x={x}
                            y={y}
                            fill="#4e55d4"
                            textAnchor="top"
                            fontSize="14px"
                            fontWeight="500"
                            transform={`rotate(-90, ${x + 10}, ${y})`}
                          >
                            <tspan
                              x={x + 14}
                              dy={13}
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {helper.abbreviateNumber(value)}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Bar>
                )}
                {filterDashboard.display.includes("cost") && (
                  <Bar
                    dataKey="sum_cost"
                    barSize={20}
                    fill={`rgba(39, 223, 28,0.3)`}
                    stroke={`#27df1c`}
                    onClick={(data, index) => {
                      setBarData(data.payload);
                      if (index > 0) {
                        setGraph1(dataCurrent[index - 1]);
                      } else {
                        setGraph1(null);
                      }
                      setGraph2(data.payload);
                    }}
                    shape={<CustomBarShape />}
                  >
                    <LabelList
                      dataKey="sum_cost"
                      content={(props) => {
                        const { x, y, value, width } = props;
                        const offset = 40;
                        return (
                          <text
                            x={x}
                            y={y}
                            fill="#27df1c"
                            textAnchor="top"
                            fontSize="14px"
                            fontWeight="500"
                            transform={`rotate(-90, ${x + 10}, ${y})`}
                          >
                            <tspan
                              x={x + 14}
                              dy={13}
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {helper.abbreviateNumber(value)}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Bar>
                )}
                {filterDashboard.display.includes("work_project") && (
                  <Line
                    dataKey={"sum_work_project"}
                    barSize={20}
                    fill={`rgba(0, 196, 160,0.5)`}
                    stroke={`rgba(0, 196, 160, 1)`}
                  />
                )}
                {filterDashboard.display.includes("work_3pl") && (
                  <Line
                    dataKey={"sum_work_3pl"}
                    barSize={20}
                    fill={`rgba(196, 114, 0, 0.5)`}
                    stroke={`rgba(196, 114, 0, 1)`}
                  />
                )}
                {allIndustry.map((i) => {
                  const baseColor = getRandomRGBA();
                  const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                  const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                  return (
                    filterDashboard.display.includes(i.value) && (
                      <Line
                        dataKey={`sum_trade_${i.value}`}
                        barSize={20}
                        fill={fillColor}
                        stroke={strokeColor}
                      />
                    )
                  );
                })}
                {filterDashboard.display.includes("truck_rental") && (
                  <Line
                    dataKey={"sum_truck_rental"}
                    barSize={20}
                    fill={`rgba(36, 180, 237, 0.5)`}
                    stroke={`rgba(36, 180, 237, 1)`}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <Typography
              fontSize={18}
              style={{
                textAlign: "center",
                marginBottom: "15px",
                fontWeight: 500,
              }}
            >
              สัดส่วน
              {filterDashboard.display.includes("profit")
                ? "ต้นทุน,รายได้,กำไร"
                : filterDashboard.display.includes("revenue") &&
                  filterDashboard.display.includes("cost")
                ? "ต้นทุน,รายได้"
                : filterDashboard.display.includes("revenue")
                ? "รายได้"
                : filterDashboard.display.includes("cost")
                ? "ต้นทุน"
                : ""}
              <p style={{ margin: 0 }}>
                ประจํา
                {filterDashboard.type_data_set === "day"
                  ? "วัน"
                  : filterDashboard.type_data_set === "week"
                  ? "สัปดาห์"
                  : filterDashboard.type_data_set === "month"
                  ? "เดือน"
                  : "ปี"}{" "}
                {barData?.xname}
              </p>
            </Typography>
            <ResponsiveContainer width="100%" height={420}>
              <BarChart
                data={pieData}
                layout="vertical"
                margin={{ left: 30, right: 90 }}
              >
                <XAxis
                  type="number"
                  tickFormatter={(tick) => helper.abbreviateNumber(tick)}
                  style={{ fontSize: "12px", fontWeight: 500 }}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  // width={150}
                  style={{ fontSize: "14px", fontWeight: 500 }}
                />
                <Tooltip content={<CustomTooltipProportion />} />
                <Legend content={<CustomLegendProportion />} />

                {(filterDashboard.display.includes("profit") ||
                  filterDashboard.display.includes("revenue")) && (
                  <Bar dataKey="value" barSize={10} fill={`#4e55d4`}>
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={`#4e55d4`} />
                    ))}
                    <LabelList
                      dataKey="percentage"
                      position="right"
                      content={({ x, y, width, value, index }) => {
                        const barColor = `#4e55d4`;

                        return (
                          <text
                            x={x + width + 5}
                            y={y + 15}
                            // fill={barColor}
                            textAnchor="start"
                            style={{
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            <tspan x={x + width + 5} dy="-5">
                              {`${helper.abbreviateNumber(
                                pieData[index]?.value
                              )}`}
                              , {`${value}%`}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Bar>
                )}
                {(filterDashboard.display.includes("profit") ||
                  filterDashboard.display.includes("cost")) && (
                  <Bar dataKey="cost" barSize={10} fill={"#f9c436"}>
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={"#f9c436"} />
                    ))}
                    <LabelList
                      dataKey="percentageCost"
                      position="right"
                      content={({ x, y, width, value, index }) => {
                        return (
                          <text
                            x={x + width + 5}
                            y={y}
                            // fill={"#f9c436"}
                            textAnchor="start"
                            style={{
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            <tspan x={x + width + 5} dy="10">
                              {`${helper.abbreviateNumber(
                                pieData[index]?.cost
                              )}`}
                              , {`${value}%`}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Bar>
                )}
                {filterDashboard.display.includes("profit") && (
                  <Bar dataKey="profit" barSize={10}>
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.value > 0
                            ? `#27df1c`
                            : `rgba(255, 49, 111, 0.75)`
                        }
                      />
                    ))}
                    <LabelList
                      dataKey="percentageProfit"
                      position={"right"}
                      content={({ x, y, width, value, index }) => {
                        return (
                          <text
                            x={x + width + 5}
                            y={y}
                            textAnchor="start"
                            style={{
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            <tspan x={x + width + 5} dy="10">
                              {`${helper.abbreviateNumber(
                                pieData[index]?.profit
                              )}`}
                              , {`${value}%`}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                  </Bar>
                )}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{
          paddingTop: "8px",
          alignItems: "stretch",
          paddingBottom: "20px",
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <Typography
                      fontSize={16}
                      style={{
                        textAlign: "start",
                        fontWeight: 500,
                      }}
                    >
                      รายได้และ
                      <br />
                      แผนสะสม
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage(
                        Number(totalTarget),
                        Number(totalIncome)
                      ) > 0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage(
                          Number(totalTarget),
                          Number(totalIncome)
                        ) < 0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        color:
                          helper.calculatePercentage(
                            Number(totalTarget),
                            Number(totalIncome)
                          ) > 0
                            ? "#27CE88"
                            : helper.calculatePercentage(
                                Number(totalTarget),
                                Number(totalIncome)
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {totalTarget && totalIncome
                        ? helper.formatNumber(
                            helper.calculatePercentage(
                              Number(totalTarget),
                              Number(totalIncome)
                            )
                          ) + " %"
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  เป้าหมายสะสม :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.addComma(totalTarget.toFixed(2)) + " บาท" ?? "-"}
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  รายได้สะสม :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.addComma(totalIncome.toFixed(2)) + " บาท" ?? "-"}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  ส่วนต่าง :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber((totalIncome - totalTarget).toFixed(2)) +
                    " บาท" ?? "-"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    รายได้และแผน
                    <br />
                    {" " + barData?.xname + " "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage(
                        Number(barData?.target),
                        Number(barData?.sum)
                      ) > 0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage(
                          Number(barData?.target),
                          Number(barData?.sum)
                        ) < 0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        color:
                          helper.calculatePercentage(
                            Number(barData?.target),
                            Number(barData?.sum)
                          ) > 0
                            ? "#27CE88"
                            : helper.calculatePercentage(
                                Number(barData?.target),
                                Number(barData?.sum)
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {totalTarget && totalIncome
                        ? helper.formatNumber(
                            helper.calculatePercentage(
                              Number(barData?.target),
                              Number(barData?.sum)
                            )
                          ) + " %"
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  เป้าหมาย :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(barData?.target.toFixed(2))} บาท
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  รายได้ :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(barData?.sum.toFixed(2))} บาท
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  ส่วนต่าง :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(
                    (Number(barData?.sum) - Number(barData?.target)).toFixed(2)
                  ) + " บาท"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    รายได้ต่างจาก
                    {" " + (graph1?.xname ?? "") + " "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage(graph1?.sum, graph2?.sum) >
                      0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage(graph1?.sum, graph2?.sum) <
                        0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                        color:
                          Number(
                            helper.calculatePercentage(graph1?.sum, graph2?.sum)
                          ) > 0
                            ? "#27CE88"
                            : Number(
                                helper.calculatePercentage(
                                  graph1?.sum,
                                  graph2?.sum
                                )
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {graph1 && graph2
                        ? helper.addComma(
                            helper.calculatePercentage(graph1.sum, graph2.sum)
                          ) + " %"
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {!(graph2?.sum === 0 && graph1?.sum === 0)
                    ? Number(graph2?.sum - graph1?.sum) > 0
                      ? "สูงกว่าช่วงก่อนหน้า"
                      : Number(graph2?.sum - graph1?.sum) < 0
                      ? "ต่ำกว่าช่วงก่อนหน้า"
                      : Number(graph2?.sum - graph1?.sum) === 0
                      ? "เท่ากับช่วงก่อนหน้า"
                      : "-"
                    : "-"}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {graph1 && graph2
                    ? helper.addComma((graph2.sum - graph1.sum).toFixed(2)) +
                      " บาท"
                    : "-"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        {/* </Grid>
      <Grid
        container
        spacing={1}
        style={{
          marginTop: "10px",
          alignItems: "stretch",
          paddingBottom: "20px",
        }}
      > */}
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    รายได้และ
                    <br />
                    กำไรสะสม
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage2(totalIncome, totalCost) >
                      0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage2(totalIncome, totalCost) <
                        0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        color:
                          helper.calculatePercentage2(totalIncome, totalCost) >
                          0
                            ? "#27CE88"
                            : helper.calculatePercentage2(
                                totalIncome,
                                totalCost
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {totalCost && totalIncome
                        ? helper.calculatePercentage2(totalIncome, totalCost) +
                          " %"
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  รายได้สะสม :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.addComma(totalIncome.toFixed(2)) + " บาท" ?? "-"}
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  ต้นทุนสะสม :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.addComma(totalCost.toFixed(2)) + " บาท" ?? "-"}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  กำไรสะสม :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber((totalIncome - totalCost).toFixed(2)) +
                    " บาท" ?? "-"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    กำไร
                    {filterDashboard.type_data_set === "day"
                      ? "วัน"
                      : filterDashboard.type_data_set === "week"
                      ? "สัปดาห์"
                      : filterDashboard.type_data_set === "month"
                      ? "เดือน"
                      : "ปี"}
                    <br />
                    {" " + barData?.xname + " "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage2(
                        Number(barData?.sum),
                        Number(barData?.sum_cost)
                      ) > 0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage2(
                          Number(barData?.sum),
                          Number(barData?.sum_cost)
                        ) < 0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        color:
                          helper.calculatePercentage2(
                            Number(barData?.sum),
                            Number(barData?.sum_cost)
                          ) > 0
                            ? "#27CE88"
                            : helper.calculatePercentage2(
                                Number(barData?.sum),
                                Number(barData?.sum_cost)
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {totalTarget && totalIncome
                        ? helper.formatNumber(
                            helper.calculatePercentage2(
                              Number(barData?.sum),
                              Number(barData?.sum_cost)
                            )
                          ) + " %"
                        : "-"}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  รายได้ :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(barData?.sum.toFixed(2))} บาท
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  ต้นทุน :
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(barData?.sum_cost.toFixed(2))} บาท
                </div>
                <div
                  style={{
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  กำไร :{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {helper.formatNumber(
                    (Number(barData?.sum) - Number(barData?.sum_cost)).toFixed(
                      2
                    )
                  ) + " บาท"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Paper
            sx={{
              bgcolor: "white",
              padding: "15px",
              borderRadius: "10px",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    กำไรต่างจาก
                    <br />
                    {" " + (graph1?.xname ?? "") + " "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {helper.calculatePercentage(
                        graph1?.sum - graph1?.sum_cost,
                        graph2?.sum - graph2?.sum_cost
                      ) > 0 ? (
                        <FaArrowTrendUp color="#27CE88" size={30} />
                      ) : helper.calculatePercentage(
                          graph1?.sum - graph1?.sum_cost,
                          graph2?.sum - graph2?.sum_cost
                        ) < 0 ? (
                        <FaArrowTrendDown color="#FF316F" size={30} />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        color:
                          Number(
                            helper.calculatePercentage(
                              graph1?.sum - graph1?.sum_cost,
                              graph2?.sum - graph2?.sum_cost
                            )
                          ) > 0
                            ? "#27CE88"
                            : Number(
                                helper.calculatePercentage(
                                  graph1?.sum - graph1?.sum_cost,
                                  graph2?.sum - graph2?.sum_cost
                                )
                              ) < 0
                            ? "#FF316F"
                            : "#FFC675",
                      }}
                    >
                      {graph1 && graph2
                        ? helper.addComma(
                            helper.calculatePercentage(
                              graph1?.sum - graph1?.sum_cost,
                              graph2?.sum - graph2?.sum_cost
                            )
                          ) + " %"
                        : "-"}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    paddingTop: "10px",
                    color: "primary.main",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {!(graph2?.sum_cost === 0 && graph1?.sum_cost === 0)
                    ? Number(
                        graph2?.sum -
                          graph2?.sum_cost -
                          (graph1?.sum - graph1?.sum_cost)
                      ) > 0
                      ? "สูงกว่าช่วงก่อนหน้า"
                      : Number(
                          graph2?.sum -
                            graph2?.sum_cost -
                            (graph1?.sum - graph1?.sum_cost)
                        ) < 0
                      ? "ต่ำกว่าช่วงก่อนหน้า"
                      : Number(
                          graph2?.sum -
                            graph2?.sum_cost -
                            (graph1?.sum - graph1?.sum_cost)
                        ) === 0
                      ? "เท่ากับช่วงก่อนหน้า"
                      : "-"
                    : "-"}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {graph1 && graph2
                    ? helper.addComma(
                        graph2?.sum -
                          graph2?.sum_cost -
                          (graph1?.sum - graph1?.sum_cost).toFixed(2)
                      ) + " บาท"
                    : "-"}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FinanceDashboard;
