import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./TradeFinanceBuyer.module.css";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import { Download, Edit, Search, UploadFile } from "@mui/icons-material";
import SelectRowValue from "components/button/SelectRowValue";
import AlertModal from "components/modal/AlertModal";
import SubMenu from "components/layout/SubMenu.js";
import TableCustom from "components/table/TableCustom";
import moment from "moment";
import LoadingBackground from "components/loading/LoadingBackground";
import {
  editBuyerTransaction,
  getBuyerEditLog,
  getBuyerTransaction,
} from "utils/api/trade-finance/buyer";
import helper from "utils/helper";
import { useCookies } from "react-cookie";
import FieldDatePicker from "components/field/FieldDatePicker";
import { Controller, useForm } from "react-hook-form";
import ButtonCustom from "components/ButtonCustom";
import CloseIcon from "@mui/icons-material/Close";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import currency from "currency.js";
import FieldUpload from "components/field/FieldUpload";
import { getAllBank } from "utils/api/masterData/service";
import { subMenuTradeFinance } from "utils/submenuoptions";
import FieldTextArea from "components/field/FieldTextArea";
import LightTooltip from "components/tooltip/LightTooltip";
import AuthContext from "components/context/AuthContext";
import { useNavigate } from "react-router-dom";

const EditTransactionBuyer = () => {
  const { adminAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [hasNoRole, setHasNoRole] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState({
    open: false,
    data: null,
  });
  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    search_by: "",
    search_val: "",
    page: 1,
    per_page: 100,
    total: 0,
  });
  const [filterLog, setFilterLog] = useState({
    order_by: "actual_date",
    order_by_value: -1,
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    search_by: "",
    search_val: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm();

  const watchDecimal = watch("decimal");
  const watchAmountPerTrip = watch("amount_per_trip");
  const watchVatPerTrip = watch("vat_per_trip");
  const watchTotalPerTrip = watch("total_per_trip");

  const breadcrumbs = [
    {
      label: "แก้ไขการเงินผู้ซื้อ",
      path: null,
    },
  ];

  const filterForEdit = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center" }} fontWeight={"bold"}>
          ค้นหาเอกสารที่ต้องการแก้ไข
        </Typography>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_topic"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={1}
            lg={1}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterTransaction = { ...filter, page: 1 };
                filterTransaction.search_by !== "" &&
                  filterTransaction.search_val !== "" &&
                  getAllTransaction(filterTransaction);
              }}
            >
              <Search
                sx={{
                  color:
                    filter.search_by !== "" && filter.search_val !== ""
                      ? "secondary.main"
                      : "grey.main",
                  fontSize: 35,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  const filterLogBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center" }} fontWeight={"bold"}>
          ค้นหาประวัติการแก้ไขเอกสาร
        </Typography>
        <Grid container rowSpacing={2} justifyContent="center">
          {/* <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid> */}
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_topic"}
              value={filterLog.search_by}
              onChange={(val) => setFilterLog({ ...filterLog, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterLog.search_val ? filterLog.search_val : ""}
              onChange={(val) =>
                setFilterLog({ ...filterLog, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={1}
            lg={1}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterTransactionLog = { ...filterLog, page: 1 };
                filterTransactionLog.search_by !== "" &&
                  filterTransactionLog.search_val !== "" &&
                  getAllTransactionLog(filterTransactionLog);
              }}
            >
              <Search
                sx={{
                  color:
                    filterLog.search_by !== "" && filterLog.search_val !== ""
                      ? "secondary.main"
                      : "grey.main",
                  fontSize: 35,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getBank();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      if (
        adminAuth?.admin_role_type === 0 ||
        adminAuth?.admin_role_type === 4
      ) {
        getAllTransactionLog(filterLog);
      } else {
        setHasNoRole(true);
      }
    }
  }, [filterLog.page, filterLog.per_page, adminAuth]);

  useEffect(() => {
    console.log("openModalUpdate", openModalUpdate.data);
    if (openModalUpdate.data) {
      Object.keys(openModalUpdate.data).forEach((fieldName) => {
        if (
          fieldName === "amount_per_trip" ||
          fieldName === "vat_per_trip" ||
          fieldName === "total_per_trip" ||
          fieldName === "amount_of_transfer_actual"
        ) {
          const data = currency(openModalUpdate.data[fieldName], {
            precision: openModalUpdate.data["decimal"],
          });
          const formatting = helper.formatNumeric(
            data,
            "display",
            openModalUpdate.data["decimal"]
          );
          setValue(fieldName, formatting);
        } else {
          setValue(fieldName, openModalUpdate.data[fieldName]);
        }
      });
      setValue("detail", "");
    }
  }, [openModalUpdate.data]);

  useEffect(() => {
    const vat = currency(watchAmountPerTrip, {
      precision: watchDecimal,
    }).multiply(0.07).value;
    const total = currency(watchAmountPerTrip, { precision: watchDecimal }).add(
      vat
    ).value;

    const vat_per_trip = helper.formatNumeric(vat, "display", watchDecimal);
    const total_per_trip = helper.formatNumeric(total, "display", watchDecimal);
    setValue("vat_per_trip", vat_per_trip);
    setValue("total_per_trip", total_per_trip);
  }, [watchAmountPerTrip]);

  useEffect(() => {
    const total = currency(watchAmountPerTrip, { precision: watchDecimal }).add(
      watchVatPerTrip
    ).value;
    const total_per_trip = helper.formatNumeric(total, "display", watchDecimal);
    setValue("total_per_trip", total_per_trip);
  }, [watchVatPerTrip]);

  useEffect(() => {
    const amount_per_trip = helper.formatNumeric(
      watchAmountPerTrip,
      "display",
      watchDecimal
    );
    const vat_per_trip = helper.formatNumeric(
      watchVatPerTrip,
      "display",
      watchDecimal
    );
    const total_per_trip = helper.formatNumeric(
      watchTotalPerTrip,
      "display",
      watchDecimal
    );

    setValue("amount_per_trip", amount_per_trip);
    setValue("vat_per_trip", vat_per_trip);
    setValue("total_per_trip", total_per_trip);
  }, [watchDecimal]);

  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBankOptions(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderFormUpdateInvoice = () => {
    return (
      <Box>
        <form
          className="form-updatestatus"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitInvoice)}
        >
          <Controller
            control={control}
            name="actual_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันเวลาที่ต้องการแจ้งหนี้ *"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.actual_date}
                helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="payment_due_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันที่กำหนดชำระเงิน*"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.payment_due_date}
                helperText={errors.payment_due_date ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="trade_transport_ids"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="รวมจำนวนคำสั่ง"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                readOnly
                error={errors.trade_transport_ids}
              />
            )}
          />
          <Controller
            control={control}
            name="decimal"
            render={({ field: { onChange, value } }) => (
              <FieldSelect
                className="w-100"
                name="จำนวนทศนิยม"
                options={[
                  { value: 2, name: "2" },
                  { value: 4, name: "4" },
                  { value: 6, name: "6" },
                ]}
                value={value}
                onChange={onChange}
                error={errors.decimal}
              />
            )}
          />
          <Controller
            control={control}
            name="amount_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                onChange={onChange}
                precision={watchDecimal}
                // readOnly
                error={errors.amount_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="vat_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมภาษีมูลค่าเพิ่ม"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                onChange={onChange}
                type="float"
                precision={watchDecimal}
                error={errors.vat_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="total_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อสุทธิ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                precision={watchDecimal}
                readOnly
                error={errors.total_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="unit_price"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="Unit Price (free key)"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="credit_remark"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="หมายเหตุ"
                value={value}
                onChange={onChange}
                multiline={true}
                maxRows={4}
              />
            )}
          />
          <Controller
            control={control}
            name="detail"
            rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
            render={({ field: { onChange, value } }) => (
              <FieldTextArea
                className="w-100"
                placeholder="เหตุผลในการแก้ไข"
                value={value}
                onChange={onChange}
                error={errors.detail}
                errorMessage={errors.detail?.message}
              />
            )}
          />
          <div style={{ paddingTop: "20px", display: "flex" }}>
            <div style={{ flex: 0.5 }}>
              <ButtonCustom
                name={"ยกเลิก"}
                onClick={() => setOpenModalUpdate({ open: false, data: null })}
                type={"cancel"}
                fullWidth={true}
              />
            </div>

            <div style={{ flex: 0.5 }}>
              <ButtonCustom name={"บันทึก"} type="submit" fullWidth={true} />
            </div>
          </div>
        </form>
      </Box>
    );
  };

  const renderFormUpdateBilling = () => {
    return (
      <Box>
        <form
          className="form-updatestatus"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitBilling)}
        >
          <Controller
            control={control}
            name="actual_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันเวลาที่ต้องการวางบิล *"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.actual_date}
                helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="payment_due_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันที่กำหนดชำระเงิน*"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.payment_due_date}
                helperText={errors.payment_due_date ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="trade_transport_ids"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="รวมจำนวนคำสั่ง"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                readOnly
                error={errors.trade_transport_ids}
              />
            )}
          />
          <Controller
            control={control}
            name="decimal"
            render={({ field: { onChange, value } }) => (
              <FieldSelect
                className="w-100"
                name="จำนวนทศนิยม"
                options={[
                  { value: 2, name: "2" },
                  { value: 4, name: "4" },
                  { value: 6, name: "6" },
                ]}
                value={value}
                onChange={onChange}
                error={errors.decimal}
              />
            )}
          />
          <Controller
            control={control}
            name="amount_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                onChange={onChange}
                precision={watchDecimal}
                // readOnly
                error={errors.amount_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="vat_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมภาษีมูลค่าเพิ่ม"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                onChange={onChange}
                type="float"
                precision={watchDecimal}
                error={errors.vat_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="total_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อสุทธิ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                precision={watchDecimal}
                readOnly
                error={errors.total_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="detail"
            rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
            render={({ field: { onChange, value } }) => (
              <FieldTextArea
                className="w-100"
                placeholder="เหตุผลในการแก้ไข"
                value={value}
                onChange={onChange}
                error={errors.detail}
                errorMessage={errors.detail?.message}
              />
            )}
          />
          <div style={{ paddingTop: "20px", display: "flex" }}>
            <div style={{ flex: 0.5 }}>
              <ButtonCustom
                name={"ยกเลิก"}
                onClick={() => setOpenModalUpdate({ open: false, data: null })}
                type={"cancel"}
                fullWidth={true}
              />
            </div>

            <div style={{ flex: 0.5 }}>
              <ButtonCustom name={"บันทึก"} type="submit" fullWidth={true} />
            </div>
          </div>
        </form>
      </Box>
    );
  };

  const renderFormUpdateReceipt = () => {
    return (
      <Box>
        <form
          className="form-updatestatus"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitReceipt)}
        >
          <Controller
            control={control}
            name="actual_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันเวลาที่ต้องการชำระเงิน *"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.actual_date}
                helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
                minDate={moment(value).clone().startOf("month")}
                maxDate={moment(value).clone().endOf("month")}
              />
            )}
          />
          <p style={{ marginTop: 0 }}>
            ไม่สามารถแก้ไขวันที่ต้องการชำระเงินเป็นเดือนที่ต่างจากวันที่ชำระเงินในครั้งแรกได้เนื่องจากจะทำให้เลขที่ใบเสร็จเปลี่ยน
            ถ้าต้องการแก้ไขโปรดยกเลิกใบเสร็จและทำตามรับชำระเงินใหม่อีกครั้ง
          </p>
          <Controller
            control={control}
            name="payment_due_date"
            render={({ field: { onChange, value } }) => (
              <FieldDatePicker
                className="w-100"
                label="วันที่กำหนดชำระเงิน*"
                subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
                value={value}
                onChange={onChange}
                showTime
                error={errors.payment_due_date}
                helperText={errors.payment_due_date ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="trade_transport_ids"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="รวมจำนวนคำสั่ง"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                readOnly
                error={errors.trade_transport_ids}
              />
            )}
          />
          <Controller
            control={control}
            name="decimal"
            render={({ field: { onChange, value } }) => (
              <FieldSelect
                className="w-100"
                name="จำนวนทศนิยม"
                options={[
                  { value: 2, name: "2" },
                  { value: 4, name: "4" },
                  { value: 6, name: "6" },
                ]}
                value={value}
                onChange={onChange}
                error={errors.decimal}
              />
            )}
          />
          <Controller
            control={control}
            name="amount_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                onChange={onChange}
                precision={watchDecimal}
                error={errors.amount_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="vat_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมภาษีมูลค่าเพิ่ม"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                onChange={onChange}
                type="float"
                precision={watchDecimal}
                error={errors.vat_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="total_per_trip"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="รวมราคาซื้อสุทธิ"
                subLabel="(ผลรวมจากการเลือก)"
                value={value}
                type="float"
                precision={watchDecimal}
                readOnly
                error={errors.total_per_trip}
              />
            )}
          />
          <Controller
            control={control}
            name="unit_price"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="Unit Price*"
                value={value}
                onChange={onChange}
                error={errors.unit_price}
              />
            )}
          />
          <Controller
            control={control}
            name="amount_of_transfer_actual"
            render={({ field: { onChange, value } }) => (
              <FieldNumber
                className="w-100"
                label="จำนวนเงินที่ชำระจริง*"
                subLab=" (ค่าเริ่มต้น: รวมราคาลูกค้าสุทธิ)"
                value={value}
                onChange={onChange}
                type="float"
                error={errors.amount_of_transfer_actual}
                helperText={
                  errors.amount_of_transfer_actual ? "จำเป็นต้องระบุ" : ""
                }
                precision={watchDecimal}
              />
            )}
          />
          <Controller
            control={control}
            name="bank_id"
            render={({ field: { onChange, value } }) => (
              <FieldSelect
                className="w-100"
                name={`ธนาคารที่ชำระ*`}
                options={bankOptions}
                value={value}
                onChange={onChange}
                error={errors.bank_id}
                errorText={errors.bank_id ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="ref"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="ref*"
                value={value}
                onChange={onChange}
                error={errors.ref}
                helperText={errors.ref ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, value } }) => (
              <FieldUpload
                name={"สลิปโอนเงิน*"}
                icon={<UploadFile />}
                value={value}
                accept="image/png, image/jpeg, application/pdf"
                onChange={onChange}
                error={errors.file}
                helperText={errors.file ? "จำเป็นต้องระบุ" : ""}
              />
            )}
          />
          <Controller
            control={control}
            name="credit_remark"
            render={({ field: { onChange, value } }) => (
              <FieldInput
                className="w-100"
                label="หมายเหตุ"
                value={value}
                onChange={onChange}
                multiline={true}
                maxRows={4}
              />
            )}
          />
          <Controller
            control={control}
            name="detail"
            rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
            render={({ field: { onChange, value } }) => (
              <FieldTextArea
                className="w-100"
                placeholder="เหตุผลในการแก้ไข"
                value={value}
                onChange={onChange}
                error={errors.detail}
                errorMessage={errors.detail?.message}
              />
            )}
          />

          <div style={{ paddingTop: "20px", display: "flex" }}>
            <div style={{ flex: 0.5 }}>
              <ButtonCustom
                name={"ยกเลิก"}
                onClick={() => setOpenModalUpdate({ open: false, data: null })}
                type={"cancel"}
                fullWidth={true}
              />
            </div>

            <div style={{ flex: 0.5 }}>
              <ButtonCustom name={"บันทึก"} type="submit" fullWidth={true} />
            </div>
          </div>
        </form>
      </Box>
    );
  };

  const getAllTransaction = async (_filter) => {
    try {
      setLoading(true);
      const res = await getBuyerTransaction(_filter);
      if (res.data.code === 1000) {
        const result = res.data.data;
        if (result.length > 0) {
          setOpenModalUpdate({
            open: true,
            data: { ...result[0], trade_transport_ids: res.data.total },
          });
        } else {
          setErrorAlert({
            open: true,
            desc:
              filter.search_by === "invoice_code"
                ? "ไม่พบเลขที่ใบแจ้งหนี้"
                : filter.search_by === "bill_code"
                ? "ไม่พบเลขที่ใบวางบิล"
                : filter.search_by === "receipt_code"
                ? "ไม่พบเลขที่ใบชำระเงิน"
                : "",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.data.code === 4106) {
        setErrorAlert({
          open: true,
          desc: error.response.data.description,
        });
      } else if (error.response.data.code === "4105") {
        removeAccessToken("access_token");
        window.location.reload();
      }
    }
  };

  const getAllTransactionLog = async (_filter) => {
    try {
      setLoading(true);
      const res = await getBuyerEditLog(_filter);
      if (res.data.code === 1000) {
        const result = res.data.data;
        setDataSource(result);
        setFilterLog({ ...filterLog, total: res.data.total });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.data.code === 4106) {
        setErrorAlert({
          open: true,
          desc: error.response.data.description,
        });
      } else if (error.response.data.code === "4105") {
        removeAccessToken("access_token");
        window.location.reload();
      }
    }
  };

  const onSubmitInvoice = async (data) => {
    setLoading(true);
    console.log(data);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append("payment_due_date", data.payment_due_date);
    formData.append(
      "amount_per_trip",
      currency(data.amount_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "vat_per_trip",
      currency(data.vat_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "total_per_trip",
      currency(data.total_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append("decimal", data.decimal);
    formData.append(
      "credit_remark",
      data.credit_remark === "" ? null : data.credit_remark
    );
    formData.append("unit_price", data.unit_price);
    formData.append("detail", data.detail);

    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    await editBuyerTransaction("invoice", data.invoice_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const onSubmitBilling = async (data) => {
    setLoading(true);
    console.log(data);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append("payment_due_date", data.payment_due_date);
    formData.append(
      "amount_per_trip",
      currency(data.amount_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "vat_per_trip",
      currency(data.vat_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "total_per_trip",
      currency(data.total_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append("decimal", data.decimal);
    formData.append("detail", data.detail);

    await editBuyerTransaction("bill", data.bill_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const onSubmitReceipt = async (data) => {
    setLoading(true);
    console.log(data);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append("payment_due_date", data.payment_due_date);
    formData.append(
      "amount_per_trip",
      currency(data.amount_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "vat_per_trip",
      currency(data.vat_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "total_per_trip",
      currency(data.total_per_trip, {
        precision: data.decimal,
      }).value
    );
    formData.append(
      "amount_of_transfer_actual",
      currency(data.amount_of_transfer_actual, {
        precision: data.decimal,
      }).value
    );
    formData.append("decimal", data.decimal);
    formData.append(
      "credit_remark",
      data.credit_remark === "" ? null : data.credit_remark
    );
    formData.append("unit_price", data.unit_price);
    formData.append("bank_id", data.bank_id);
    formData.append("ref", data.ref);
    formData.append("detail", data.detail);

    if (data.file !== openModalUpdate.data.file) {
      formData.append("file", data.file);
    }
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    await editBuyerTransaction("receipt", data.receipt_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const columns = [
    {
      id: "actual_date",
      label: "วันที่ทำรายการ",
      align: "center",
      //   width: "13%",
      format: (value) =>
        helper.ToConvertDateTimeForTable(value.actual_date, false),
    },
    {
      id: "document",
      label: "เลขที่เอกสาร",
      align: "center",
      //   width: "13%",
      format: (value) =>
        value.buyer_payment_status_id === 3 ||
        value.buyer_payment_status_id === 4
          ? value.invoice_code
          : value.buyer_payment_status_id === 5 ||
            value.buyer_payment_status_id === 6
          ? value.bill_code
          : value.buyer_payment_status_id === 7 ||
            value.buyer_payment_status_id === 8
          ? value.receipt_code
          : "",
    },
    {
      id: "buyer_payment_status_name",
      label: "สถานะเอกสาร",
      align: "center",
    },
    {
      id: "customer_name",
      label: "ผู้ซื้อ",
      align: "center",
    },
    {
      id: "project_name",
      label: "ชื่อโครงการ",
      align: "center",
    },
    {
      id: "amount_per_trip",
      label: "ราคาต่อหน่วย",
      align: "center",
      format: (value) => helper.addComma(value.amount_per_trip),
    },
    {
      id: "total_per_trip",
      label: "ราคาสุทธิ",
      align: "center",
      format: (value) => helper.addComma(value.total_per_trip),
    },
    {
      id: "vat_per_trip",
      label: "ภาษีมูลค่าเพิ่ม",
      align: "center",
      format: (value) => helper.addComma(value.vat_per_trip),
    },
    {
      id: "amount_of_transfer_actual",
      label: "จำนวนเงินที่ชำระจริง",
      align: "center",
      format: (value) => helper.addComma(value.amount_of_transfer_actual),
    },
    {
      id: "detail",
      label: "เหตุผลในการแก้ไข",
      align: "center",
      format: (value) => (
        <LightTooltip title={value.detail}>
          <div className={styles.ellipsisDetail}>{value.detail}</div>
        </LightTooltip>
      ),
    },

    {
      id: "admin",
      label: "สร้างโดย",
      align: "center",
      format: (value) => value.admin_firstname + " " + value.admin_lastname,
    },
  ];
  return (
    <div>
      {loading && <LoadingBackground />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          getAllTransactionLog({ ...filterLog, page: 1 });
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() =>
          setErrorAlert({
            open: false,
            desc: "",
          })
        }
      />
      <AlertModal
        open={hasNoRole}
        title="เกิดข้อผิดพลาด"
        description={"ไม่มีสิทธ์ในการเข้าถึง"}
        onClose={() => {
          navigate("/");
          setHasNoRole(false);
        }}
      />
      <Container>
        <SubMenu
          menus={subMenuTradeFinance.map((menu) => {
            return {
              label: menu?.label,
              path: menu?.path,
              active: menu?.label === `แก้ไขการเงินผู้ซื้อ` ? true : false,
              header: menu?.header,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={"/"}
        />
        <div style={{ paddingTop: "20px" }}></div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {filterForEdit()}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {filterLogBar()}
          </Grid>
        </Grid>
        <div>
          <TableCustom
            columns={columns}
            dataSource={dataSource}
            pagination={filterLog}
            setPagination={(val) => {
              setFilterLog({
                ...filterLog,
                ...val,
              });
            }}
            hrefDisabled
          />
        </div>
      </Container>
      {
        <Modal
          open={openModalUpdate?.open}
          onClose={(event, reason) =>
            reason !== "backdropClick"
              ? setOpenModalUpdate({ open: false, data: null })
              : ""
          }
          sx={{ zIndex: 1000 }}
        >
          <div className={styles.modalStyle}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon
                sx={{ color: "grey.main" }}
                onClick={() => setOpenModalUpdate({ open: false, data: null })}
              />
            </div>
            <div style={{ fontSize: 20, fontWeight: 400, textAlign: "center" }}>
              {openModalUpdate?.data?.buyer_payment_status_id === 3
                ? "แก้ไขข้อมูลใบแจ้งหนี้"
                : openModalUpdate?.data?.buyer_payment_status_id === 5
                ? "แก้ไขข้อมูลใบวางบิล"
                : "แก้ไขข้อมูลใบเสร็จ"}
            </div>
            <Divider />
            <div style={{ paddingTop: 23 }}>
              {openModalUpdate?.data?.buyer_payment_status_id === 3
                ? renderFormUpdateInvoice()
                : openModalUpdate?.data?.buyer_payment_status_id === 5
                ? renderFormUpdateBilling()
                : renderFormUpdateReceipt()}
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default EditTransactionBuyer;
