import { CleaningServices, Search } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Container,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import GraphKPI from "components/dashboard/GraphKPI";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import Line from "components/layout/line";
import SubMenu from "components/layout/SubMenu.js";
import ModalWork from "components/work/modalWork";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getDashboardKPIInbound } from "utils/api/dashboard/newDashboard";
import { getAllUser as getAllUserAPI } from "utils/api/user/user";
import helper from "utils/helper";
const KPI = () => {
  const [loading, setLoading] = useState(false);
  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
  const [showGetProfile, setShowGetProfile] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [totalProfile, setTotalProfile] = useState(0);
  const [optionCalculate, setOptionCalculate] = useState([]);
  const [optionCalculate2, setOptionCalculate2] = useState([]);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [dataGraphInbound, setDataGraphInbound] = useState(null);
  const [filterDashboard, setFilterDashboard] = useState({
    type_data_set: "day",
    start_date: firstDayOfMonth,
    end_date: yesterday,
    on_dashboard: true,
  });
  const [filterProfile, setFilterProfile] = useState({
    sort_by: "updated_at",
    search_by: "company_name",
    search_val: "",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 3,
    total: 0,
    is_main: [true],
    profile_type: [],
    role: ["shipper"],
    active: ["true"],
  });

  const subDashboard = [
    {
      label: "งานขนส่ง",
      path: "/dashboard/work",
    },
    {
      label: "KPI",
      path: "/dashboard/KPI",
    },
  ];
  const breadcrumbs = [
    {
      label: "KPI",
      path: null,
    },
  ];

  useEffect(() => {
    getDataGraphInbound();
  }, [filterDashboard]);

  useEffect(() => {
    if (profileList.length > 0) {
      getProfile();
    }
  }, [filterProfile.page]);

  useEffect(() => {
    handleFirstDropdownChange(
      optionCalculate[optionCalculate.length - 2]?.value
    );
    setGraph2(optionCalculate[optionCalculate.length - 1]?.value);
  }, [optionCalculate]);

  const getDataGraphInbound = async (fil = filterDashboard) => {
    let _filter = {};
    if (fil.on_dashboard !== undefined && fil.on_dashboard === "all") {
      const { on_dashboard, ...rest } = fil;
      _filter = rest;
    } else {
      _filter = fil;
    }
    await getDashboardKPIInbound(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
          label:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
        };
      });
      setDataGraphInbound(mapGroups);
    });
  };
  const handleFirstDropdownChange = (value) => {
    setGraph1(value);
    setGraph2(null);
    const selectedIndex = optionCalculate?.findIndex(
      (option) => option?.name === value?.name
    );
    console.log("selectedIndex", selectedIndex);
    const newFilteredOptions = optionCalculate.slice(selectedIndex + 1);
    console.log("newFilteredOptions", newFilteredOptions);
    setOptionCalculate2(newFilteredOptions);
  };

  const getProfile = async (filter = filterProfile) => {
    const body = {
      shipper: {},
      carrier: {},
      driver: {},
    };
    await getAllUserAPI(filter, body)
      .then((response) => {
        console.log(response.data.data);
        setProfileList(response.data.data);
        setTotalProfile(response.data.total);
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
        } else if (error.response.data.code === "4105") {
          // removeAccessToken("access_token");
          // window.location.reload();
        }
      });
  };

  const filterBar = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"แสดงตาม"}
              options={[
                { value: "year", name: "รายปี" },
                { value: "month", name: "รายเดือน" },
                { value: "week", name: "รายสัปดาห์" },
                { value: "day", name: "รายวัน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"day"}
              value={filterDashboard.type_data_set}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  type_data_set: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterDashboard.start_date ?? firstDayOfMonth,
                end_date: filterDashboard.end_date ?? yesterday,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"ไม่รวมงานโปรเจ็ค"}
              options={[
                { value: true, name: "ใช่" },
                { value: false, name: "ไม่ใช่" },
                { value: "all", name: "ทั้งหมด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={true}
              value={filterDashboard?.on_dashboard}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  on_dashboard: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <div style={{ flex: 0.7, paddingRight: 20 }}>
                <FieldInput
                  value={filterDashboard.profile_name ?? ""}
                  label={"ชื่อโปรไฟล์"}
                  style={{ width: "-webkit-fill-available" }}
                  readOnly
                  closePaddingBottom
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 0.3,
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "secondary.main",
                  }}
                  onClick={() => {
                    setShowGetProfile(true);
                  }}
                >
                  <Search sx={{ color: "white", fontSize: 30 }} />
                </Avatar>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "white",
                  }}
                  onClick={() => {
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: null,
                      profile_name: null,
                    });
                  }}
                >
                  <CleaningServices
                    sx={{ color: "secondary.main", fontSize: 30 }}
                  />
                </Avatar>
                {/* <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px" }}
                    color="secondary"
                    onClick={() => {
                      // getProject();
                      setShowGetProfile(true);
                    }}
                  >
                    ค้นหา
                  </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่1(ก่อน)"}
              options={optionCalculate}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph1}
              onChange={handleFirstDropdownChange}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่2(หลัง)"}
              options={optionCalculate2}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph2}
              onChange={(value) => setGraph2(value)}
              closePaddingBottom={true}
              readOnly={graph1 ? false : true}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Container>
      <SubMenu
        menus={subDashboard.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `KPI` ? true : false,
          };
        })}
        breadcrumbs={breadcrumbs}
      />
      {filterBar()}
      <Grid container sx={{ marginTop: "10px" }}>
        <Grid item xs={4}>
          <GraphKPI
            data={dataGraphInbound}
            title="KPI Inbound"
            maxValueY={110}
            onClickBar={() => {}}
            dataKeyX="xname"
            dataKeyBar="percentage"
            height={250}
            width={730}
            barColor="#00C49F"
            lineColor={`rgba(0,136,254,0.75)`}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <ModalWork
        close={() => {
          setShowGetProfile(false);
          setProfileList([]);
          setTotalProfile(0);
          setFilterProfile({
            ...filterProfile,
            search_val: "",
            page: 1,
            per_page: 3,
            total: 0,
          });
        }}
        open={showGetProfile}
        minHeight
        enableClose
      >
        <div style={{ padding: 20 }}>
          <div style={{ textAlign: "center" }}>โปรไฟล์ลูกค้า</div>
          <Line />
          <TextField
            id="search_val"
            variant="standard"
            style={{
              paddingTop: "16px",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={filterProfile.search_val}
            onChange={(val) => {
              setFilterProfile({
                ...filterProfile,
                search_val: val.target.value,
              });
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            color="secondary"
            onClick={() => {
              getProfile();
            }}
          >
            ค้นหา
          </Button>
          <div style={{ marginTop: 10 }}>
            {profileList.map((ele, index) => {
              return (
                <div
                  key={"profile" + index}
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    color:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    console.log(ele);
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: ele.user_profile.profile.id,
                      profile_name: ele.user_profile.profile.company_name,
                    });
                  }}
                >
                  <div>{ele.user_code}</div>
                  <div style={{ fontSize: 20, fontWeight: 500 }}>
                    {ele.user_profile.profile.company_name}
                  </div>
                  <div>
                    {"สร้างบัญชีเมื่อ: " +
                      helper.momentDateTime(ele.created_at)}
                  </div>
                </div>
              );
            })}
            <Pagination
              pagination={{
                page: filterProfile.page,
                per_page: filterProfile.per_page,
                total: totalProfile,
              }}
              setPagination={(val) => {
                console.log(val);
                setFilterProfile({
                  ...filterProfile,
                  per_page: val.per_page,
                  page: val.page,
                });
              }}
            />
            {filterDashboard.profile_id && profileList.length > 0 ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                color="secondary"
                onClick={async () => {
                  setShowGetProfile(false);
                  setProfileList([]);
                  setTotalProfile(0);
                  setFilterProfile({
                    ...filterProfile,
                    search_val: "",
                    page: 1,
                    per_page: 3,
                    total: 0,
                  });
                }}
              >
                ยืนยัน
              </Button>
            ) : null}
          </div>
        </div>
      </ModalWork>
    </Container>
  );
};

export default KPI;
