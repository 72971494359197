import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import helper from "utils/helper";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalUpdateStatus from "./modalUpdateStatus";
import ModalWork from "./modalWork";
import FieldDatePicker from "components/field/FieldDatePicker";
import InputNormal from "components/input/inputNormal";
import InputSelect from "components/input/inputSelect";
import Line from "components/layout/line";
import { Button, Skeleton } from "@mui/material";
import Master from "utils/master";
import InputImageShow from "components/input/InputImageShow";
import InputUpload from "components/input/inputUpload";
import API from "utils/api/work/work";
import { getAdminById } from "utils/api/admin/admin";
import { getUserById } from "utils/api/user/user";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
let api = API.create();
const WorkStatus = (props) => {
  const { data } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [createableName, setCreateableName] = useState("");
  const [statusDetail, setStatusDetail] = useState(null);
  // const [trackingDetail, setTrackingDetail] = useState({
  //   init: { active: false, date: null },
  //   delivery: { active: false, date: null },
  //   send: { active: false, date: null },
  //   done: { active: false, date: null },
  // });
  const [trackingDetail, setTrackingDetail] = useState([]);
  console.log("status,", data);
  const showStatus = (status) => {
    console.log(status);
    setShowModal(true);
    setStatusDetail(status);
  };

  console.log("statusDetail", statusDetail);
  useEffect(() => {
    if (statusDetail?.work_status === 13) {
      console.log("เอกสารนำกลับ");
      getTracking();
    }
    getCreateableName();
  }, [statusDetail]);

  const getTracking = async () => {
    if (
      statusDetail?.workTrackingDetail?.tracking_code &&
      statusDetail?.workTrackingDetail?.tracking_code !== ""
    ) {
      setLoadingDetail(true);
      await api
        .getTrackingFromCode({
          logistics_provider_type:
            statusDetail?.workTrackingDetail?.logistics_provider_type,
          tracking_code: statusDetail?.workTrackingDetail?.tracking_code,
        })
        .then((response) => {
          console.log("response", response);
          // let sta = { ...trackingDetail };
          // if (response.data?.results && response.data?.results.length > 0) {
          //   response.data?.results.forEach((e) => {
          //     console.log(e);
          //     if (e.track_status.includes("รับฝาก")) {
          //       sta.init.active = true;
          //       sta.init.date = e.checkpoint_date;
          //       sta.init.color = "#E57716";
          //       return;
          //     }
          //     if (e.track_status.includes("การขนส่ง")) {
          //       sta.init.active = true;
          //       sta.init.color = "#E57716";
          //       sta.delivery.active = true;
          //       sta.delivery.date = e.checkpoint_date;
          //       sta.delivery.color = "#49C5B6";
          //       return;
          //     }
          //     if (e.track_status.includes("การนำจ่าย")) {
          //       //   sta.delivery = true;
          //       sta.init.active = true;
          //       sta.init.color = "#E57716";
          //       sta.delivery.active = true;
          //       sta.delivery.color = "#49C5B6";
          //       sta.send.active = true;
          //       sta.send.date = e.checkpoint_date;
          //       sta.send.color = "#0D19A3";

          //       return;
          //     }
          //     if (e.track_status.includes("สำเร็จ")) {
          //       //   sta.delivery = true;
          //       //   sta.send = true;
          //       sta.init.active = true;
          //       sta.init.color = "#E57716";
          //       sta.delivery.active = true;
          //       sta.delivery.color = "#49C5B6";
          //       sta.send.active = true;
          //       sta.send.color = "#0D19A3";
          //       sta.done.active = true;
          //       sta.done.date = e.checkpoint_date;
          //       sta.done.color = "#01C100";

          //       return;
          //     }
          //   });
          // }

          // console.log(sta);
          if (response.data?.results && response.data?.results.length > 0) {
            setTrackingDetail(response.data?.results.reverse());
          }

          setLoadingDetail(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingDetail(false);
        });
    }
  };

  const getCreateableName = async () => {
    if (statusDetail?.createable_by_type === 1) {
      await getAdminById(statusDetail?.createable_by_id)
        .then((res) => {
          if (res.data.code === 1000) {
            setCreateableName(
              res.data.data.firstname + " " + res.data.data.lastname
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (statusDetail?.createable_by_type === 0) {
      await getUserById(statusDetail?.createable_by_id)
        .then((res) => {
          if (res.data.code === 1000) {
            setCreateableName(
              res.data.data.firstname + " " + res.data.data.lastname
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const detail = () => {
    if (statusDetail) {
      switch (statusDetail?.work_status) {
        case 3:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label="วันเวลาที่รับงาน*"
                  disable
                />
              </div>
              <div style={{ marginTop: 0 }}>
                <InputNormal
                  value={
                    statusDetail.workTrackingDetail.carrier.user_info.user_code
                  }
                  label="รหัสผู้ใช้งาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.carrier.company_name}
                  label="ชื่อโปรไฟล์*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail.workTrackingDetail.carrier.user_info.phone
                  }
                  label="เบอร์โทรศัพท์*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
            </div>
          );
        case 4:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label="วันเวลาที่กำหนดรถและคนขับ*"
                  disable
                />
              </div>
              <div>
                <InputSelect
                  value={
                    statusDetail.workTrackingDetail.is_owner ? "ใช่" : "ไม่ใช่"
                  }
                  options={[
                    { item: "ใช่", value: "ใช่" },
                    { item: "ไม่ใช่", value: "ไม่ใช่" },
                  ]}
                  label="ใช้รถตัวเอง*"
                  disable
                />
              </div>
              {!statusDetail.workTrackingDetail.is_owner ? (
                <div>
                  <InputSelect
                    value={
                      statusDetail.workTrackingDetail.carrier_partner
                        ?.company_name
                    }
                    label="ชื่อโปรไฟล์*"
                    options={[
                      {
                        item: statusDetail.workTrackingDetail.carrier_partner
                          ?.company_name,
                        value:
                          statusDetail.workTrackingDetail.carrier_partner
                            ?.company_name,
                      },
                    ]}
                    disable
                  />
                </div>
              ) : null}
              <div>
                <InputSelect
                  value={
                    statusDetail.workTrackingDetail.truck.license_plate_head +
                    (statusDetail.workTrackingDetail.truck.license_plate_tail &&
                    statusDetail.workTrackingDetail.truck.license_plate_tail !==
                      ""
                      ? " / " +
                        statusDetail.workTrackingDetail.truck.license_plate_tail
                      : "")
                  }
                  options={[
                    {
                      item:
                        statusDetail.workTrackingDetail.truck
                          .license_plate_head +
                        (statusDetail.workTrackingDetail.truck
                          .license_plate_tail &&
                        statusDetail.workTrackingDetail.truck
                          .license_plate_tail !== ""
                          ? " / " +
                            statusDetail.workTrackingDetail.truck
                              .license_plate_tail
                          : ""),
                      value:
                        statusDetail.workTrackingDetail.truck
                          .license_plate_head +
                        (statusDetail.workTrackingDetail.truck
                          .license_plate_tail &&
                        statusDetail.workTrackingDetail.truck
                          .license_plate_tail !== ""
                          ? " / " +
                            statusDetail.workTrackingDetail.truck
                              .license_plate_tail
                          : ""),
                    },
                  ]}
                  label="ทะเบียนรถ*"
                  disable
                />
              </div>
              <div>
                <InputSelect
                  value={
                    (Master.prefix.filter(
                      (e) =>
                        e.value ===
                        statusDetail.workTrackingDetail.driver.prefix
                    ).length
                      ? Master.prefix.filter(
                          (e) =>
                            e.value ===
                            statusDetail.workTrackingDetail.driver.prefix
                        )[0].item
                      : "") +
                    statusDetail.workTrackingDetail.driver.firstname +
                    " " +
                    statusDetail.workTrackingDetail.driver.lastname
                  }
                  options={[
                    {
                      item:
                        (Master.prefix.filter(
                          (e) =>
                            e.value ===
                            statusDetail.workTrackingDetail.driver.prefix
                        ).length
                          ? Master.prefix.filter(
                              (e) =>
                                e.value ===
                                statusDetail.workTrackingDetail.driver.prefix
                            )[0].item
                          : "") +
                        statusDetail.workTrackingDetail.driver.firstname +
                        " " +
                        statusDetail.workTrackingDetail.driver.lastname,
                      value:
                        (Master.prefix.filter(
                          (e) =>
                            e.value ===
                            statusDetail.workTrackingDetail.driver.prefix
                        ).length
                          ? Master.prefix.filter(
                              (e) =>
                                e.value ===
                                statusDetail.workTrackingDetail.driver.prefix
                            )[0].item
                          : "") +
                        statusDetail.workTrackingDetail.driver.firstname +
                        " " +
                        statusDetail.workTrackingDetail.driver.lastname,
                    },
                  ]}
                  label="คนขับรถ*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.driver.phone}
                  label="เบอร์โทรศัพท์*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(
                    statusDetail.workTrackingDetail.load_date ?? new Date()
                  )}
                  label="ยืนยันเวลารับสินค้า*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        case 8:
        case 10:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label={
                    "วันเวลาที่" +
                    (statusDetail.work_status === 8 ? "รับ" : "ส่ง") +
                    "สินค้า*"
                  }
                  disable
                />
              </div>
              <div style={{ marginTop: 0 }}>
                <InputSelect
                  value={statusDetail.workTrackingDetail.epod_contact_type}
                  label={
                    "ประเภทผู้" +
                    (statusDetail.work_status === 8 ? "ส่ง" : "รับ") +
                    "สินค้า" +
                    (statusDetail.work_status === 8 ? "ต้นทาง*" : "ปลายทาง*")
                  }
                  options={[
                    { item: "เจ้าของสินค้า", value: 0 },
                    { item: "เพื่อนบ้าน", value: 1 },
                    { item: "เพื่อนร่วมงาน", value: 2 },
                    { item: "รปภ.", value: 3 },
                    { item: "แผนกต้อนรับ", value: 4 },
                    { item: "ครอบครัว", value: 5 },
                    { item: "อื่นๆ", value: 6 },
                  ]}
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.epod_contact_name}
                  label={
                    "ชื่อผู้" +
                    (statusDetail.work_status === 8 ? "ส่ง" : "รับ") +
                    "สินค้า" +
                    (statusDetail.work_status === 8 ? "ต้นทาง*" : "ปลายทาง*")
                  }
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.epod_remark}
                  label={"หมายเหตุ"}
                  disable
                />
              </div>
              <div>
                <InputUpload
                  value={helper.addhtpps(
                    statusDetail.workTrackingDetail.epod_signature
                  )}
                  label={"ลายมือชื่อ"}
                  disable
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <InputImageShow
                  value={statusDetail.workTrackingDetail.epodImage?.map((e) => {
                    return { ...e, src: helper.addhtpps(e.image) };
                  })}
                  disable
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 10 }}>
                  <InputNormal
                    value={statusDetail.workTrackingDetail.epod_lat}
                    label={"พิกัด lat"}
                    disable
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <InputNormal
                    value={statusDetail.workTrackingDetail.epod_long}
                    label={"พิกัด long"}
                    disable
                  />
                </div>
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        case 13:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label={"วันเวลาที่กำลังส่งเอกสารนำกลับ"}
                  disable
                />
              </div>
              <div style={{ marginTop: 0 }}>
                <InputSelect
                  value={
                    statusDetail.workTrackingDetail.logistics_provider_type
                  }
                  label={"ผู้ให้บริการจัดส่งเอกสาร"}
                  options={[
                    { item: "ไปรษณีย์ไทย", value: 0 },
                    { item: "Flash Express", value: 1 },
                    { item: "Kerry Express", value: 2 },
                    { item: "J&T Express", value: 3 },
                    { item: "SCG Express", value: 4 },
                  ]}
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.tracking_code}
                  label={"เลขติดตามสถานะ"}
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={statusDetail.workTrackingDetail.remark}
                  label={"หมายเหตุ"}
                  disable
                />
              </div>

              <div style={{ marginTop: 10 }}>
                <InputImageShow
                  value={statusDetail.workTrackingDetail.sendingDocumentImage?.map(
                    (e) => {
                      return { ...e, src: helper.addhtpps(e.image) };
                    }
                  )}
                  disable
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={{ textAlign: "center" }}>สถานะการส่งเอกสาร</div>
                <Line />

                <div
                  style={{
                    overflow: "scroll",
                    maxHeight: 300,
                    marginBottom: 80,
                    marginTop: 20,
                  }}
                >
                  {loadingDetail ? (
                    <Skeleton height={60} />
                  ) : (
                    trackingDetail?.map((ele, index) => {
                      return (
                        <div key={"status" + index} style={{ display: "flex" }}>
                          <div style={{ flex: 1, display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "0px 10px 10px 10px",
                              }}
                            >
                              <TripOriginIcon
                                sx={{
                                  fontSize: 16,
                                  opacity: index === 0 ? 1 : 0.5,
                                }}
                              />
                              <MoreVertIcon
                                sx={{ opacity: index === 0 ? 1 : 0.5 }}
                              />
                            </div>
                            <div style={{ flex: 1, textAlign: "start" }}>
                              <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                                {ele.track_status}{" "}
                                {ele.location ? "[" + ele.location + "]" : ""}
                              </div>
                              <div
                                style={{
                                  opacity: index === 0 ? 1 : 0.5,
                                  fontSize: 12,
                                }}
                              >
                                {ele.tracking_detail ?? ""}
                              </div>
                              <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                                {helper.momentDateTime(
                                  ele.checkpoint_date,
                                  "short",
                                  false
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        case 16:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label={"วันเวลาที่แจ้งปัญหา"}
                  disable
                />
              </div>
              <div style={{ marginTop: 0 }}>
                <InputSelect
                  value={statusDetail.workAbnormal.problem_type}
                  label={"ประเภทปัญหา"}
                  options={[
                    { item: "ที่อยู่ไม่ชัดเจน หรือ ที่อยู่ผิด", value: 0 },
                    {
                      item: "ผู้รับสินค้าติดต่อไม่ได้/ไม่มีผู้รับสาย",
                      value: 1,
                    },
                    { item: "รอคิวขึ้น-ลงสินค้าเกิน 3 ชั่วโมง ", value: 2 },
                    { item: "ผู้รับปลายทางไม่รับของวันหยุด", value: 3 },
                    { item: "ผู้รับปลายทางปฏิเสธการรับของ", value: 4 },
                    { item: "สถานที่จัดส่งปิดทำการ", value: 5 },
                    { item: "บรรจุภัณฑ์เสียหาย", value: 6 },
                    { item: "สินค้าเสียหาย", value: 7 },
                    { item: "สินค้าขาดหาย/สูญหาย", value: 8 },
                    { item: "รถเกิดอุบัติเหตุ", value: 9 },
                    { item: "รถเสียระหว่างการเดินทาง", value: 10 },
                    { item: "อื่นๆ", value: 11 },
                  ]}
                  disable
                />
              </div>
              {statusDetail.workAbnormal.problem_type === 11 ? (
                <div>
                  <InputNormal
                    value={statusDetail.workAbnormal.problem_topic_other}
                    label={"อื่นๆ โปรดระบุ"}
                    disable
                  />
                </div>
              ) : null}
              <div>
                <InputNormal
                  value={statusDetail.workAbnormal.problem_detail}
                  label={"รายละเอียดปัญหา"}
                  disable
                />
              </div>

              <div style={{ marginTop: 10 }}>
                <InputImageShow
                  value={statusDetail.workAbnormal.workAbnormalProblemFile?.map(
                    (e) => {
                      return { ...e, src: helper.addhtpps(e.image) };
                    }
                  )}
                  disable
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 10 }}>
                  <InputNormal
                    value={statusDetail.workAbnormal.abnormal_lat}
                    label={"พิกัด lat"}
                    disable
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <InputNormal
                    value={statusDetail.workAbnormal.abnormal_long}
                    label={"พิกัด long"}
                    disable
                  />
                </div>
              </div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        case 17:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label="วันเวลาที่ยกเลิกงาน*"
                  disable
                />
              </div>
              <div>
                <InputSelect
                  value={statusDetail.workTrackingDetail.cancel_work_type}
                  options={[
                    { item: "ประกาศผิด/แก้ไข/ซ้ำ", value: 0 },
                    { item: "ลูกค้ายกเลิก/สินค้าไม่พร้อม", value: 1 },
                    {
                      item: "พขร.ยกเลิกงาน/รถเสีย/เข้ารับสินค้าไม่ทัน",
                      value: 2,
                    },
                    { item: "อื่น ๆ", value: 3 },
                  ]}
                  label="เหตุผลในการยกเลิกงาน*"
                  disable
                />
              </div>
              {statusDetail.workTrackingDetail.cancel_work_type === 3 ? (
                <div>
                  <InputNormal
                    value={
                      statusDetail.workTrackingDetail.cancel_work_reason_other
                    }
                    label={"อื่นๆ โปรดระบุ"}
                    disable
                  />
                </div>
              ) : null}
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        case 19:
          return (
            <div>
              <div>
                <InputNormal
                  value={statusDetail?.workStatus.name}
                  label="สถานะงาน*"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={helper.momentDateTime(statusDetail.actual_date)}
                  label="วันเวลาที่ลบงาน*"
                  disable
                />
              </div>
              <div>
                <InputSelect
                  value={statusDetail.workTrackingDetail.delete_post_type}
                  options={[
                    { item: "ประกาศผิด/แก้ไข/ซ้ำ", value: 0 },
                    { item: "ลูกค้ายกเลิก/สินค้าไม่พร้อม", value: 1 },
                    {
                      item: "พขร.ยกเลิกงาน/รถเสีย/เข้ารับสินค้าไม่ทัน",
                      value: 2,
                    },
                    { item: "อื่น ๆ", value: 3 },
                  ]}
                  label="เหตุผลในการยกเลิกงาน*"
                  disable
                />
              </div>
              {statusDetail.workTrackingDetail.delete_post_type === 3 ? (
                <div>
                  <InputNormal
                    value={
                      statusDetail.workTrackingDetail.delete_post_reason_other
                    }
                    label={"อื่นๆ โปรดระบุ"}
                    disable
                  />
                </div>
              ) : null}
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
        default:
          return (
            <div>
              <div>
                <InputNormal
                  value={
                    statusDetail?.createable_by_type === 1
                      ? "ผู้ดูแลระบบ"
                      : "ผู้ใช้งาน"
                  }
                  label="ดำเนินการโดย"
                  disable
                />
              </div>
              <div>
                <InputNormal
                  value={createableName}
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div>
              {/* <div>
                <InputNormal
                  value={
                    statusDetail?.createableBy?.firstname +
                    " " +
                    statusDetail?.createableBy?.lastname
                  }
                  label="ผู้ดำเนินการ"
                  disable
                />
              </div> */}
            </div>
          );
      }
    } else {
      return null;
    }
  };
  return (
    <div>
      <div style={{ marginTop: 10 }}>
        {data?.map((ele, index) => {
          return (
            <div key={"status" + index} style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0px 10px 10px 10px",
                  }}
                >
                  <TripOriginIcon
                    sx={{ fontSize: 16, opacity: index === 0 ? 1 : 0.5 }}
                  />
                  <MoreVertIcon sx={{ opacity: index === 0 ? 1 : 0.5 }} />
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                    {" "}
                    {ele.workStatus.name}
                  </div>
                  <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                    {helper.momentDateTime(ele.actual_date, "short", false)}
                  </div>
                </div>
              </div>
              {/* {ele.workTrackingDetail || ele.workAbnormal ? ( */}
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  showStatus(ele);
                }}
              >
                <ArrowForwardIosIcon sx={{ opacity: 0.3 }} />
              </div>
              {/* ) : null} */}
            </div>
          );
        })}
      </div>
      <ModalWork
        open={showModal}
        close={() => {
          setShowModal(false);
        }}
        enableClose
        minHeight
      >
        <div style={{ padding: "0px 20px 20px 20px", height: "100%" }}>
          <div style={{ textAlign: "center" }}>สถานะงาน</div>
          <Line />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div style={{ paddingBottom: 20, overflow: "auto" }}>
              {detail()}
            </div>
            <div
            // style={{
            //   position: isMobile ? "" : "absolute",
            //   bottom: 20,
            //   left: 20,
            //   right: 20,
            //   overflow: "auto",
            // }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                  setStatusDetail(null);
                }}
              >
                ปิด
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
    </div>
  );
};
export default WorkStatus;
