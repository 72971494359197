// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .modal-select-map .MuiInputBase-root {
  padding: 10px 0px 0px 40px;
}
.modal-select-map .MuiFormLabel-root {
  padding: 10px 0px 0px 40px;
} */

.modal-select-truck .modal-select-truck-options {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.modal-select-truck .modal-select-truck-options:hover {
  /* background-color: #121f43;
  color: white; */
}
.modal-select-truck .modal-select-truck-options-select {
  /* background-color: rgb(255, 41, 41); */
  border: 1px solid rgb(255, 41, 41);
}
.swal2-container {
  z-index: 20000;
}

.modal-work::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-work {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/components/work/modalSelectMap.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;AACA;EACE;iBACe;AACjB;AACA;EACE,wCAAwC;EACxC,kCAAkC;AACpC;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC","sourcesContent":["/* .modal-select-map .MuiInputBase-root {\r\n  padding: 10px 0px 0px 40px;\r\n}\r\n.modal-select-map .MuiFormLabel-root {\r\n  padding: 10px 0px 0px 40px;\r\n} */\r\n\r\n.modal-select-truck .modal-select-truck-options {\r\n  display: flex;\r\n  align-items: center;\r\n  text-align: center;\r\n  padding: 10px;\r\n  cursor: pointer;\r\n}\r\n.modal-select-truck .modal-select-truck-options:hover {\r\n  /* background-color: #121f43;\r\n  color: white; */\r\n}\r\n.modal-select-truck .modal-select-truck-options-select {\r\n  /* background-color: rgb(255, 41, 41); */\r\n  border: 1px solid rgb(255, 41, 41);\r\n}\r\n.swal2-container {\r\n  z-index: 20000;\r\n}\r\n\r\n.modal-work::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n\r\n/* Hide scrollbar for IE, Edge and Firefox */\r\n.modal-work {\r\n  -ms-overflow-style: none; /* IE and Edge */\r\n  scrollbar-width: none; /* Firefox */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
