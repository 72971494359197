import "./FinanceTable.css";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  checkboxClasses,
  Typography,
  CircularProgress,
  Box,
  Modal,
  IconButton,
  FormControl,
  Avatar,
  Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import helper from "utils/helper";
import PaginationComponent from "components/pagination/Pagination";
import moment from "moment";
import instance from "utils/axiosInstance";
import { Link } from "react-router-dom";
import { notification } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import InfoIcon from "@mui/icons-material/Info";
import { resendGinkgo } from "../../../utils/api/finance/shipper";
import { resendGinkgo as resendGinkgoBuyer } from "../../../utils/api/trade-finance/buyer";
import { resendGinkgo as resendGinkgoTenant } from "../../../utils/api/rental-finance/tenant";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CommentIcon from "@mui/icons-material/Comment";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import FieldSelect from "components/field/FieldSelect";
import { getAllBank } from "utils/api/masterData/service";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import CustomModal from "components/modal/CustomModal";
import FieldTextArea from "components/field/FieldTextArea";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 10,
    fontWeight: 300,
  },
}));
const FinanceTable = ({
  items,
  setItems,
  fields,
  rowSelected,
  setRowSelected,
  pagination = {
    page: 1,
    per_page: 5,
    total: 0,
  },
  setPagination = () => {},
  isBusy = false,
  disabledSelectAll = false,
  selectAll = false,
  setSelectAll = () => {},
  type = "shipper",
  onDoubleClickRow = () => {},
  onClickRow = () => {},
  setIsLoading = () => {},
}) => {
  const [alertModal, setAlertModal] = useState(false);
  const [dataModal, setDataModal] = useState("");
  const [modalGinkgo, setModalGinkgo] = useState({
    open: false,
    receiptCode: "",
  });
  //fields
  // key: ชื่อของ property ใน items ที่จะนำมาแสดงในคอลัมน์นี้
  // label: ข้อความที่จะใช้เป็นชื่อคอลัมน์ในส่วนหัวของตาราง
  // thclass: คลาส CSS สำหรับ TableCell ในส่วนหัวของตาราง (th)
  // tdclass: คลาส CSS สำหรับ TableCell ในส่วนเนื้อของตาราง (td)
  // thstyle: สไตล์ CSS สำหรับ TableCell ในส่วนหัวของตาราง (th)
  // tdstyle: สไตล์ CSS สำหรับ TableCell ในส่วนเนื้อของตาราง (td)

  //ถ้าใช้ rowSelected ต้องมี items{rowIsActive , rowDisabled ,key} prop setItems ,rowSelected, setRowSelected

  // useEffect(() => {
  //   clg
  // }, [items]);

  const handleSelectAll = () => {
    let updatedItems;
    if (!selectAll) {
      updatedItems = items.map((item, index) => {
        if (!item.rowDisabled) {
          if (!item.rowIsActive) {
            setRowSelected((prevSelected) => [...prevSelected, item]);
          }
          return {
            ...item,
            rowIsActive: true,
          };
        } else {
          return item;
        }
      });
    } else {
      updatedItems = items.map((item, index) => {
        if (!item.rowDisabled) {
          if (item.rowIsActive) {
            setRowSelected((prevSelected) =>
              prevSelected.filter(
                (selectedItem) => selectedItem.key !== item.key
              )
            );
          }
          return {
            ...item,
            rowIsActive: false,
          };
        } else {
          return item;
        }
      });
    }
    setItems(updatedItems);
    setSelectAll(!selectAll);
  };

  const handleSelectItem = (item) => {
    const updatedItems = items.map((existingItem) => {
      if (existingItem === item) {
        return {
          ...existingItem,
          rowIsActive: !existingItem.rowIsActive,
        };
      }
      return existingItem;
    });
    if (!item.rowIsActive) {
      setRowSelected((prevSelected) => [...prevSelected, item]);
    } else {
      setRowSelected((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.key !== item.key)
      );
    }
    setItems(updatedItems);
  };

  const handleInvoice = async (invoiceCode) => {
    try {
      let response;
      if (type === "shipper") {
        response = await instance.get(
          `api/pdf/shipper/invoice/${invoiceCode}`,
          {
            responseType: "blob",
          }
        );
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "buyer") {
        response = await instance.get(`api/pdf/buyer/invoice/${invoiceCode}`, {
          responseType: "blob",
        });
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "seller") {
        response = await instance.get(
          `api/trade-finance/seller/invoice/${invoiceCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      } else if (type === "tenant") {
        response = await instance.get(`api/pdf/tenant/invoice/${invoiceCode}`, {
          responseType: "blob",
        });
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "lessor") {
        response = await instance.get(
          `api/rental-finance/lessor/invoice/${invoiceCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      } else {
        response = await instance.get(
          `api/finance/carrier/invoice/${invoiceCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "เกิดข้อผิดพลาดไม่สามารถโหลดเอกสารได้"
      );
    }
  };

  const handleBillingnote = async (billCode) => {
    try {
      let response;
      if (type === "shipper") {
        response = await instance.get(
          `api/pdf/shipper/billingnote/${billCode}`,
          {
            responseType: "blob",
          }
        );
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "buyer") {
        response = await instance.get(`api/pdf/buyer/billingnote/${billCode}`, {
          responseType: "blob",
        });
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "seller") {
        response = await instance.get(
          `api/trade-finance/seller/billingnote/${billCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      } else if (type === "tenant") {
        response = await instance.get(
          `api/pdf/tenant/billingnote/${billCode}`,
          {
            responseType: "blob",
          }
        );
        if (response) {
          helper.downloadFileFromResponse(response);
        }
      } else if (type === "lessor") {
        response = await instance.get(
          `/api/rental-finance/lessor/billingnote/${billCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      } else {
        response = await instance.get(
          `api/finance/carrier/billingnote/${billCode}/file`
        );
        if (response) {
          const url = `https://${response.data.result}`;
          window.open(url, "_blank");
        }
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "เกิดข้อผิดพลาดไม่สามารถโหลดเอกสารได้"
      );
    }
  };

  const handleReceipt = async (receiptCode, ginkgo_res) => {
    try {
      let response;
      const downloadFile = async (url) => {
        setIsLoading(true);
        try {
          response = await instance.get(url, { responseType: "blob" });
          if (response) {
            helper.downloadFileFromResponse(response);
          }
        } catch (error) {
          openNotificationWithIcon(
            "error",
            "Notification Error",
            "กรุณารอ 5 นาที"
          );
        } finally {
          setIsLoading(false);
        }
      };

      const openFileInNewTab = async (url) => {
        response = await instance.get(url);
        if (response) {
          const fileUrl = `https://${response.data.result}`;
          window.open(fileUrl, "_blank");
        }
      };

      if (type === "shipper" || type === "buyer" || type === "tenant") {
        if (ginkgo_res === 1) {
          await downloadFile(`api/finance/shipper/paid/${receiptCode}/pdf`);
        } else if (ginkgo_res === 0) {
          openNotificationWithIcon(
            "error",
            "Notification Error",
            "เจนไฟล์ไม่สำเร็จ"
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Notification Error",
            "กรุณารอ 5 นาที"
          );
        }
      } else if (type === "seller") {
        await openFileInNewTab(
          `api/trade-finance/seller/paid/${receiptCode}/file`
        );
      } else if (type === "carrier") {
        await openFileInNewTab(`api/finance/carrier/paid/${receiptCode}/file`);
      } else if (type === "lessor") {
        await openFileInNewTab(
          `/api/rental-finance/lessor/paid/${receiptCode}/file`
        );
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "เกิดข้อผิดพลาดไม่สามารถโหลดเอกสารได้"
      );
    }
  };

  const handleWht = async (receiptCode) => {
    try {
      let response;
      if (type === "seller") {
        response = await instance.get(
          `/api/trade-finance/carrier/paid/${receiptCode}/wht`,
          {
            responseType: "blob",
          }
        );
      } else if (type === "lessor") {
        response = await instance.get(
          `/api/rental-finance/lessor/paid/${receiptCode}/wht`,
          {
            responseType: "blob",
          }
        );
      } else {
        response = await instance.get(
          `/api/finance/carrier/user/payment_detail/7/${receiptCode}/wht`,
          {
            responseType: "blob",
          }
        );
      }

      if (response) {
        helper.downloadFileFromResponse(response);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "เกิดข้อผิดพลาดไม่สามารถโหลดเอกสารได้"
      );
    }
  };

  const handleReciptName = (item) => {
    const dataModal = (
      <Box sx={{ fontSize: "14px", lineHeight: "1.4", color: "#121F43" }}>
        <b>ชื่อผู้ที่รับใบแจ้งหนี้</b>
        <br />
        <span>{item.receipt_company_name}</span>
        <br />
        <br />
        <b>ประเภทผู้ที่รับใบแจ้งหนี้</b>
        <br />
        <span>{item.receipt_profile_type}</span>
        <br />
        <br />
        <b>รหัสสาขา</b>
        <br />
        <span>{item.receipt_branch_code}</span>
        <br />
        <br />
        <b>เลขประจำตัวผู้เสียภาษี</b>
        <br />
        <span>{item.receipt_tax_id}</span>
        <br />
        <br />
        <b>เบอร์โทร</b>
        <br />
        <span>{item.receipt_phone_number}</span>
        <br />
        <br />
        <b>อีเมล</b>
        <br />
        <span>{item.receipt_email}</span>
        <br />
        <br />
        <b>ที่อยู่</b>
        <br />
        <span>{item.receipt_address}</span>
        <br />
        <br />
        <b>ประเทศ</b>
        <br />
        <span>{item.receipt_country}</span>
        <br />
        <br />
        <b>จังหวัด</b>
        <br />
        <span>{item.receipt_province}</span>
        <br />
        <br />
        <b>เขต/อำเภอ</b>
        <br />
        <span>{item.receipt_district}</span>
        <br />
        <br />
        <b>แขวง/ตำบล</b>
        <br />
        <span>{item.receipt_subdistrict}</span>
        <br />
        <br />
        <b>รหัสไปรษณีย์</b>
        <br />
        <span>{item.receipt_postal_code}</span>
      </Box>
    );
    setDataModal(dataModal);
    setAlertModal(true);
  };

  const handleReciptDetail = async (item) => {
    const res = await getAllBank();
    let bankOptions;
    if (res) {
      const results = res.data.data.results;
      bankOptions = results.map((res) => ({
        value: res.id,
        name: res.name,
      }));
    }

    const dataModal = (
      <Box sx={{ fontSize: "14px", lineHeight: "1.4", color: "#121F43" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการชำระเงิน"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={item.receipt_date}
              showTime
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="จำนวนเงินที่ชำระจริง"
              subLab=" (ค่าเริ่มต้น: รวมราคาลูกค้าสุทธิ)"
              value={item.amount_of_transfer_actual}
              type="float"
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ธนาคารที่ชำระ`}
              options={bankOptions}
              value={item.bank_id}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="ref"
              value={item.ref}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldUpload
              name={"สลิปโอนเงิน*"}
              icon={<UploadFile />}
              value={item.file}
              accept="image/png, image/jpeg, application/pdf"
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldTextArea
              className="w-100"
              label="หมายเหตุ"
              value={item.credit_remark}
              multiline={true}
              maxRows={4}
              readOnly
            />
          </FormControl>
        </form>
      </Box>
    );
    setDataModal(dataModal);
    setAlertModal(true);
  };

  const renderXBtn = () => (
    <IconButton
      // onClick={handleCancel}
      onClick={() => {
        setAlertModal(false);
      }}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687072 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
          fill="#8CA3BA"
        />
      </svg>
    </IconButton>
  );

  const renderModalReceipt = () => {
    return (
      <>
        <Modal open={alertModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              minWidth: "400px",
              maxWidth: "500px",
              borderRadius: "20px",
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
              padding: "25px",
            }}
          >
            {renderXBtn()}
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#8CA3BA",
                  textAlign: "center",
                  fontFamily: "Kanit",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                ข้อมูลใบเสร็จ
              </Typography>
              <hr />
            </Box>
            <div
              style={{
                overflowY: "auto",
                height: "72vh",
                padding: "10px 0px",
              }}
            >
              {dataModal}
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const handleGinkgo = async (receiptCode) => {
    try {
      setModalGinkgo({
        open: false,
        receiptCode: "",
      });
      //  e.currentTarget.disabled = true;
      let response;
      if (type === "shipper") {
        response = await resendGinkgo(receiptCode);
      } else if (type === "buyer") {
        response = await resendGinkgoBuyer(receiptCode);
      } else if (type === "tenant") {
        response = await resendGinkgoTenant(receiptCode);
      }
      openNotificationWithIcon(
        "success",
        `${receiptCode} กำลังส่งไป Ginkgo`,
        response.data.description
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderTableBody = () => {
    if (items.length === 0) {
      return (
        <div style={{ overflow: "auto", height: "42vh" }}>
          <Table
            sx={{
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: "0 5px",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={fields.length + (rowSelected ? 1 : 0)}
                  className="text-center"
                >
                  Data not found
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    }

    return (
      <div style={{ overflow: "auto", height: "42vh" }}>
        <Table
          sx={{
            tableLayout: "fixed",
            borderCollapse: "separate",
            borderSpacing: "0 5px",
          }}
        >
          <TableBody>
            {items.map((item, index) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{
                  bgcolor: "white",
                  paddingTop: "20px",
                }}
                key={index}
                style={{
                  border: "2px solid blue",
                }}
                className={`FinanceTable ${
                  item.rowIsActive === true ? "rowChecked" : ""
                }`}
                onDoubleClick={() => onDoubleClickRow(item.key)}
                onClick={() => onClickRow(item.key)}
              >
                {rowSelected && (
                  <TableCell
                    padding="checkbox"
                    sx={{
                      height: "50px",
                    }}
                  >
                    <Checkbox
                      variant="outlined"
                      checked={item.rowIsActive}
                      disabled={item.rowDisabled}
                      onChange={() => handleSelectItem(item)}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: `var(--mui-palette-secondary-main)`,
                        },
                      }}
                    />
                  </TableCell>
                )}
                {fields.map((field, fieldIndex) => {
                  const value = item[field?.key];
                  return (
                    <TableCell
                      key={fieldIndex}
                      className={field.tdclass}
                      style={field.tdstyle}
                      align={field.align}
                      sx={{
                        fontSize: "10px",
                        fontWeight: 300,
                        padding: `5px`,
                        height: "60px",
                        wordWrap: "break-word",
                        // flex: 1,
                      }}
                    >
                      {!field.render && field.key === "invoice_code" && (
                        <Link onClick={() => handleInvoice(item.invoice_code)}>
                          <span style={{ fontSize: "10px" }}>
                            {item.invoice_code}
                          </span>
                        </Link>
                      )}

                      {!field.render && field.key === "billing_note_code" && (
                        <Link
                          onClick={() =>
                            handleBillingnote(item.billing_note_code)
                          }
                        >
                          <span style={{ fontSize: "10px" }}>
                            {item.billing_note_code}
                          </span>
                        </Link>
                      )}

                      {!field.render && field.key === "receipt_code" && (
                        <Link
                          onClick={() =>
                            handleReceipt(item.receipt_code, item.ginkgo_res)
                          }
                        >
                          <span style={{ fontSize: "10px" }}>
                            {item.receipt_code}
                          </span>
                        </Link>
                      )}

                      {!field.render && field.key === "pay_in_code" && (
                        <>
                          <Link onClick={() => handleReceipt(item.pay_in_code)}>
                            <span style={{ fontSize: "10px" }}>
                              {item.pay_in_code}
                            </span>
                          </Link>
                          <br />
                          <Link onClick={() => handleWht(item.pay_in_code)}>
                            <span style={{ fontSize: "10px" }}>
                              {item.withholding_tax_code}
                            </span>
                          </Link>
                        </>
                      )}

                      {!field.render && field.key === "bill_code" && (
                        <Link onClick={() => handleBillingnote(item.bill_code)}>
                          <span style={{ fontSize: "10px" }}>
                            {item.bill_code}
                          </span>
                        </Link>
                      )}

                      {!field.render &&
                        ![
                          "work_name",
                          "work_status",
                          "work_code",
                          "invoice_code",
                          "billing_note_code",
                          "receipt_code",
                          "pay_in_code",
                          "bill_code",
                        ].includes(field.key) &&
                        item[field.key]}

                      {field.key === "work_code" && (
                        <Link to={`../../work-details/${item.id}`}>
                          <div
                            style={{
                              fontSize: "10px",
                            }}
                          >
                            {item.work_code}
                          </div>
                        </Link>
                      )}

                      {field.key === "work_status" && (
                        <div
                          style={{
                            fontSize: "10px",
                            textAlign: "center",
                          }}
                        >
                          {item.work_status}
                        </div>
                      )}

                      {field.key === "work_name" && (
                        <LightTooltip title={item.work_name}>
                          <div
                            style={{
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.work_name}
                          </div>
                        </LightTooltip>
                      )}

                      {field.render === "date" &&
                        item[field.key] &&
                        moment(item[field.key]).format("YYYY-MM-DD kk:mm")}

                      {field.render === "currency" &&
                        (item[field.key]
                          ? helper.formatNumeric(item[field.key], "display")
                          : "0.00")}

                      {field.render === "percent" &&
                        (item[field.key]
                          ? helper.formatNumeric(item[field.key], "display") +
                            " %"
                          : "NaN")}

                      {field.render === "active" &&
                        (item[field.key] ? (
                          <CheckCircleIcon sx={{ color: "#28ce88" }} />
                        ) : (
                          <CancelIcon sx={{ color: "lightgray" }} />
                        ))}

                      {field.render === "invoice" && item[field.key] && (
                        <div>
                          <Link to={`../../work-details/${item.id}`}>
                            <span style={{ fontSize: "10px" }}>
                              {item.work_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "billingnote" && item[field.key] && (
                        <div>
                          <Link to={`../../work-details/${item.id}`}>
                            <span style={{ fontSize: "10px" }}>
                              {item.work_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() =>
                              handleBillingnote(item.billing_note_code)
                            }
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.billing_note_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "receipt" && item[field.key] && (
                        <div>
                          <Link to={`../../work-details/${item.id}`}>
                            <span style={{ fontSize: "10px" }}>
                              {item.work_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() =>
                              handleBillingnote(item.billing_note_code)
                            }
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.billing_note_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() =>
                              handleReceipt(item.receipt_code, item.ginkgo_res)
                            }
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.receipt_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "pay_in" && item[field.key] && (
                        <div>
                          <Link to={`../../work-details/${item.id}`}>
                            <span style={{ fontSize: "10px" }}>
                              {item.work_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() =>
                              handleBillingnote(item.billing_note_code)
                            }
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.billing_note_code}
                            </span>
                          </Link>
                          <br />
                          <Link onClick={() => handleReceipt(item.pay_in_code)}>
                            <span style={{ fontSize: "10px" }}>
                              {item.pay_in_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "ginkgo" && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "10px",
                                alignItems: "center",
                              }}
                            >
                              {item["ginkgo_res"] === null ? (
                                <span>pending</span>
                              ) : item["ginkgo_res"] ? (
                                <span>success</span>
                              ) : (
                                <LightTooltip title={item["ginkgo_msg"]} s>
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item["ginkgo_msg"]}
                                  </div>
                                </LightTooltip>
                              )}
                            </div>
                            <div style={{ display: "inline" }}>
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  pointerEvents: item.disabled
                                    ? "none"
                                    : "auto",
                                  opacity: item.disabled ? 0.5 : 1,
                                  cursor: item.disabled
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onClick={(e) => {
                                  e.currentTarget.disabled = true;
                                  setModalGinkgo({
                                    open: true,
                                    receiptCode: item.receipt_code,
                                  });
                                  // handleGinkgo(e, item.receipt_code)
                                }}
                              >
                                <ReplayCircleFilledIcon />
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {field.render === "receipt_name" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <InfoIcon onClick={(e) => handleReciptName(item)} />
                        </div>
                      )}

                      {field.render === "receipt_detail" && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              justifyContent: "center",
                            }}
                          >
                            <Box display={"inline-block"}>
                              <Avatar
                                variant="rounded"
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                                  bgcolor: "white",
                                }}
                                onClick={(e) => handleReciptDetail(item)}
                              >
                                <CommentIcon
                                  sx={{
                                    color: "secondary.main",
                                    fontSize: 20,
                                  }}
                                />
                              </Avatar>
                            </Box>
                            <Box display={"inline-block"}>
                              <Avatar
                                variant="rounded"
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                                  bgcolor: "white",
                                }}
                                onClick={(e) => handleReciptName(item)}
                              >
                                <ReceiptLongIcon
                                  sx={{
                                    color: "secondary.main",
                                    fontSize: 20,
                                  }}
                                />
                              </Avatar>
                            </Box>
                          </Box>
                        </>
                      )}

                      {field.render === "trade_invoice" && (
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            {item.order_code}#{item.key}
                          </span>

                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "trade_bill" && (
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            {item.order_code}#{item.key}
                          </span>

                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleBillingnote(item.bill_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.bill_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "trade_pay" && (
                        <div>
                          <span style={{ fontSize: "10px" }}>
                            {item.order_code}#{item.key}
                          </span>

                          <br />
                          <Link
                            onClick={() => handleInvoice(item.invoice_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.invoice_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() => handleBillingnote(item.bill_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.bill_code}
                            </span>
                          </Link>
                          <br />
                          <Link
                            onClick={() =>
                              handleReceipt(item.receipt_code, item.ginkgo_res)
                            }
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.receipt_code}
                            </span>
                          </Link>
                        </div>
                      )}

                      {field.render === "key" && (
                        <div style={{ textAlign: "center" }}>
                          <span> {item[field.key]}</span>
                          <br />
                          <span>#{item.key}</span>
                        </div>
                      )}

                      {field.render === "puchase&end_date" && (
                        <div style={{ textAlign: "center" }}>
                          <span>
                            {moment(item["puchase_date"]).format("YYYY-MM-DD")}
                          </span>
                          <br />
                          <span>
                            {" "}
                            {moment(item["end_date_term"]).format("YYYY-MM-DD")}
                          </span>
                        </div>
                      )}

                      {field.render === "amount&unit" && (
                        <div style={{ textAlign: "end" }}>
                          <span>
                            {helper.formatNumeric(
                              item["tenant_amount"],
                              "display"
                            )}{" "}
                            {item.tenant_amount_unit}
                          </span>
                        </div>
                      )}

                      {field.render === "receipt&wht" && (
                        <>
                          <Link
                            onClick={() => handleReceipt(item.receipt_code)}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {item.receipt_code}
                            </span>
                          </Link>
                          <br />
                          <Link onClick={() => handleWht(item.receipt_code)}>
                            <span style={{ fontSize: "10px" }}>
                              {item.withholding_tax_code}
                            </span>
                          </Link>
                        </>
                      )}

                      {field.render === "id_group" && (
                        <div style={{ maxWidth: "80px", textWrap: "wrap" }}>
                          {item?.groups?.map((items, index) => {
                            return (
                              "#" +
                              items?.id +
                              (index !== item.groups.length - 1 ? ", " : "")
                            );
                          })}
                        </div>
                      )}

                      {field.render === "date-edit" && item[field.key] && (
                        <>
                          <div>
                            {moment(item[field.key]).format("YYYY-MM-DD kk:mm")}
                          </div>
                          <div
                            style={{ textDecorationLine: "underline" }}
                            onClick={() => {
                              field?.onEdit(item);
                            }}
                          >
                            แก้ไข
                          </div>
                        </>
                      )}
                      {field.render === "minus-wht" && (
                        <>
                          <div>
                            {helper.formatNumeric(
                              parseFloat(item?.total_per_trip) -
                                (item?.wht ? parseFloat(item?.wht) : 0),
                              "display"
                            )}
                          </div>
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  const Loading = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={fields.length + (rowSelected ? 1 : 0)}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <span style={{ marginLeft: "10px" }}>Loading...</span>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <>
      {contextHolder}
      {renderModalReceipt()}
      {/* <TableContainer
        className="FinanceTable"
        style={{ maxHeight: "54vh", margin: "auto", overflow: "auto" }}
      > */}
      <CustomModal
        open={modalGinkgo.open}
        title={"ส่งใบเสร็จซ้ำ"}
        onCancel={() => {
          setModalGinkgo({
            open: false,
            receiptCode: "",
          });
        }}
        onConfirm={() => {
          handleGinkgo(modalGinkgo?.receiptCode);
        }}
      >
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          ต้องการส่งใบเสร็จซ้ำหรือไม่
        </div>
      </CustomModal>

      <div style={{ overflow: "auto", paddingTop: "20px" }}>
        <Table
          sx={{
            tableLayout: "fixed",
          }}
        >
          <TableHead sx={{ height: "74px" }}>
            <TableRow className="FinanceTable">
              {rowSelected && (
                <TableCell
                  sx={{
                    fontSize: "12px",
                    bgcolor: "secondary.main",
                    color: "white",
                  }}
                  padding="checkbox"
                >
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: `#fee`,
                      },
                    }}
                    disabled={
                      rowSelected.length === 0 ? disabledSelectAll : false
                    }
                  />
                </TableCell>
              )}
              {fields.map((field, index) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    fontSize: "12px",
                    bgcolor: "secondary.main",
                    color: "white",
                    wordWrap: "break-word",
                    padding: `16px 5px 16px 5px`,
                    padding: `5px`,
                  }}
                >
                  {field.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </div>
      {isBusy ? <Loading /> : renderTableBody()}

      {/* </TableContainer> */}
      <Typography color={"grey.main"}>
        แสดงผล{" "}
        {pagination.page !== 1
          ? parseInt(pagination.per_page) * parseInt(pagination.page) +
            1 -
            parseInt(pagination.per_page)
          : 1}
        -
        {pagination.page !== 1
          ? parseInt(pagination.per_page) * parseInt(pagination.page) +
              parseInt(pagination.per_page) -
              parseInt(pagination.per_page) >
            parseInt(pagination.total)
            ? pagination.total
            : parseInt(pagination.per_page) * parseInt(pagination.page) +
              parseInt(pagination.per_page) -
              parseInt(pagination.per_page)
          : parseInt(pagination.per_page) > parseInt(pagination.total)
          ? parseInt(pagination.total)
          : parseInt(pagination.per_page)}{" "}
        จาก {pagination.total}{" "}
        {rowSelected ? `(เลือกแล้ว ${rowSelected.length} รายการ)` : ""}
      </Typography>
      {!helper.IsEmptyFunction(setPagination) && (
        <PaginationComponent
          pagination={pagination}
          setPagination={setPagination}
          loading={isBusy}
        />
      )}
    </>
  );
};

export default FinanceTable;
