import { Paper } from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import React, { useEffect, useState } from "react";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Customized,
  LabelList,
  Legend,
  Line,
  LineChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getDataGraphByGroup,
  getListTruckByGroup,
} from "utils/api/dashboard/newDashboard";
import helper from "utils/helper";

const GraphByTruckGroup = ({
  data = null,
  title = "",
  maxValueY = 100,
  height = 450,
  width = 730,
  filter = {},
}) => {
  const abbreviateNumber = (numStr) => {
    if (numStr) {
      const num =
        typeof numStr === "number"
          ? numStr
          : parseFloat(numStr?.replace(/,/g, ""));
      if (isNaN(num)) {
        return null;
      }
      const absNum = Math.abs(num);
      const units = [
        { value: 1e12, suffix: "T" }, // Trillion
        { value: 1e9, suffix: "B" }, // Billion
        { value: 1e6, suffix: "M" }, // Million
        { value: 1e3, suffix: "K" }, // Thousand
      ];
      for (let i = 0; i < units.length; i++) {
        if (absNum >= units[i].value) {
          const abbreviatedNum = num / units[i].value;
          const rounded = parseFloat(abbreviatedNum.toFixed(2));
          return `${rounded} ${units[i].suffix}`;
        }
      }
      return num.toString();
    } else {
      return "0";
    }
  };

  const [totalShipper, setTotalShipper] = useState(0);
  const [totalCarrier, setTotalCarrier] = useState(0);
  const [avgTotal, setAvgTotal] = useState(0);
  const [listTruck, setListTruck] = useState([]);
  const [filterInGraph, setFilterInGraph] = useState({
    truck_type_id: "all",
  });
  const [newData, setNewData] = useState(null);
  //   let _filter = filter();
  useEffect(() => {
    getListTruck();
  }, []);

  useEffect(() => {
    if (filter) {
      if (filter.project_type === "all") {
        delete filter.project_type;
      }
      if (filter.distance_range === "all") {
        delete filter.distance_range;
      }
      delete filter.display;
      setFilterInGraph({
        ...filter,
        truck_type_id: filterInGraph.truck_type_id,
      });
    }
  }, [filter]);

  useEffect(() => {
    let totalCarrier = 0;
    let countCarrier = 0;
    let totalShipper = 0;
    let countShipper = 0;
    if (newData?.data) {
      newData?.data.forEach((value) => {
        if (
          !isNaN(Number(value.avg_carrier_freight_per_km)) &&
          Number(value.avg_carrier_freight_per_km) !== 0
        ) {
          totalCarrier += Number(value.avg_carrier_freight_per_km);
          countCarrier++;
        }
        if (
          !isNaN(Number(value.avg_shipper_freight_per_km)) &&
          Number(value.avg_shipper_freight_per_km) !== 0
        ) {
          totalShipper += Number(value.avg_shipper_freight_per_km);
          countShipper++;
        }
      });
    } else if (data) {
      data.forEach((value) => {
        value?.groups?.map((item) => {
          if (
            !isNaN(Number(item.carrier_freight_per_km)) &&
            Number(item.carrier_freight_per_km) !== 0
          ) {
            totalCarrier += Number(item.carrier_freight_per_km);
            countCarrier++;
          }
          if (
            !isNaN(Number(item.shipper_freight_per_km)) &&
            Number(item.shipper_freight_per_km) !== 0
          ) {
            totalShipper += Number(item.shipper_freight_per_km);
            countShipper++;
          }
        });
      });
    }
    let avgCarrier =
      countCarrier > 0 ? (totalCarrier / countCarrier).toFixed(2) : 0;
    let avgShipper =
      countShipper > 0 ? (totalShipper / countShipper).toFixed(2) : 0;
    const avg = ((avgShipper / avgCarrier - 1) * 100).toFixed(2);
    setTotalShipper(Number(avgShipper).toFixed(2));
    setTotalCarrier(Number(avgCarrier).toFixed(2));
    setAvgTotal(isNaN(avg) ? Number((0).toFixed(2)) : Number(avg).toFixed(2));
  }, [data, newData?.data, filterInGraph]);

  useEffect(() => {
    if (filterInGraph.truck_type_id !== "all") {
      getData();
    } else if (filterInGraph.truck_type_id === "all") {
      setNewData(null);
    }
  }, [filterInGraph]);
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>
                {entry.value == "avg_shipper_freight_per_km"
                  ? "Avg.ราคาขาย"
                  : entry.value === "avg_carrier_freight_per_km"
                  ? "Avg.ราคาจ้าง"
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "avg_shipper_freight_per_km"
                ? "Avg.ราคาขาย :"
                : entry.dataKey === "avg_carrier_freight_per_km"
                ? "Avg.ราคาจ้าง :"
                : ""}
              {helper.formatNumber(entry.value)} บาท/กม.
            </span>
          </div>
        ))}
      </div>
    );
  };
  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;

    console.log("formattedGraphicalItems", formattedGraphicalItems);
    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    // render custom content using points from the graph
    return firstSeries?.props?.points.map((firstSeriesPoint, index) => {
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;

      return (
        <Rectangle
          key={firstSeriesPoint.payload.name}
          width={10}
          height={yDifference}
          x={secondSeriesPoint.x - 5}
          y={secondSeriesPoint.y}
          fill={
            yDifference > 0
              ? "rgba(255, 49, 111,0.5)"
              : yDifference < 0
              ? "rgba(72,208,80,0.5)"
              : "none"
          }
        />
      );
    });
  };

  const getListTruck = async () => {
    const param = {
      truck_group: title,
    };
    const res = await getListTruckByGroup(param);
    if (res.data.code === 1000) {
      const array = res.data.data.map((item) => {
        return { value: item.id, name: item.name };
      });
      setListTruck(array);
    }
  };

  const getData = async (fil = filterInGraph) => {
    const res = await getDataGraphByGroup({ ...fil, truck_group: title });
    if (res.data.code === 1000) {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        const avgShipperFreight = Number(item.avg_shipper_freight_per_km) || 0;
        const avgCarrierFreight = Number(item.avg_carrier_freight_per_km) || 0;

        let diff =
          ((avgShipperFreight / avgCarrierFreight - 1) * 100).toFixed(2) ?? 0;

        return {
          ...item,
          avg_shipper_freight_per_km:
            Number(item.avg_shipper_freight_per_km).toFixed(2) ?? 0,
          avg_carrier_freight_per_km:
            Number(item.avg_carrier_freight_per_km).toFixed(2) ?? 0,
          label_shipper:
            diff === "NaN"
              ? "-"
              : `${Number(item.avg_shipper_freight_per_km).toFixed(2) ?? 0},${
                  diff > 0 ? `+${diff}` : diff < 0 ? `${diff}` : ""
                }`,
          label_carrier: `${
            Number(item.avg_carrier_freight_per_km).toFixed(2) ?? 0
          }`,
          //   groups: Object.values(
          //     item.groups?.reduce(
          //       (
          //         acc,
          //         { company_name, carrier_freight_per_km, shipper_freight_per_km }
          //       ) => {
          //         if (!acc[company_name]) {
          //           acc[company_name] = {
          //             company_name,
          //             carrier_freight_per_km,
          //             shipper_freight_per_km,
          //             count: 0,
          //           };
          //         }
          //         acc[company_name].count += 1;

          //         return acc;
          //       },
          //       {}
          //     )
          //   ),
        };
      });
      const maxCR = Math.max(
        ...response.map((item) => item.avg_carrier_freight_per_km)
      );
      const maxSH = Math.max(
        ...response.map((item) => item.avg_shipper_freight_per_km)
      );
      const niceMaxValueCR = Math.ceil(maxCR + maxCR * 0.6);
      const niceMaxValueSH = Math.ceil(maxSH + maxSH * 0.6);

      setNewData({
        data: mapGroups,
        maxValueY:
          niceMaxValueSH > niceMaxValueCR
            ? niceMaxValueSH > 0
              ? niceMaxValueSH
              : niceMaxValueCR > 0
              ? niceMaxValueCR
              : 1
            : niceMaxValueCR > 0
            ? niceMaxValueCR
            : 1,
      });
    } else {
      console.log("error handleGetDataGraphGroup In Graph");
    }
  };

  return (
    <>
      <Paper
        style={{
          backgroundColor: "white",
          padding: "15px",
          borderRadius: "10px",
          height: "100%",
          boxShadow: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 0.4, fontSize: "18px", fontWeight: 500 }}>
            {title}
          </div>
          <div
            style={{
              flex: 0.6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ประเภทรถ"}
                options={[{ value: "all", name: "ทั้งหมด" }, ...listTruck]}
                style={{
                  width: "-webkit-fill-available",
                }}
                defaultValue={true}
                value={filterInGraph?.truck_type_id}
                onChange={(val) =>
                  setFilterInGraph({
                    ...filterInGraph,
                    truck_type_id: val,
                  })
                }
                closePaddingBottom={true}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            paddingTop: "20px",
            gap: 20,
          }}
        >
          <div style={{ fontWeight: 500, color: "#FF8B00" }}>
            ขาย: {Number(totalShipper) === 0 ? "-" : totalShipper}
          </div>
          <div style={{ fontWeight: 500, color: "#355BAA" }}>
            จ้าง: {Number(totalCarrier) === 0 ? "-" : totalCarrier}
          </div>
          <div
            style={{
              fontWeight: 500,
              color:
                avgTotal > 0
                  ? "rgb(72, 208, 80)"
                  : avgTotal < 0
                  ? "rgb(255, 49, 111)"
                  : "#FFC675",
            }}
          >
            ส่วนต่าง: {Number(avgTotal) === 0 ? "-" : avgTotal > 0 ? "+ " : ""}
            {Number(avgTotal) === 0 ? "" : avgTotal + "%"}
          </div>
        </div>

        <ResponsiveContainer width={"100%"} height={height}>
          <LineChart
            width={width}
            height={height}
            data={newData?.data ?? data}
            margin={{
              top: 30,
              right: 60,
            }}
          >
            <XAxis
              dataKey={"xname"}
              style={{ fontSize: "10px", fontWeight: 500 }}
            />
            <YAxis
              style={{ fontSize: "10px", fontWeight: 500 }}
              tickFormatter={helper.formatNumber}
              domain={[0, newData?.maxValueY ?? maxValueY]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={<CustomLegend />} width={"100%"} />
            <CartesianGrid stroke="#f5f5f5" />
            <Customized component={CustomizedRectangle} />
            <Line
              strokeWidth={2}
              dataKey="avg_shipper_freight_per_km"
              stroke={`#FF8B00`}
            >
              <LabelList
                dataKey="label_shipper"
                fill={`#FF8B00`}
                content={(props) => {
                  const { x, y, value, width, target, fill } = props;
                  const val = value.split(",");
                  return (
                    <text
                      x={x + 5}
                      y={y - 10}
                      fill={fill}
                      textAnchor="top"
                      fontSize="14px"
                      fontWeight="500"
                      transform={`rotate(-45, ${x + 5}, ${y})`}
                    >
                      <tspan>
                        {Number(val[0]) === 0
                          ? "-"
                          : helper.formatNumber(val[0])}
                      </tspan>
                      <tspan> </tspan>
                      <tspan
                        fill={
                          Number(val[1]) > 0
                            ? "rgb(72, 208, 80)"
                            : Number(val[1]) < 0
                            ? "rgb(255, 49, 111)"
                            : "#FFC675"
                        }
                      >
                        {val[1] ? `${val[1]} %` : ""}
                      </tspan>
                    </text>
                  );
                }}
              />
            </Line>
            <Line
              strokeWidth={2}
              dataKey="avg_carrier_freight_per_km"
              stroke={`#355BAA`}
            >
              <LabelList
                dataKey="label_carrier"
                fill={`#355BAA`}
                content={(props) => {
                  const { x, y, value, width, target, fill } = props;
                  return (
                    <text
                      x={x - 3}
                      y={y + 12}
                      fill={fill}
                      textAnchor="end"
                      fontSize="14px"
                      fontWeight="500"
                      transform={`rotate(-45, ${x}, ${y})`}
                    >
                      {Number(value) === 0 ? "-" : helper.formatNumber(value)}
                    </text>
                  );
                }}
              />
            </Line>
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default GraphByTruckGroup;
