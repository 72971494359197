import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuRentalFinance } from "utils/submenuoptions";
import FieldDatePicker from "components/field/FieldDatePicker";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import {
  cancelBillingnote,
  createPaid,
  getBillingnoteGroup,
} from "utils/api/rental-finance/lessor";
import UpdateStatusButton2way from "components/button/finance/UpdateStatusButton2way";
import * as currency from "currency.js";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import { getAllBank } from "utils/api/masterData/service";
import SelectRowValue from "components/button/SelectRowValue";
import FieldTextArea from "components/field/FieldTextArea";
import AuthContext from "components/context/AuthContext";

const TruckLessorBillingNoteGroup = () => {
  const { adminAuth } = useContext(AuthContext);
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "bill_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    bill_codes: [],
    amount_per_trip: 0,
    vat_per_trip: 0,
    total_per_trip: 0,
    decimal: 2,
    amount_of_transfer_actual: 0,
    bank_id: 1,
    ref: "",
    file: "",
    receipt_voucher_code: "",
    wht: 0,
  });
  const [error, setError] = useState({
    actual_date: false,
    bill_codes: false,
    amount_per_trip: false,
    vat_per_trip: false,
    total_per_trip: false,
    decimal: false,
    amount_of_transfer_actual: false,
    receipt_voucher_code: false,
    bank_id: false,
    ref: false,
    file: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [calculateWHT, setCalculateWHT] = useState(0);
  const [cancelDetail, setCancelDetail] = useState("");
  const [errorDetail, setErrorDetail] = useState(false);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  useEffect(() => {
    const totalPerTrip = parseFloat(
      String(updateStatusForm?.total_per_trip).replace(/,/g, "")
    );
    const wht = parseFloat(String(updateStatusForm?.wht).replace(/,/g, ""));
    setCalculateWHT(totalPerTrip - wht);
  }, [updateStatusForm]);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการซื้อขาย",
        path: null,
      },
      {
        label: "ข้อมูลการเงินซื้อขาย",
        path: null,
      },
      {
        label: "ประวัติการเตรียมแจ้งโอน",
        path: null,
      },
    ]);
    handleSearch();
    getBank();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuRentalFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(11);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const customer_id = rowSelected[0].customer_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.customer_id !== customer_id) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBankOptions(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getBillingnoteGroup(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.customer_id)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const customer_id = rowSelected[0].customer_id;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = customer_id !== item.customer_id;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (["actual_date", "bank_id", "file"].includes(name)) {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "decimal") {
      const value = event;
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["bill_codes"].push(val["key"]);
            } else if (
              ["amount_per_trip", "vat_per_trip", "total_per_trip"].includes(
                key
              )
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            }
          });
          return result;
        },
        {
          bill_codes: [],
          amount_per_trip: 0,
        }
      );
      const vat = currency(groupdata.amount_per_trip).multiply(0.01).value;
      const total = currency(groupdata.amount_per_trip).subtract(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display",
        value
      );
      const vat_per_trip = helper.formatNumeric(vat, "display", value);
      const total_per_trip = helper.formatNumeric(total, "display", value);
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        amount_of_transfer_actual: total_per_trip,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      if (["vat_per_trip"].includes(name)) {
        const amount = updateStatusForm.amount_per_trip;
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
          total_per_trip: currency(amount, {
            precision: updateStatusForm.decimal,
            symbol: "",
          })
            .subtract(value)
            .format(),
        }));
      } else {
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["bill_codes"].push(val["key"]);
            } else if (
              [
                "amount_per_trip",
                "vat_per_trip",
                "total_per_trip",
                "wht",
              ].includes(key)
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            }
          });
          return result;
        },
        {
          bill_codes: [],
          amount_per_trip: 0,
          vat_per_trip: 0,
          total_per_trip: 0,
        }
      );
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display"
      );
      const vat_per_trip = helper.formatNumeric(
        groupdata.vat_per_trip,
        "display"
      );
      const total_per_trip = helper.formatNumeric(
        groupdata.total_per_trip,
        "display"
      );
      const wht = helper.formatNumeric(groupdata.wht, "display");

      const amount_of_transfer_actual = helper.formatNumeric(
        groupdata.total_per_trip - groupdata.wht,
        "display"
      );
      setUpdateStatusForm({
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        bill_codes: groupdata.bill_codes,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        decimal: 2,
        amount_of_transfer_actual: amount_of_transfer_actual,
        bank_id: 1,
        ref: "",
        file: "",
        receipt_voucher_code: "",
        wht: wht,
      });
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      bill_codes: updateStatusForm.bill_codes,
      actual_date: updateStatusForm.actual_date,
      amount_per_trip: currency(updateStatusForm.amount_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      vat_per_trip: currency(updateStatusForm.vat_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      total_per_trip: currency(updateStatusForm.total_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      decimal: updateStatusForm.decimal,
      amount_of_transfer_actual: currency(
        updateStatusForm.amount_of_transfer_actual
      ).value,
      bank_id: updateStatusForm.bank_id,
      ref: updateStatusForm.ref,
      file: updateStatusForm.file,
      receipt_voucher_code: updateStatusForm.receipt_voucher_code,
      wht: currency(updateStatusForm.wht, {
        precision: updateStatusForm.decimal,
      }).value,
    };
    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (
        value === undefined ||
        value === null ||
        value === ""
        // || value === 0
      ) {
        newError[key] = true;
      } else {
        newError[key] = false;
      }
    });
    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    newError.actual_date = !check_actual_date;
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createPaid(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error.response.data.description);
        openNotificationWithIcon("error", "Notification Error", "Error");
        return false;
      }
    } else {
      setError(newError);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  useEffect(() => {
    if (cancelDetail !== "") {
      setErrorDetail(false);
    }
  }, [cancelDetail]);

  const handleCancelStatus = async () => {
    if (cancelDetail === "") {
      setErrorDetail(true);
    } else {
      try {
        const body = {
          detail: cancelDetail,
        };
        const response = await cancelBillingnote(
          rowSelected[0].bill_code,
          body
        );
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Notification Error",
          error.response.data.description
        );
        return false;
      }
    }
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "bill_date", name: "วันที่เตรียมแจ้งโอนล่าสุด" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่ใบสั่งซื้อ" },
                { value: "invoice_code", name: "เลขที่ใบตั้งเบิก" },
                { value: "bill_code", name: "เลขที่ใบเตรียมแจ้งโอน" },
                { value: "id", name: "ไอดี" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton2way
              title="แจ้งโอน / ยกเลิกการเตรียมแจ้งโอน"
              description="คุณต้องการแจ้งโอนหรือยกเลิกการเตรียมแจ้งโอน?"
              textUpdateBtn="แจ้งโอน"
              textCancelBtn="ยกเลิกการเตรียมแจ้งโอน"
              cancelDescription="ยืนยันยกเลิกการเตรียมแจ้งโอน?"
              onClick={handleOnclickUpdateStatusButton}
              onConfirmCancel={handleCancelStatus}
              onConfirmUpdate={handleSubmitUpdateStatus}
              selectedLength={rowSelected.length}
              openNotificationWithIcon={openNotificationWithIcon}
              onClose={() => setCancelDetail("")}
              childrenCancel={() => (
                <>
                  <FieldTextArea
                    placeholder="กรุณากรอกเหตุผลในการยกเลิก"
                    onChange={(e) => setCancelDetail(e.target.value)}
                    value={cancelDetail}
                    error={errorDetail}
                    errorMessage="*จำเป็นต้องระบุ"
                  />
                </>
              )}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton2way>
            <FormControl>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการแจ้งโอน *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนใบเตรียมแจ้งโอน"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.bill_codes.length}
              readOnly
              error={error.bill_codes}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาซื้อ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.amount_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.amount_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมภาษีมูลค่าเพิ่ม"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.vat_per_trip}
              onChange={(e) => handleUpdatestatusFormChange("vat_per_trip", e)}
              type="float"
              precision={updateStatusForm.decimal}
              error={error.vat_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="ราคารวมภาษีมูลค่าเพิ่ม"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.total_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.total_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label=" หัก ณ ที่จ่าย"
              value={updateStatusForm.wht}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมราคาเช่าสุทธิหลังหักภาษี"
              value={helper.addComma(calculateWHT)}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="จำนวนทศนิยม"
              options={[
                { value: 2, name: "2" },
                { value: 4, name: "4" },
                { value: 6, name: "6" },
              ]}
              value={updateStatusForm.decimal}
              onChange={(e) => handleUpdatestatusFormChange("decimal", e)}
              error={error.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="จำนวนเงินที่ชำระจริง*"
              subLab=" (ค่าเริ่มต้น: รวมราคาลูกค้าสุทธิ)"
              value={updateStatusForm.amount_of_transfer_actual}
              onChange={(e) =>
                handleUpdatestatusFormChange("amount_of_transfer_actual", e)
              }
              type="float"
              error={error.amount_of_transfer_actual}
              helperText={
                error.amount_of_transfer_actual ? "จำเป็นต้องระบุ" : ""
              }
              precision={updateStatusForm.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ธนาคารที่ชำระ*`}
              options={bankOptions}
              value={updateStatusForm.bank_id}
              onChange={(e) => handleUpdatestatusFormChange("bank_id", e)}
              error={error.bank_id}
              errorText={error.bank_id ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="ref*"
              value={updateStatusForm.ref}
              onChange={(e) => handleUpdatestatusFormChange("ref", e)}
              error={error.ref}
              helperText={error.ref ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldUpload
              name={"สลิปโอนเงิน*"}
              icon={<UploadFile />}
              value={updateStatusForm.file}
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => handleUpdatestatusFormChange("file", e)}
              error={error.file}
              textError={error.file ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="ใบเสร็จจากผู้ขาย"
              value={updateStatusForm.receipt_voucher_code}
              onChange={(e) =>
                handleUpdatestatusFormChange("receipt_voucher_code", e)
              }
              error={error.receipt_voucher_code}
              helperText={error.receipt_voucher_code ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "id",
        label: "ไอดี",
        tdclass: "text-center",
        render: "id_group",
      },
      {
        key: "actual_date",
        label: "วันที่เตรียมแจ้งโอน",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "bill_code",
        label: "เลขที่ใบเตรียมแจ้งโอน",
        tdclass: "text-center",
      },
      // {
      //   key: "customer_name",
      //   label: "ผู้ซื้อ",
      // },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "lessor_name",
        label: "ผู้ให้เช่า",
        tdclass: "text-center",
      },
      {
        key: "amount_per_trip",
        label: "ราคาผู้ให้เช่า",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "vat_per_trip",
        label: "ภาษีหัก ณ ที่จ่าย",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "total_per_trip",
        label: "ราคารวมภาษีมูลค่าเพิ่ม",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "wht",
        label: "ภาษีหัก ณ ที่จ่าย",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "",
        label: "ราคาหลังหักภาษี ณ ที่จ่าย",
        tdclass: "text-end",
        render: "minus-wht",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="lessor"
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TruckLessorBillingNoteGroup;
