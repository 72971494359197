import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FilledInput, IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import "./modalSelectMap.css";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import Line from "components/layout/line";
import { getTruckType } from "utils/api/masterData/truckType";
// const google = window.google;

const isMobile = window.innerWidth <= 768 ? true : false;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? 350 : 400,
  height: "80vh",
  //   height: 200,
  overflow: "scroll",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};
const ModalSelectTruck = (props) => {
  const { value, close, open, onClick, truckType } = props;
  const [truck, setTruck] = useState();
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();
  useEffect(() => {
    if (!truckType && open) {
      getTruck();
    } else {
      setTruck(truckType);
    }
  }, [truckType, open]);

  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
    });
    console.log(truck);
    // truckType = truck.data.data.results;
    setTruck(truck.data.data.results);
  };
  return (
    <div>
      <Modal open={open} className="modal-select-truck">
        <Box sx={{ ...style }}>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </div>
          <div style={{ marginTop: 40 }}>
            {truck?.map((ele, index) => {
              return (
                <div key={index + ele.name} onClick={() => onClick(ele)}>
                  <div
                    className={
                      "modal-select-truck-options " +
                      (ele?.id === value?.id
                        ? "modal-select-truck-options-select"
                        : "")
                    }
                  >
                    <div
                      style={{
                        flex: 0.4,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={helper.addhtpps(ele.image)}
                        height={30}
                        alt={"truck"}
                      />
                    </div>
                    <div style={{ flex: 0.6, textAlignLast: "start" }}>
                      <div
                        className="modal-select-truck-label"
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {ele.name}
                      </div>
                      <div
                        className="modal-select-truck-label"
                        style={{ fontSize: 10, fontWeight: 300 }}
                      >
                        {ele.width +
                          " x " +
                          ele.length +
                          " x " +
                          ele.height +
                          " ม. " +
                          ele.weight +
                          " กก."}
                      </div>
                    </div>
                  </div>
                  <Line />
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectTruck;
