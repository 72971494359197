import {
  CleaningServices,
  Search,
  Star,
  StarBorder,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import GraphFavorite from "components/dashboard/GraphFavorite";
import ModalAddTargetTruckRental from "components/dashboard/ModalAddTargetTruckRental";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import Pagination from "components/pagination/Pagination";
import LightTooltip from "components/tooltip/LightTooltip";
import ModalWork from "components/work/modalWork";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { PiFlagPennantFill } from "react-icons/pi";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getAllTruckTenantProjectForGraph,
  getDashboardTruckRental,
  getFavTruckTenantProject,
} from "utils/api/dashboard/newDashboard";
import { getAllTenant } from "utils/api/truckRental/truckRental";
import helper from "utils/helper";

const TruckRentalDashboard = () => {
  const [dataTruckRental, setDataTruckRental] = useState([]);
  const [dataTruckRentalMerge, setDataTruckRentalMerge] = useState([]);
  const [barData, setBarData] = useState(null);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [allSum, setAllSum] = useState(0);
  const [allAmount, setAllAmount] = useState(0);
  const [allTarget, setAllTarget] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [showGetTenant, setShowGetTenant] = useState(false);
  const [allTenantProject, setAllTenantProject] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [showFav, setShowFav] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const [dataFavProject, setDataFavProject] = useState(false);
  const [modalUploadTarget, setModalUploadTarget] = useState(false);
  const [allTruckTenantProject, setAllTruckTenantProject] = useState([]);
  const [filterTenant, setFilterTenant] = useState({
    sort_by: "created_at",
    start_date: "",
    end_date: "",
    search_by: "company_name",
    search_val: "",
    page: 1,
    per_page: 3,
    total: 0,
  });
  const subDashboard = [
    {
      label: "งานขนส่ง",
      path: "/dashboard/work",
    },
    {
      label: "งานซื้อขาย",
      path: "/dashboard/trade",
    },
    {
      label: "งานเช่า",
      path: "/dashboard/truck-rental",
    },
    {
      label: "การเงิน",
      path: "/dashboard/finance",
    },
  ];
  const breadcrumbs = [
    {
      label: "งานเช่า",
      path: null,
    },
  ];
  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
  const [filterDashboard, setFilterDashboard] = useState({
    type_data_set: "day",
    start_date: firstDayOfMonth,
    end_date: yesterday,
    display: ["revenue", "target"],
  });

  useEffect(() => {
    getAllTruckTenantProject();
  }, []);
  useEffect(() => {
    getDataGraph();
    if (showFav) {
      handleGetFavTenantProject();
    }
  }, [filterDashboard]);
  useEffect(() => {
    if (showFav) {
      handleGetFavTenantProject();
    } else {
      setDataFavProject([]);
    }
  }, [showFav]);
  useEffect(() => {
    if (dataTruckRental) {
      getDataYearAgo();
    }
  }, [dataTruckRental]);
  const formatNumber = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const getRandomRGBA = (alpha = 0.8) => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.random() * 20;
    const lightness = 50 + Math.random() * 10;

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
      s /= 100;
      l /= 100;
      const k = (n) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

      return [
        Math.round(f(0) * 255),
        Math.round(f(8) * 255),
        Math.round(f(4) * 255),
      ];
    };

    const [r, g, b] = hslToRgb(hue, saturation, lightness);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Add transparency
  };
  const getAllTruckTenantProject = async () => {
    await getAllTruckTenantProjectForGraph().then((res) => {
      if (res.data.code === 1000) {
        const master = res.data.data.map((val) => {
          return val.project_name;
        });

        setAllTruckTenantProject(master);
      }
    });
  };
  const getDataGraph = async (fil = filterDashboard) => {
    let _filter = { ...fil };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    delete _filter.display;

    await getDashboardTruckRental(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          label: `${formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, total_tenant, tenant_amount }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    amount: 0,
                    sum: 0,
                    count: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].sum += total_tenant;
                acc[project_name].amount += tenant_amount;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.sum - a.sum),
          projects: Object.values(
            item.projects?.reduce((acc, { value, name, count }) => {
              acc[name] = {
                name,
                value: parseFloat(value),
              };
              return acc;
            }, {})
          ),
          ...item.projects.reduce((acc, curr) => {
            acc[curr.name] = parseFloat(curr.value);
            return acc;
          }, {}),
        };
      });
      allTruckTenantProject.forEach((key) => {
        const allZeros = mapGroups.every((obj) => obj[key] === 0);
        if (allZeros) {
          mapGroups.forEach((obj) => delete obj[key]);
        }
      });
      const totalSum = response.reduce(
        (sum, item) => sum + Number(item.sum),
        0
      );
      const totalTarget = response.reduce(
        (sum, item) => sum + Number(item.target),
        0
      );
      const totalAmount = response.reduce(
        (sum, item) => sum + Number(item.amount),
        0
      );
      //   allProject.forEach((key) => {
      //     const allZeros = mapGroups.every((obj) => obj[key] === 0);
      //     if (allZeros) {
      //       mapGroups.forEach((obj) => delete obj[key]);
      //     }
      //   });
      //   amountAllProject.forEach((key) => {
      //     const allZeros = mapGroups.every((obj) => obj[key] === 0);
      //     if (allZeros) {
      //       mapGroups.forEach((obj) => delete obj[key]);
      //     }
      //   });
      setDataTruckRental(mapGroups);
      setBarData(mapGroups[mapGroups.length - 1]);
      setGraph1(mapGroups[mapGroups.length - 2]);
      setGraph2(mapGroups[mapGroups.length - 1]);
      //   setBarAmountData(mapGroups[mapGroups.length - 1]);
      //   setGraphAmount1(mapGroups[mapGroups.length - 2]);
      //   setGraphAmount2(mapGroups[mapGroups.length - 1]);
      setAllSum(totalSum);
      setAllAmount(Number(totalAmount).toFixed(2));
      setAllTarget(totalTarget);
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      setMaxValue(niceMax > 0 ? niceMax : 100);

      //   const maxCount = Math.max(...response.map((item) => item.count));
      //   const niceMaxCount = Math.ceil(maxCount + maxCount * 0.2);
      //   setMaxValueCount(niceMaxCount);
    });
  };
  const getDataYearAgo = async (fil = filterDashboard) => {
    let _filter = {
      ...fil,
      start_date: moment(fil.start_date).subtract(1, "year"),
      end_date: moment(fil.end_date).subtract(1, "year"),
    };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    delete _filter.display;

    await getDashboardTruckRental(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          label: `${formatNumber(Number(item.sum).toFixed(2))}`,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { project_name, total_tenant, tenant_amount }) => {
                if (!acc[project_name]) {
                  acc[project_name] = {
                    project_name,
                    amount: 0,
                    sum: 0,
                    count: 0,
                  };
                }
                acc[project_name].count += 1;
                acc[project_name].sum += total_tenant;
                acc[project_name].amount += tenant_amount;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.sum - a.sum),
        };
      });

      const newTruckRental = dataTruckRental.map((item, index) => ({
        ...item,
        sum2: mapGroups.find((e, i) => i === index)?.sum || 0,
        amount2: mapGroups.find((e, i) => i === index)?.amount || 0,
      }));
      const max = Math.max(...response.map((item) => item.sum));
      const maxTarget = Math.max(...response.map((item) => item.target));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      const niceMaxTargetValue = Math.ceil(maxTarget);
      const niceMax = Math.max(niceMaxTargetValue, niceMaxValue);
      const niceMaxCompareYearAgo = Math.max(maxValue, niceMax);
      setMaxValue(niceMaxCompareYearAgo);
      setDataTruckRentalMerge(newTruckRental);
    });
  };
  const getTenant = async (filter = filterTenant) => {
    await getAllTenant(filter)
      .then((res) => {
        const results = res.data.results;
        setTenantList(results);
        setFilterTenant({ ...filterTenant, total: res.data.total });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleGetFavTenantProject = async (fil = filterDashboard) => {
    setLoadingFav(true);
    setDataFavProject([]);
    let results = [];
    let _filter = { ...fil };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    delete _filter.display;
    const param = {
      on_dashboard: true,
    };
    const res = await getAllTruckTenantProjectForGraph(param);
    if (res.data.code === 1000) {
      for (let value of res?.data?.data) {
        await getFavTruckTenantProject(value.id, _filter).then(async (res) => {
          const response = res.data.data;
          const mapGroups = response?.map((item) => {
            return {
              ...item,
              groups: Object.values(
                item.groups?.reduce(
                  (acc, { project_name, total_tenant, tenant_amount }) => {
                    if (!acc[project_name]) {
                      acc[project_name] = {
                        project_name,
                        amount: 0,
                        sum: 0,
                        count: 0,
                      };
                    }
                    acc[project_name].count += 1;
                    acc[project_name].sum += total_tenant;
                    acc[project_name].amount += tenant_amount;
                    return acc;
                  },
                  {}
                )
              ).sort((a, b) => b.sum - a.sum),
            };
          });
          const max = Math.max(...response.map((item) => item.sum));
          const maxTarget = Math.max(...response.map((item) => item.target));
          const niceMaxValue =
            maxTarget > max
              ? Math.ceil(maxTarget + maxTarget * 0.1)
              : Math.ceil(max + max * 0.1);

          let mergeValue;
          let niceMaxValue2;
          let filterYearAgo = {
            ..._filter,
            start_date: moment(_filter.start_date).subtract(1, "year"),
            end_date: moment(_filter.end_date).subtract(1, "year"),
          };
          await getFavTruckTenantProject(value.id, filterYearAgo).then(
            (res2) => {
              const response2 = res2.data.data;
              const mapGroups2 = response2?.map((item) => {
                return {
                  ...item,
                  groups: Object.values(
                    item.groups?.reduce(
                      (acc, { project_name, total_tenant, tenant_amount }) => {
                        if (!acc[project_name]) {
                          acc[project_name] = {
                            project_name,
                            amount: 0,
                            sum: 0,
                            count: 0,
                          };
                        }
                        acc[project_name].count += 1;
                        acc[project_name].sum += total_tenant;
                        acc[project_name].amount += tenant_amount;
                        return acc;
                      },
                      {}
                    )
                  ).sort((a, b) => b.sum - a.sum),
                };
              });
              mergeValue = mapGroups.map((item, index) => ({
                ...item,
                sum2: mapGroups2.find((e, i) => i === index)?.sum || 0,
              }));
              const max2 = Math.max(...response2.map((item) => item.sum));
              niceMaxValue2 = Math.ceil(max2 + max2 * 0.1);
            }
          );

          results.push({
            project_id: value.id,
            project_name: value.project_name,
            data_graph: mergeValue,
            max_graph:
              niceMaxValue2 > niceMaxValue
                ? niceMaxValue2
                : niceMaxValue > 100
                ? niceMaxValue
                : 100,
          });
        });
      }
      setDataFavProject(results);
      setLoadingFav(false);
    } else {
      setLoadingFav(false);
    }
  };
  const renderFilterBar = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px 20px 0px 20px",
          borderRadius: "10px",
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3.5}
          >
            <FieldSelect
              name={"แสดงตาม"}
              options={[
                { value: "year", name: "รายปี" },
                { value: "month", name: "รายเดือน" },
                { value: "week", name: "รายสัปดาห์" },
                { value: "day", name: "รายวัน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"day"}
              value={filterDashboard.type_data_set}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  type_data_set: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3.5}
          >
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterDashboard.start_date ?? firstDayOfMonth,
                end_date: filterDashboard.end_date ?? yesterday,
              }}
              style={{
                width: "-webkit-fill-available",
              }}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          {/* <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldSelect
              name={"ประเภทอุสาหกรรม"}
              options={[{ value: "all", name: "ทั้งหมด" }, ...allIndustry]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"all"}
              value={filterDashboard.industry_id}
              onChange={(val) => {
                const selectedOption = [
                  { value: "all", name: "ทั้งหมด" },
                  ...allIndustry,
                ].find((option) => option.value === val);
                setFilterDashboard({
                  ...filterDashboard,
                  industry_id: val,
                  industry_name: selectedOption ? selectedOption.name : "",
                });
              }}
              closePaddingBottom={true}
            />
          </Grid>*/}
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={5}
          >
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <div style={{ flex: 0.8, paddingRight: 10 }}>
                <FieldInput
                  value={filterDashboard.tenant_name ?? ""}
                  label={"ชื่อผู้เช่า"}
                  style={{ width: "-webkit-fill-available" }}
                  readOnly
                  closePaddingBottom
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 0.2,
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "secondary.main",
                  }}
                  onClick={() => {
                    setShowGetTenant(true);
                  }}
                >
                  <Search sx={{ color: "white", fontSize: 30 }} />
                </Avatar>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "white",
                  }}
                  onClick={() => {
                    setFilterDashboard({
                      ...filterDashboard,
                      truck_tenant_id: null,
                      tenant_name: null,
                    });
                    setAllTenantProject([]);
                  }}
                >
                  <CleaningServices
                    sx={{ color: "secondary.main", fontSize: 30 }}
                  />
                </Avatar>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map(
            (entry, index) =>
              !allTruckTenantProject.includes(entry.dataKey) && (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: entry.color,
                      marginRight: 5,
                      borderRadius: 50,
                    }}
                  ></div>
                  <span>
                    {entry.value == "sum"
                      ? "รายได้"
                      : entry.value === "sum2"
                      ? "รายได้เมื่อปีที่แล้ว"
                      : entry.value === "target"
                      ? "เป้าหมาย"
                      : entry.value == "sum_ftl"
                      ? "FTL"
                      : entry.value == "sum_stl"
                      ? "STL"
                      : entry.value}
                  </span>
                </div>
              )
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => setShowFav(!showFav)}
            style={{ cursor: "pointer", paddingRight: 20 }}
          >
            {showFav ? (
              <Star size={40} sx={{ color: "#8ba2bb" }} />
            ) : (
              <StarBorder size={40} sx={{ color: "#8ba2bb" }} />
            )}
          </div>
          <div
            onClick={() => setModalUploadTarget(true)}
            style={{ cursor: "pointer", paddingRight: 20 }}
          >
            <PiFlagPennantFill size={20} color="#8ba2bb" />
          </div>
        </div>
      </div>
    );
  };
  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "target"
                ? "เป้าหมาย : "
                : entry.dataKey == "sum2"
                ? "รายได้เมื่อปีที่แล้ว : "
                : entry.dataKey == "sum"
                ? "รายได้ : "
                : entry.dataKey == "sum_ftl"
                ? "FTL :"
                : entry.dataKey == "sum_stl"
                ? "STL :"
                : `${entry.dataKey} :`}
              {formatNumber(Number(entry.value).toFixed(2)) + " บาท"}
            </span>
          </div>
        ))}
      </div>
    );
  };
  const CustomBarShape = (props) => {
    const { x, y, width, height, fill, stroke } = props;
    const borderRadius = 5;
    const borderColor = stroke;
    const borderWidth = 1;
    if (height <= 0) {
      return null;
    }
    return (
      <path
        d={`
              M${x},${y + height} 
              L${x},${y + borderRadius} 
              Q${x},${y} ${x + borderRadius},${y} 
              L${x + width - borderRadius},${y} 
              Q${x + width},${y} ${x + width},${y + borderRadius} 
              L${x + width},${y + height} 
              Z
            `}
        fill={fill}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
    );
  };
  return (
    <Container>
      <SubMenu
        menus={subDashboard.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `งานเช่า` ? true : false,
          };
        })}
        breadcrumbs={breadcrumbs}
      />
      {renderFilterBar()}
      <div>
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "stretch", marginTop: "10px" }}
        >
          <Grid item xs={7.5}>
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    fontSize={18}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    รายได้
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container>
                    <Grid style={{ paddingRight: "20px" }} item xs={6}>
                      <FieldSelect
                        name={"แสดงผล"}
                        options={[
                          { value: "revenue", name: "รายได้" },
                          { value: "target", name: "เป้าหมาย" },
                          { value: "compare", name: "ช่วงนี้เมื่อปีที่แล้ว" },
                          { value: "projects", name: "โครงการ" },
                        ]}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                        value={filterDashboard.display}
                        defaultValue={[]}
                        multiSelect={true}
                        onChange={(val) =>
                          setFilterDashboard({
                            ...filterDashboard,
                            display: val,
                          })
                        }
                        closePaddingBottom={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <div
                    style={{ display: "flex", justifyContent: "end", gap: 10 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "end",
                          color: "primary.main",
                          fontSize: "14px",
                        }}
                      >
                        {"เป้าหมายสะสม : " +
                          formatNumber(allTarget.toFixed(2)) +
                          " บาท"}
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          color: "primary.main",
                          fontSize: "14px",
                        }}
                      >
                        รายได้สะสม :{" "}
                        {helper.addComma(allSum.toFixed(2)) + " บาท" ?? "-"}
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          fontSize: "14px",
                        }}
                      >
                        ส่วนต่าง :{" "}
                        {formatNumber((allSum - allTarget).toFixed(2)) +
                          " บาท" ?? "-"}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {helper.calculatePercentage(
                            Number(allTarget),
                            Number(allSum)
                          ) > 0 ? (
                            <FaArrowTrendUp color="#27CE88" size={40} />
                          ) : helper.calculatePercentage(
                              Number(allTarget),
                              Number(allSum)
                            ) < 0 ? (
                            <FaArrowTrendDown color="#FF316F" size={40} />
                          ) : (
                            "-"
                          )}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: 500,
                            color:
                              helper.calculatePercentage(
                                Number(allTarget),
                                Number(allSum)
                              ) > 0
                                ? "#27CE88"
                                : helper.calculatePercentage(
                                    Number(allTarget),
                                    Number(allSum)
                                  ) < 0
                                ? "#FF316F"
                                : "#FFC675",
                          }}
                        >
                          {allTarget && allSum
                            ? formatNumber(
                                helper.calculatePercentage(
                                  Number(allTarget),
                                  Number(allSum)
                                )
                              ) + " %"
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <ResponsiveContainer width="100%" height={430}>
                <ComposedChart
                  width={730}
                  height={434}
                  data={dataTruckRentalMerge}
                  onClick={(data) => {
                    if (data.activePayload) {
                      setBarData(data?.activePayload[0]?.payload);
                      if (data?.activeTooltipIndex > 0) {
                        setGraph1(
                          dataTruckRentalMerge[data?.activeTooltipIndex - 1]
                        );
                      } else {
                        setGraph1(null);
                      }
                      setGraph2(data?.activePayload[0]?.payload);
                    }
                  }}
                  margin={{
                    top: 30,
                    right: 50,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e1e9ea" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#e1e9ea" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey={"xname"}
                    style={{ fontSize: "10px", fontWeight: 500 }}
                  />
                  <YAxis
                    style={{ fontSize: "10px", fontWeight: 500 }}
                    tickFormatter={formatNumber}
                    domain={[0, maxValue]}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    contentStyle={{ zIndex: 999999 }}
                  />
                  <Legend content={<CustomLegend />} />
                  <CartesianGrid stroke="#f5f5f5" />
                  {filterDashboard.display.includes("target") && (
                    <Line
                      strokeWidth={2}
                      dataKey="target"
                      stroke={`rgba(255, 49, 111,0.5)`}
                      dot={false}
                      strokeDasharray="5 5"
                    />
                  )}
                  {filterDashboard.display.includes("compare") && (
                    <Area
                      dataKey="sum2"
                      stroke={`#729094`}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    >
                      <LabelList
                        dataKey="sum2"
                        position="top"
                        angle={-90}
                        fill={`#729094`}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          textAnchor: "start",
                        }}
                        formatter={helper.abbreviateNumber}
                      />
                    </Area>
                  )}
                  {filterDashboard.display.includes("revenue") && (
                    <Bar
                      dataKey="sum"
                      barSize={20}
                      fill={`rgba(36, 180, 237,0.5)`}
                      stroke={`#24b4ed`}
                      shape={<CustomBarShape />}
                    >
                      <LabelList
                        dataKey="label"
                        content={(props) => {
                          const { x, y, value, width } = props;
                          const lines = value.split("\n");
                          const offset = 40;
                          return (
                            <text
                              x={x}
                              y={y}
                              fill="#24b4ed"
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-90, ${x + 10}, ${y})`}
                            >
                              {lines.map((line, index) => (
                                <tspan
                                  key={index}
                                  x={x + 14}
                                  dy={index * 13}
                                  style={{
                                    fontSize: index == 1 ? "12px" : "14px",
                                  }}
                                >
                                  {helper.abbreviateNumber(line)}
                                </tspan>
                              ))}
                            </text>
                          );
                        }}
                      />
                    </Bar>
                  )}
                  {filterDashboard.display.includes("projects") &&
                    allTruckTenantProject?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Line
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="b"
                          // shape={<CustomBarShape />}
                        />
                      );
                    })}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item xs={4.5}>
            <Grid container spacing={1} sx={{ alignItems: "stretch" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ height: "100%", alignItems: "stretch" }}
                >
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>รายได้</p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              ประจํา
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {" " + barData?.xname + " "}
                            </p>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {helper.calculatePercentage(
                              Number(barData?.target),
                              Number(barData?.sum)
                            ) > 0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : helper.calculatePercentage(
                                Number(barData?.target),
                                Number(barData?.sum)
                              ) < 0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                              // <FaWindowMinimize color="#FFC675" size={40} />
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                helper.calculatePercentage(
                                  Number(barData?.target),
                                  Number(barData?.sum)
                                ) > 0
                                  ? "#27CE88"
                                  : helper.calculatePercentage(
                                      Number(barData?.target),
                                      Number(barData?.sum)
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {barData
                              ? helper.addComma(
                                  helper.calculatePercentage(
                                    Number(barData?.target),
                                    Number(barData?.sum)
                                  )
                                ) + " %"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Grid container sx={{ height: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div style={{ fontSize: 14 }}>
                                เป้าหมาย :{" "}
                                {formatNumber(
                                  Number(barData?.target).toFixed(2)
                                )}{" "}
                                บาท
                              </div>
                              <div style={{ fontSize: 14 }}>
                                รายได้ :{" "}
                                {formatNumber(Number(barData?.sum).toFixed(2))}
                                บาท
                              </div>
                              <div style={{ fontSize: 14 }}>
                                ส่วนต่าง :{" "}
                                {formatNumber(
                                  (
                                    Number(barData?.sum) -
                                    Number(barData?.target)
                                  ).toFixed(2)
                                ) + " บาท"}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              เปลี่ยนแปลง
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              จาก
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}{" "}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {graph1?.xname}
                            </p>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {helper.calculatePercentage(
                              graph1?.sum,
                              graph2?.sum
                            ) > 0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : helper.calculatePercentage(
                                graph1?.sum,
                                graph2?.sum
                              ) < 0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                Number(
                                  helper.calculatePercentage(
                                    graph1?.sum,
                                    graph2?.sum
                                  )
                                ) > 0
                                  ? "#27CE88"
                                  : Number(
                                      helper.calculatePercentage(
                                        graph1?.sum,
                                        graph2?.sum
                                      )
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {graph1 && graph2
                              ? helper.addComma(
                                  helper.calculatePercentage(
                                    graph1.sum,
                                    graph2.sum
                                  )
                                ) + " %"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Grid
                          container
                          justifyContent="space-between"
                          sx={{ height: "100%" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {!(graph2?.sum === 0 && graph1?.sum === 0)
                                  ? Number(graph2?.sum - graph1?.sum) > 0
                                    ? "สูงกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) < 0
                                    ? "ต่ำกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) === 0
                                    ? "เท่ากับช่วงก่อนหน้า"
                                    : "-"
                                  : "-"}
                              </div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graph1 && graph2
                                  ? helper.addComma(graph2.sum - graph1.sum) +
                                    " บาท"
                                  : "-"}
                              </div>
                              {/* <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graph1 && graph2
                                  ? formatNumber(
                                      (graph2?.amount - graph1?.amount).toFixed(
                                        2
                                      )
                                    ) + " หน่วย"
                                  : "-"}
                              </div> */}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ height: 360 }}>
                <Paper
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                    height: "100%",
                    boxShadow: "none",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontWeight: 500,
                    }}
                  >
                    อันดับโครงการที่ใช้งานมากที่สุด
                    <p style={{ margin: 0, lineHeight: 1 }}>
                      ประจำ
                      {filterDashboard.type_data_set === "day"
                        ? "วัน"
                        : filterDashboard.type_data_set === "week"
                        ? "สัปดาห์"
                        : filterDashboard.type_data_set === "month"
                        ? "เดือน"
                        : "ปี"}
                      {" " + barData?.xname + " "}
                    </p>
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <div style={{ fontWeight: 500 }}>ชื่อ</div>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>
                        จำนวน
                      </div>
                    </Grid> */}
                    <Grid item xs={4}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>
                        รายได้
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>%</div>
                    </Grid>
                  </Grid>

                  {barData?.groups?.map((row, index) => (
                    <Grid container key={index}>
                      <Grid item xs={6}>
                        <LightTooltip title={row.project_name}>
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: "14px",
                            }}
                          >
                            {row.project_name}
                          </div>
                        </LightTooltip>
                      </Grid>
                      {/* <Grid item xs={3}>
                        <div style={{ textAlign: "end", fontSize: "14px" }}>
                          {formatNumber(Number(row.amount).toFixed(2))}
                        </div>
                      </Grid> */}
                      <Grid item xs={4}>
                        <div style={{ textAlign: "end", fontSize: "14px" }}>
                          {helper.addComma(row.sum)}
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div style={{ textAlign: "end", fontSize: "14px" }}>
                          {helper.addComma(
                            (row.sum / Number(barData?.sum)) * 100
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showFav &&
        (loadingFav ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: 999,
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
          >
            <Loading />
          </div>
        ) : (
          <Grid
            container
            spacing={[1, 1]}
            sx={{ paddingTop: "8px", paddingBottom: "20px" }}
            alignItems="stretch"
          >
            {dataFavProject?.map((row, index) => (
              <Grid item xs={4} key={index}>
                <GraphFavorite
                  type="truck_tenant"
                  project_id={row.project_id}
                  data={row?.data_graph}
                  title={row?.project_name}
                  maxValueY={row?.max_graph}
                  typeDataSet={filterDashboard.type_data_set}
                  refresh={() => handleGetFavTenantProject()}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      <ModalWork
        close={() => {
          setShowGetTenant(false);
          setTenantList([]);
          //   setTotalProfile(0);
          setFilterTenant({
            ...filterTenant,
            search_val: "",
            page: 1,
            per_page: 3,
            total: 0,
          });
        }}
        open={showGetTenant}
        minHeight
        enableClose
      >
        <div style={{ padding: 20 }}>
          <div style={{ textAlign: "center" }}>รายชื่อผู้เช่า</div>
          <Line />
          <TextField
            id="search_val"
            variant="standard"
            style={{
              paddingTop: "16px",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={filterTenant.search_val}
            onChange={(val) => {
              setFilterTenant({
                ...filterTenant,
                search_val: val.target.value,
              });
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            color="secondary"
            onClick={() => {
              getTenant();
            }}
          >
            ค้นหา
          </Button>
          <div style={{ marginTop: 10 }}>
            {tenantList.map((ele, index) => {
              return (
                <div
                  key={"customer" + index}
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor:
                      filterDashboard.truck_tenant_id === ele.id
                        ? "#121f43"
                        : "",
                    color:
                      filterDashboard.truck_tenant_id === ele.id
                        ? "#121f43"
                        : "",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    console.log(ele);
                    setFilterDashboard({
                      ...filterDashboard,
                      truck_tenant_id: ele.id,
                      tenant_name: ele.company_name,
                    });
                  }}
                >
                  <div>{ele.tenant_code}</div>
                  <div style={{ fontSize: 20, fontWeight: 500 }}>
                    {ele.company_name}
                  </div>
                  <div>
                    {"สร้างบัญชีเมื่อ: " +
                      helper.momentDateTime(ele.created_at)}
                  </div>
                </div>
              );
            })}
            <Pagination
              pagination={{
                page: filterTenant.page,
                per_page: filterTenant.per_page,
                total: filterTenant.total,
              }}
              setPagination={(val) => {
                setFilterTenant({
                  ...filterTenant,
                  per_page: val.per_page,
                  page: val.page,
                });
              }}
            />
            {filterDashboard.truck_tenant_id && tenantList.length > 0 ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                color="secondary"
                onClick={async () => {
                  setShowGetTenant(false);
                  setTenantList([]);
                  setFilterTenant({
                    ...filterTenant,
                    search_val: "",
                    page: 1,
                    per_page: 3,
                    total: 0,
                  });
                }}
              >
                ยืนยัน
              </Button>
            ) : null}
          </div>
        </div>
      </ModalWork>
      {modalUploadTarget && (
        <ModalAddTargetTruckRental
          open={modalUploadTarget}
          handleClose={() => {
            setModalUploadTarget(false);
            getDataGraph();
          }}
        />
      )}
    </Container>
  );
};

export default TruckRentalDashboard;
