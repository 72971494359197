import React, { useState, useEffect, useRef } from "react";
import * as R from "ramda";
import { getArea } from "utils/api/profile/profile";
import { Typography, useTheme, CircularProgress } from "@mui/material";
import { TreeSelect } from "antd";
import { ArrowDropDown } from "@mui/icons-material";
import styles from "./FieldSelectArea.module.css";

const FieldSelectArea = ({
  levelArea = [],
  name = "",
  defaultValue = null,
  value = ``,
  onChange = () => {},
  showChild = false,
  closePaddingBottom = false,
  readOnly = false,
  valueType = "id",
  selectAll = false,
}) => {
  const theme = useTheme();
  const [selectAreas, setSelectAreas] = useState(defaultValue ?? []);
  const [areas, setAreas] = useState([]);
  const [listId, setListId] = useState({
    zone: [],
    province: [],
    district: [],
    subdistrict: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("defaultValue select area", areas, listId);
  }, [areas, listId]);

  useEffect(() => {
    handleGetArea();
  }, []);

  useEffect(() => {}, [areas]);

  const handleGetArea = () => {
    setIsLoading(true);

    const param = {
      provinces: levelArea?.includes("provinces") ? true : false,
      districts: levelArea?.includes("districts") ? true : false,
      subdistricts: levelArea?.includes("subdistricts") ? true : false,
    };

    getArea(param)
      .then((response) => {
        if (response.data.code === 1000) {
          let areas = response.data.data;
          initialListId(areas);
          let treeData = convertToTreeData(areas);
          setAreas(treeData);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const onChangeAreas = (newValue) => {
    setSelectAreas(newValue);
    const list_selected = {
      zone: newValue
        .filter((item) => {
          if (typeof item === `string`) {
            const zone_id = Number(item.split("z")[1]);
            return listId.zone.includes(zone_id);
          }
          return false;
        })
        .map((item) => {
          const zone_id = Number(item.split("z")[1]);
          return zone_id;
        }),
      province: newValue.filter((item) => listId.province.includes(item)),
      district: newValue.filter((item) => listId.district.includes(item)),
      subdistrict: newValue.filter((item) => listId.subdistrict.includes(item)),
    };

    onChange(newValue, list_selected);
  };

  const initialListId = (areas) => {
    let list_id = listId;
    areas.forEach((area) => {
      list_id = {
        ...list_id,
        zone: [...list_id.zone, area.id],
      };
      if (!R.isNil(area.provinces) && !R.isEmpty(area.provinces)) {
        area.provinces.forEach((province) => {
          list_id = {
            ...list_id,
            province: [...list_id.province, province.id],
          };

          if (!R.isNil(province.districts) && !R.isEmpty(province.districts)) {
            province.districts.forEach((district) => {
              list_id = {
                ...list_id,
                district: [...list_id.district, district.id],
              };

              if (
                !R.isNil(district.subdistricts) &&
                !R.isEmpty(district.subdistricts)
              ) {
                district.subdistricts.forEach((subdistrict) => {
                  list_id = {
                    ...list_id,
                    subdistrict: [...list_id.subdistrict, subdistrict.id],
                  };
                });
              }
            });
          }
        });
      }
    });

    setListId(list_id);
  };

  const convertToTreeData = (data, parentKey = "") => {
    return data.map((item) => {
      let key;
      if (parentKey === "") {
        key = "z" + item.id.toString();
      } else {
        key = item.id;
      }

      const treeItem = {
        key: key,
        value: valueType === "name" ? item.name : key,
        title: item.name,
      };
      if (item.provinces && item.provinces.length > 0) {
        treeItem.children = convertToTreeData(item.provinces, key);
      }
      if (item.districts && item.districts.length > 0) {
        treeItem.children = convertToTreeData(item.districts, key);
      }
      if (item.subdistricts && item.subdistricts.length > 0) {
        treeItem.children = convertToTreeData(item.subdistricts, key);
      }
      return treeItem;
    });
  };

  return (
    <div
      style={{
        width: "100%",
        paddingBottom: closePaddingBottom ? 0 : "23px",
        height: closePaddingBottom ? "48px" : "71px",
        pointerEvents: readOnly ? "none" : "",
      }}
    >
      <Typography
        sx={{
          paddingLeft: "14px",
          fontSize: "12px",
          height: "14px",
          color: theme.palette.grey.main,
          backgroundColor: readOnly ? "rgba(140, 163, 186, 0.20)" : "#ffffff",
        }}
      >
        {value.length > 0 || selectAreas.length > 0 ? name : ""}
      </Typography>

      <TreeSelect
        treeData={areas}
        value={value ? value : selectAreas}
        onChange={onChangeAreas}
        treeCheckable={true}
        bordered={false}
        maxTagCount={"responsive"}
        showCheckedStrategy={
          showChild === true ? TreeSelect.SHOW_CHILD : TreeSelect.SHOW_PARENT
        }
        placeholder={<div style={{ paddingBottom: "10px" }}>{name}</div>}
        style={{
          width: "100%",
          fontSize: "16px",
          fontWeight: "500",
          maxHeight: "400px",
          height: "34px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          paddingTop: value.length > 0 || selectAreas.length > 0 ? 0 : "12px",
          backgroundColor: readOnly ? "rgba(140, 163, 186, 0.20)" : "#ffffff",
        }}
        suffixIcon={
          <ArrowDropDown
            sx={{ color: "grey.main", left: "-15px", position: "absolute" }}
          />
        }
        dropdownRender={(menu) =>
          isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            menu
          )
        }
        dropdownStyle={{
          zIndex: 9999,
          // height: "400px",
          overflow: "auto",
        }}
      />
    </div>
  );
};

export default FieldSelectArea;
