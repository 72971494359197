import {
  Avatar,
  Container,
  InputAdornment,
  TextField,
  Typography,
  Grid,
  Modal,
  Divider,
  Box,
} from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import LoadingBackground from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import styles from "./EditWorkTransactionCarrier.module.css";
import FieldSelect from "components/field/FieldSelect";
import { Search, UploadFile } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { subMenuFinance } from "utils/submenuoptions";
import { Controller, useForm } from "react-hook-form";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import FieldNumber from "components/field/FieldNumber";
import FieldUpload from "components/field/FieldUpload";
import {
  editWorkTransactionCarrier,
  getCarrierTransactionLog,
  getWorkTransactionCarrier,
} from "utils/api/finance/carrier";
import helper from "utils/helper";
import { getAllBank } from "utils/api/masterData/service";
import TableCustom from "components/table/TableCustom";
import LightTooltip from "components/tooltip/LightTooltip";
import FieldTextArea from "components/field/FieldTextArea";
import AuthContext from "components/context/AuthContext";
import { useNavigate } from "react-router-dom";

const EditWorkTransactionCarrier = () => {
  const { adminAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const breadcrumbs = [
    {
      label: "แก้ไขการเงินผู้ขนส่ง",
      path: null,
    },
  ];

  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [hasNoRole, setHasNoRole] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState({
    open: false,
    data: null,
  });
  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    search_by: "",
    search_val: "",
    page: 1,
    per_page: 100,
    total: 0,
  });
  const [filterLog, setFilterLog] = useState({
    order_by: "actual_date",
    order_by_value: -1,
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    search_by: "",
    search_val: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm();

  useEffect(() => {
    console.log("openModalUpdate", openModalUpdate.data);
    if (openModalUpdate.data) {
      Object.keys(openModalUpdate.data).forEach((fieldName) => {
        if (
          fieldName === "carrier_freight_before_tax" ||
          fieldName === "carrier_freight_net" ||
          fieldName === "carrier_freight_tax" ||
          fieldName === "carrier_insurance_per_trip" ||
          fieldName === "carrier_penalty" ||
          fieldName === "amount_of_transfer_actual"
        ) {
          const formatting = helper.formatNumeric(
            openModalUpdate.data[fieldName],
            "display"
          );
          setValue(fieldName, formatting);
        } else {
          setValue(fieldName, openModalUpdate.data[fieldName]);
        }
      });
      setValue("detail", "");
    }
  }, [openModalUpdate.data]);

  useEffect(() => {
    getBank();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      if (
        adminAuth?.admin_role_type === 0 ||
        adminAuth?.admin_role_type === 4
      ) {
        getAllTransactionLog(filterLog);
      } else {
        setHasNoRole(true);
      }
    }
  }, [filterLog.page, filterLog.per_page, adminAuth]);

  useEffect(() => {
    getAllTransactionLog(filterLog);
  }, [filterLog.page, filterLog.per_page]);

  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBankOptions(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filterForEdit = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center" }} fontWeight={"bold"}>
          ค้นหาเอกสารที่ต้องการแก้ไข
        </Typography>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_topic"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={1}
            lg={1}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterTransaction = { ...filter, page: 1 };
                filterTransaction.search_by !== "" &&
                  filterTransaction.search_val !== "" &&
                  getAllTransaction(filterTransaction);
              }}
            >
              <Search
                sx={{
                  color:
                    filter.search_by !== "" && filter.search_val !== ""
                      ? "secondary.main"
                      : "grey.main",
                  fontSize: 35,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  const filterLogBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center" }} fontWeight={"bold"}>
          ค้นหาประวัติการแก้ไขเอกสาร
        </Typography>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"task_topic"}
              value={filterLog.search_by}
              onChange={(val) => setFilterLog({ ...filterLog, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={5.5} lg={5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterLog.search_val ? filterLog.search_val : ""}
              onChange={(val) =>
                setFilterLog({ ...filterLog, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={1}
            lg={1}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterTransactionLog = { ...filterLog, page: 1 };
                filterTransactionLog.search_by !== "" &&
                  filterTransactionLog.search_val !== "" &&
                  getAllTransactionLog(filterTransactionLog);
              }}
            >
              <Search
                sx={{
                  color:
                    filterLog.search_by !== "" && filterLog.search_val !== ""
                      ? "secondary.main"
                      : "grey.main",
                  fontSize: 35,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderFormUpdateInvoice = () => {
    return (
      <Box>
        <Controller
          control={control}
          name="actual_date"
          render={({ field: { onChange, value } }) => (
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการตั้งเบิก *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={value}
              onChange={onChange}
              showTime
              error={errors.actual_date}
              helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="work_ids"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมจำนวนงาน"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
              error={errors.works_ids}
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_before_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_insurance_per_trip"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าประกันภัยสินค้ารายเที่ยว"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_penalty"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าปรับ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_net"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="invoice_file"
          render={({ field: { onChange, value } }) => (
            <FieldUpload
              name={"ใบแจ้งหนี้จากผู้ขนส่ง*"}
              icon={<UploadFile />}
              value={value}
              accept="image/png, image/jpeg, application/pdf"
              onChange={onChange}
              error={errors.file}
              helperText={errors.file ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="detail"
          rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
          render={({ field: { onChange, value } }) => (
            <FieldTextArea
              className="w-100"
              placeholder="เหตุผลในการแก้ไข"
              value={value}
              onChange={onChange}
              error={errors.detail}
              errorMessage={errors.detail?.message}
            />
          )}
        />

        <div style={{ paddingTop: "20px", display: "flex" }}>
          <div style={{ flex: 0.5 }}>
            <ButtonCustom
              name={"ยกเลิก"}
              onClick={() => setOpenModalUpdate({ open: false, data: null })}
              type={"cancel"}
              fullWidth={true}
            />
          </div>

          <div style={{ flex: 0.5 }}>
            <ButtonCustom name={"บันทึก"} type={"submit"} fullWidth={true} />
          </div>
        </div>
      </Box>
    );
  };

  const renderFormUpdateBilling = () => {
    return (
      <Box>
        <Controller
          control={control}
          name="actual_date"
          render={({ field: { onChange, value } }) => (
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการเตรียมโอน *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={value}
              onChange={onChange}
              showTime
              error={errors.actual_date}
              helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="work_ids"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมจำนวนงาน"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
              error={errors.works_ids}
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_before_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_insurance_per_trip"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าประกันภัยสินค้ารายเที่ยว"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_penalty"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าปรับ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_net"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="bill_file"
          render={({ field: { onChange, value } }) => (
            <FieldUpload
              name={"ใบวางบิลจากผู้ขนส่ง*"}
              icon={<UploadFile />}
              value={value}
              accept="image/png, image/jpeg, application/pdf"
              onChange={onChange}
              error={errors.file}
              helperText={errors.file ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="detail"
          rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
          render={({ field: { onChange, value } }) => (
            <FieldTextArea
              className="w-100"
              placeholder="เหตุผลในการแก้ไข"
              value={value}
              onChange={onChange}
              error={errors.detail}
              errorMessage={errors.detail?.message}
            />
          )}
        />

        <div style={{ paddingTop: "20px", display: "flex" }}>
          <div style={{ flex: 0.5 }}>
            <ButtonCustom
              name={"ยกเลิก"}
              onClick={() => setOpenModalUpdate({ open: false, data: null })}
              type={"cancel"}
              fullWidth={true}
            />
          </div>

          <div style={{ flex: 0.5 }}>
            <ButtonCustom name={"บันทึก"} type={"submit"} fullWidth={true} />
          </div>
        </div>
      </Box>
    );
  };

  const renderFormUpdateReceipt = () => {
    return (
      <Box>
        <Controller
          control={control}
          name="actual_date"
          render={({ field: { onChange, value } }) => (
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการแจ้งโอน*"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={value}
              onChange={onChange}
              showTime
              error={errors.actual_date}
              helperText={errors.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="work_ids"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมจำนวนงาน"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
              error={errors.works_ids}
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_before_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_tax"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_insurance_per_trip"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าประกันภัยสินค้ารายเที่ยว"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_penalty"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมค่าปรับ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="carrier_freight_net"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="รวมราคาผู้ขนส่งสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              readOnly
            />
          )}
        />
        <Controller
          control={control}
          name="amount_of_transfer_actual"
          render={({ field: { onChange, value } }) => (
            <FieldInput
              className="w-100"
              label="จำนวนเงินที่ชำระจริง"
              subLabel="(ผลรวมจากการเลือก)"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="bank_id"
          render={({ field: { onChange, value } }) => (
            <FieldSelect
              className="w-100"
              name={`ธนาคารที่ชำระ*`}
              options={bankOptions}
              value={value}
              onChange={onChange}
              error={errors.bank_id}
              errorText={errors.bank_id ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="file"
          render={({ field: { onChange, value } }) => (
            <FieldUpload
              name={"pay-in*"}
              icon={<UploadFile />}
              value={value}
              accept="image/png, image/jpeg, application/pdf"
              onChange={onChange}
              error={errors.file}
              helperText={errors.file ? "จำเป็นต้องระบุ" : ""}
            />
          )}
        />
        <Controller
          control={control}
          name="detail"
          rules={{ required: "กรุณากรอกเหตุผลในการแก้ไข" }}
          render={({ field: { onChange, value } }) => (
            <FieldTextArea
              className="w-100"
              placeholder="เหตุผลในการแก้ไข"
              value={value}
              onChange={onChange}
              error={errors.detail}
              errorMessage={errors.detail?.message}
            />
          )}
        />

        <div style={{ paddingTop: "20px", display: "flex" }}>
          <div style={{ flex: 0.5 }}>
            <ButtonCustom
              name={"ยกเลิก"}
              onClick={() => setOpenModalUpdate({ open: false, data: null })}
              type={"cancel"}
              fullWidth={true}
            />
          </div>

          <div style={{ flex: 0.5 }}>
            <ButtonCustom name={"บันทึก"} type={"submit"} fullWidth={true} />
          </div>
        </div>
      </Box>
    );
  };
  const getAllTransaction = async (_filter) => {
    try {
      setLoading(true);
      const res = await getWorkTransactionCarrier(_filter);
      if (res.data.code === 1000) {
        const result = res.data.data;
        console.log("result", result);
        if (result.length > 0) {
          setOpenModalUpdate({
            open: true,
            data: { ...result[0] },
          });
          //   getReceiptNameOptions(
          //     result[0].shipper_id,
          //     result[0].profile_receipt_name_id
          //   );
        } else {
          setErrorAlert({
            open: true,
            desc:
              filter.search_by === "invoice_code"
                ? "ไม่พบเลขที่ใบแจ้งหนี้"
                : filter.search_by === "bill_code"
                ? "ไม่พบเลขที่ใบวางบิล"
                : filter.search_by === "receipt_code"
                ? "ไม่พบเลขที่ใบเสร็จ"
                : "",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.data.code === 4106) {
        setErrorAlert({
          open: true,
          desc: error.response.data.description,
        });
      } else if (error.response.data.code === "4105") {
        removeAccessToken("access_token");
        window.location.reload();
      }
    }
  };

  const getAllTransactionLog = async (_filter) => {
    try {
      setLoading(true);
      const res = await getCarrierTransactionLog(_filter);
      if (res.data.code === 1000) {
        const result = res.data.data;
        setDataSource(result);
        setFilterLog({ ...filterLog, total: res.data.total });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.data.code === 4106) {
        setErrorAlert({
          open: true,
          desc: error.response.data.description,
        });
      } else if (error.response.data.code === "4105") {
        removeAccessToken("access_token");
        window.location.reload();
      }
    }
  };

  const onSubmitInvoice = async (data) => {
    console.log("onSubmitInvoice", data);
    setLoading(true);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append("detail", data.detail);

    if (data.invoice_file !== openModalUpdate.data.invoice_file) {
      formData.append("invoice_file", data.invoice_file);
    }

    await editWorkTransactionCarrier("invoice", data.invoice_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const onSubmitBilling = async (data) => {
    console.log("onSubmitBilling", data);
    setLoading(true);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append("detail", data.detail);

    if (data.bill_file !== openModalUpdate.data.bill_file) {
      formData.append("bill_file", data.bill_file);
    }

    await editWorkTransactionCarrier("bill", data.bill_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const onSubmitReceipt = async (data) => {
    console.log("onSubmitReceipt", data);
    setLoading(true);
    const formData = new FormData();
    formData.append("actual_date", data.actual_date);
    formData.append(
      "amount_of_transfer_actual",
      data.amount_of_transfer_actual
    );
    formData.append("bank_id", data.bank_id);
    formData.append("detail", data.detail);

    if (data.file !== openModalUpdate.data.file) {
      formData.append("file", data.file);
    }

    await editWorkTransactionCarrier("receipt", data.pay_in_code, formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          setOpenModalUpdate({ open: false, data: null });
          setSuccessAlert(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const columns = [
    {
      id: "actual_date",
      label: "วันที่ทำรายการ",
      align: "center",
      //   width: "13%",
      format: (value) =>
        helper.ToConvertDateTimeForTable(value.actual_date, false),
    },
    {
      id: "document",
      label: "เลขที่เอกสาร",
      align: "center",
      //   width: "13%",
      format: (value) =>
        value.payment_carrier_status_id === 3 ||
        value.payment_carrier_status_id === 4
          ? value.invoice_code
          : value.payment_carrier_status_id === 5 ||
            value.payment_carrier_status_id === 6
          ? value.bill_code
          : value.payment_carrier_status_id === 7 ||
            value.payment_carrier_status_id === 8
          ? value.pay_in_code
          : "",
    },
    {
      id: "payment_carrier_status_name",
      label: "สถานะเอกสาร",
      align: "center",
    },
    {
      id: "carrier_name",
      label: "ชื่อโปรไฟล์ผู้ขนส่ง",
      align: "center",
    },
    {
      id: "carrier_profile_type",
      label: "ประเภทโปรไฟล์",
      align: "center",
      format: (value) =>
        value.carreir_profile_type === "0" ? "บุคคลทั่วไป" : "นิติบุคคล",
    },
    {
      id: "carrier_payment_type",
      label: "ประเภทการชำระเงิน",
      align: "center",
      format: (value) =>
        value.carrier_payment_type === "0" ? "เงินสด" : "เครดิต",
    },
    {
      id: "carrier_freight_before_tax",
      label: "ราคาลูกค้าก่อนหักภาษี",
      align: "center",
      format: (value) => helper.addComma(value.carrier_freight_before_tax),
    },
    {
      id: "carrier_freight_tax",
      label: "ภาษีหัก ณ ที่จ่าย",
      align: "center",
      format: (value) => helper.addComma(value.carrier_freight_tax),
    },
    {
      id: "carrier_freight_net",
      label: "ราคาลูกค้าสุทธิ",
      align: "center",
      format: (value) => helper.addComma(value.carrier_freight_net),
    },
    {
      id: "amount_of_transfer_actual",
      label: "จำนวนเงินที่ชำระจริง",
      align: "center",
      format: (value) => helper.addComma(value.amount_of_transfer_actual),
    },
    {
      id: "detail",
      label: "เหตุผลในการแก้ไข",
      align: "center",
      format: (value) => (
        <LightTooltip title={value.detail}>
          <div className={styles.ellipsisDetail}>{value.detail}</div>
        </LightTooltip>
      ),
    },

    {
      id: "admin",
      label: "สร้างโดย",
      align: "center",
      format: (value) => value.admin_firstname + " " + value.admin_lastname,
    },
  ];

  return (
    <div>
      {loading && <LoadingBackground />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          getAllTransactionLog({ ...filterLog, page: 1 });
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
        }}
      />
      <AlertModal
        open={hasNoRole}
        title="เกิดข้อผิดพลาด"
        description={"ไม่มีสิทธ์ในการเข้าถึง"}
        onClose={() => {
          navigate("/");
          setHasNoRole(false);
        }}
      />
      <Container>
        <SubMenu
          menus={subMenuFinance.map((menu) => {
            return {
              label: menu?.label,
              path: menu?.path,
              active: menu?.label === `แก้ไขการเงินผู้ขนส่ง` ? true : false,
              header: menu?.header,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={"/"}
        />
        <div style={{ paddingTop: "20px" }}></div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {filterForEdit()}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {filterLogBar()}
          </Grid>
        </Grid>
        <div>
          <TableCustom
            columns={columns}
            dataSource={dataSource}
            pagination={filterLog}
            setPagination={(val) => {
              setFilterLog({
                ...filterLog,
                ...val,
              });
            }}
            hrefDisabled
          />
        </div>
      </Container>
      {
        <Modal
          open={openModalUpdate?.open}
          onClose={(event, reason) =>
            reason !== "backdropClick"
              ? setOpenModalUpdate({ open: false, data: null })
              : ""
          }
          sx={{ zIndex: 1000 }}
        >
          <div className={styles.modalStyle}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon
                sx={{ color: "grey.main" }}
                onClick={() => {
                  setOpenModalUpdate({ open: false, data: null });
                }}
              />
            </div>
            <div
              style={{
                fontSize: 20,
                fontWeight: 400,
                textAlign: "center",
                fontFamily: "Kanit",
              }}
            >
              {openModalUpdate?.data?.payment_carrier_status_id === 3
                ? "แก้ไขข้อมูลใบแจ้งหนี้"
                : openModalUpdate?.data?.payment_carrier_status_id === 5
                ? "แก้ไขข้อมูลใบวางบิล"
                : "แก้ไขข้อมูลใบเสร็จ"}
            </div>
            <Divider />
            <div style={{ paddingTop: 23 }}>
              <form
                className="form-updatestatus"
                autoComplete="off"
                onSubmit={handleSubmit(
                  openModalUpdate?.data?.payment_carrier_status_id === 3
                    ? onSubmitInvoice
                    : openModalUpdate?.data?.payment_carrier_status_id === 5
                    ? onSubmitBilling
                    : onSubmitReceipt
                )}
              >
                {openModalUpdate?.data?.payment_carrier_status_id === 3
                  ? renderFormUpdateInvoice()
                  : openModalUpdate?.data?.payment_carrier_status_id === 5
                  ? renderFormUpdateBilling()
                  : renderFormUpdateReceipt()}
              </form>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default EditWorkTransactionCarrier;
