import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Avatar,
  Box,
  Paper,
} from "@mui/material";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getDashboardKPIDelivery,
  getDashboardKPIDocument,
  getDashboardKPIIn24hr,
  getDashboardKPIInbound,
  getDashboardKPIMatching,
  getDashboardWork,
  getDashboardWorkByProfile,
  getDataGraphByGroup,
  getProfileByGrade,
} from "utils/api/dashboard/newDashboard";
import helper from "utils/helper";
import { getAllUser as getAllUserAPI } from "utils/api/user/user";
import ModalWork from "components/work/modalWork";
import {
  Search,
  CleaningServices,
  Star,
  StarBorder,
  DonutLarge,
} from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import Pagination from "components/pagination/Pagination";
import LightTooltip from "components/tooltip/LightTooltip";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import GraphKPI from "components/dashboard/GraphKPI";
import { ProjectFrequencyType } from "utils/enum";
import Loading from "components/loading/Loading";
import GraphFavorite from "components/dashboard/GraphFavorite";
import { PiFlagPennantFill } from "react-icons/pi";
import ModalAddTarget from "components/dashboard/ModalAddTarget";
import FieldSelectArea from "components/field/FieldSelectArea";
import GraphByTruckGroup from "components/dashboard/GraphByTruckGroup";
import { getAllTruck, getTruck } from "utils/api/truck/truck";
import { getTruckType } from "utils/api/masterData/truckType";
import { getAllZone } from "utils/api/masterData/gis";
const NewDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dataWork, setDataWork] = useState([]);
  const [dataWorkMerge, setDataWorkMerge] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [barData, setBarData] = useState(null);
  const [barDataCount, setBarDataCount] = useState(null);
  const [showGetProfile, setShowGetProfile] = useState(false);
  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");

  const [profileList, setProfileList] = useState([]);
  const [totalProfile, setTotalProfile] = useState(0);
  const [optionCalculate, setOptionCalculate] = useState([]);
  const [optionCalculate2, setOptionCalculate2] = useState([]);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [graphCount1, setGraphCount1] = useState(null);
  const [graphCount2, setGraphCount2] = useState(null);
  const [allIncome, setAllIncome] = useState(0);
  const [allWork, setAllWork] = useState(0);
  const [allTarget, setAllTarget] = useState(0);
  const [visibleTarget, setVisibleTarget] = useState(true);
  const [dataGraphInbound, setDataGraphInbound] = useState(null);
  const [dataGraphDelivery, setDataGraphDelivery] = useState(null);
  const [dataGraphDocument, setDataGraphDocument] = useState(null);
  const [dataGraphIn24hr, setDataGraphIn24hr] = useState(null);
  const [dataGraphMatching, setDataGraphMatching] = useState(null);
  const [dataTotalInbound, setDataTotalInbound] = useState(null);
  const [dataTotalDelivery, setDataTotalDelivery] = useState(null);
  const [dataTotalDocument, setDataTotalDocument] = useState(null);
  const [dataTotalIn24hr, setDataTotalIn24hr] = useState(null);
  const [dataTotalMatching, setDataTotalMatching] = useState(null);
  const [dataFavShipper, setDataFavShipper] = useState([]);
  const [dataGraphByGroup, setDataGraphByGroup] = useState([]);
  const [showFav, setShowFav] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const [modalUploadTarget, setModalUploadTarget] = useState(false);
  const [masterTruck, setMasterTruck] = useState([]);
  const [masterOrigin, setMasterOrigin] = useState([]);
  const [masterDestination, setMasterDestination] = useState([]);
  const [masterCountTruck, setMasterCountTruck] = useState([]);
  const [masterCountOrigin, setMasterCountOrigin] = useState([]);
  const [masterCountDestination, setMasterCountDestination] = useState([]);
  const [filterDashboard, setFilterDashboard] = useState({
    type_data_set: "day",
    start_date: firstDayOfMonth,
    end_date: yesterday,
    on_dashboard: true,
    project_type: "all",
    display: ["revenue", "target"],
    display_count: ["count"],
  });
  const [filterProfile, setFilterProfile] = useState({
    sort_by: "updated_at",
    search_by: "company_name",
    search_val: "",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 3,
    total: 0,
    is_main: [true],
    profile_type: [],
    role: ["shipper"],
    active: ["true"],
  });
  const [filterGraphLoc, setFilterGraphLoc] = useState({
    origin: [],
    destination: [],
    distance_range: ">400 km",
    greater_than: 1,
  });
  console.log("masterTruck", masterTruck, masterDestination);
  console.log("barDataCount", barDataCount);
  console.log("dataFavShipper", dataFavShipper);
  const subDashboard = [
    {
      label: "งานขนส่ง",
      path: "/dashboard/work",
    },
    {
      label: "งานซื้อขาย",
      path: "/dashboard/trade",
    },
    {
      label: "งานเช่า",
      path: "/dashboard/truck-rental",
    },
    {
      label: "การเงิน",
      path: "/dashboard/finance",
    },
  ];
  const breadcrumbs = [
    {
      label: "งานขนส่ง",
      path: null,
    },
  ];

  console.log("dataWorkMerge", dataWorkMerge);
  // useEffect(() => {
  //   if (dataWork) {
  //     const optionCalculate = dataWork.map((item) => {
  //       return {
  //         value: { sum: item.sum, name: item.xname, count: item.count },
  //         name: item.xname,
  //       };
  //     });
  //     setOptionCalculate(optionCalculate);
  //     setLastestBar(dataWork[dataWork.length - 1]);
  //   }
  // }, [dataWork]);

  //default graph
  // useEffect(() => {
  //   handleFirstDropdownChange(
  //     optionCalculate[optionCalculate.length - 2]?.value
  //   );
  //   setGraph2(optionCalculate[optionCalculate.length - 1]?.value);
  // }, [optionCalculate]);

  // const handleFirstDropdownChange = (value) => {
  //   setGraph1(value);
  //   setGraph2(null);
  //   const selectedIndex = optionCalculate?.findIndex(
  //     (option) => option?.name === value?.name
  //   );
  //   console.log("selectedIndex", selectedIndex);
  //   const newFilteredOptions = optionCalculate.slice(selectedIndex + 1);
  //   console.log("newFilteredOptions", newFilteredOptions);
  //   setOptionCalculate2(newFilteredOptions);
  // };

  useEffect(() => {
    const fetchData = async () => {
      await getDataCount();
      await getDataGraphInbound();
      await getDataGraphDelivery();
      await getDataGraphDocument();
      await getDataGraphIn24hr();
      await getDataGraphMatching();
      if (showFav) {
        await handleGetFav();
      }
    };
    fetchData();
  }, [filterDashboard]);

  useEffect(() => {
    handleGetDataGraphByGroup();
  }, [filterDashboard, filterGraphLoc]);

  useEffect(() => {
    if (dataWork) {
      getDataCountYearAgo();
    }
  }, [dataWork]);

  useEffect(() => {
    if (showFav) {
      handleGetFav();
    } else {
      setDataFavShipper([]);
    }
  }, [showFav]);

  useEffect(() => {
    getTruckMaster();
    getZone();
  }, []);

  const getRandomRGBA = (alpha = 0.8) => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.random() * 20;
    const lightness = 50 + Math.random() * 10;

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
      s /= 100;
      l /= 100;
      const k = (n) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

      return [
        Math.round(f(0) * 255),
        Math.round(f(8) * 255),
        Math.round(f(4) * 255),
      ];
    };

    const [r, g, b] = hslToRgb(hue, saturation, lightness);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Add transparency
  };

  const getTruckMaster = async () => {
    await getTruckType({ sort_field: "order_number", sort_order: 1 }).then(
      (res) => {
        if (res.data.code === 1000) {
          const master = res.data.data.results.map((val) => {
            return val.name;
          });
          const count_truck = res.data.data.results.map((val) => {
            return `จำนวนงาน ${val.name}`;
          });
          setMasterTruck(master);
          setMasterCountTruck(count_truck);
        }
      }
    );
  };
  const getZone = async () => {
    await getAllZone({ sort_field: "id", sort_order: 1 }).then((res) => {
      if (res.data.code === 1000) {
        const origin = res.data.data.results.map((val) => {
          return `ต้นทาง ${val.name}`;
        });
        const destination = res.data.data.results.map((val) => {
          return `ปลายทาง ${val.name}`;
        });
        const count_origin = res.data.data.results.map((val) => {
          return `จำนวนงานต้นทาง ${val.name}`;
        });
        const count_destination = res.data.data.results.map((val) => {
          return `จำนวนงานปลายทาง ${val.name}`;
        });
        setMasterOrigin(origin);
        setMasterDestination(destination);
        setMasterCountOrigin(count_origin);
        setMasterCountDestination(count_destination);
      }
    });
  };

  const getDataCount = async (fil = filterDashboard) => {
    let _filter = { ...fil };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardWork(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { company_name, shipper_freight_before_tax }) => {
                if (!acc[company_name]) {
                  acc[company_name] = {
                    company_name,
                    count: 0,
                    income: 0,
                  };
                }
                acc[company_name].count += 1;
                acc[company_name].income += shipper_freight_before_tax;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
          truck: Object.values(
            item.truck?.reduce((acc, { value, name, count }) => {
              acc[name] = {
                name,
                value: parseFloat(value),
                count: count,
              };
              return acc;
            }, {})
          ),
          zone_origin: Object.values(
            item.zone_origin?.reduce((acc, { value, name, count }) => {
              acc[name] = {
                name,
                value: parseFloat(value),
                count: count,
              };
              return acc;
            }, {})
          ),
          zone_destination: Object.values(
            item.zone_destination?.reduce((acc, { value, name, count }) => {
              acc[name] = {
                name,
                value: parseFloat(value),
                count: count,
              };
              return acc;
            }, {})
          ),
          ...item.truck.reduce((acc, curr) => {
            acc[curr.name] = parseFloat(curr.value);
            return acc;
          }, {}),
          ...item.truck.reduce((acc, curr) => {
            acc[`จำนวนงาน ${curr.name}`] = curr.count;
            return acc;
          }, {}),
          ...item.zone_origin.reduce((acc, curr) => {
            acc[`ต้นทาง ${curr.name}`] = parseFloat(curr.value);
            return acc;
          }, {}),
          ...item.zone_origin.reduce((acc, curr) => {
            acc[`จำนวนงานต้นทาง ${curr.name}`] = curr.count;
            return acc;
          }, {}),
          ...item.zone_destination.reduce((acc, curr) => {
            acc[`ปลายทาง ${curr.name}`] = parseFloat(curr.value);
            return acc;
          }, {}),
          ...item.zone_destination.reduce((acc, curr) => {
            acc[`จำนวนงานปลายทาง ${curr.name}`] = curr.count;
            return acc;
          }, {}),
          service: [
            {
              value: parseFloat(item.sum_ftl),
              name: "FTL",
              count: item.count_ftl,
            },
            {
              value: parseFloat(item.sum_stl),
              name: "STL",
              count: item.count_stl,
            },
          ],
        };
      });

      const totalIncome = response.reduce(
        (sum, item) => sum + Number(item.sum),
        0
      );
      const totalTarget = response.reduce(
        (sum, item) => sum + Number(item.target),
        0
      );
      const totalWork = response.reduce((sum, item) => sum + item.count, 0);

      setDataWork(mapGroups);
      setBarData(mapGroups[mapGroups.length - 1]);
      setGraph1(mapGroups[mapGroups.length - 2]);
      setGraph2(mapGroups[mapGroups.length - 1]);
      setBarDataCount(mapGroups[mapGroups.length - 1]);
      setGraphCount1(mapGroups[mapGroups.length - 2]);
      setGraphCount2(mapGroups[mapGroups.length - 1]);
      setAllIncome(totalIncome);
      setAllWork(totalWork);
      setAllTarget(totalTarget);
      const max = Math.max(...response.map((item) => item.sum));
      //   const niceMaxValue = Math.ceil((max + max * 0.1) / 10000) * 10000;

      const niceMaxValue = Math.ceil(max + max * 0.1);
      setMaxValue(niceMaxValue);
    });
  };
  const getDataCountYearAgo = async (fil = filterDashboard) => {
    console.log("getDataCountYearAgo");
    let _filter = {
      ...fil,
      start_date: moment(fil.start_date).subtract(1, "year"),
      end_date: moment(fil.end_date).subtract(1, "year"),
    };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardWork(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { company_name, shipper_freight_before_tax }) => {
                if (!acc[company_name]) {
                  acc[company_name] = {
                    company_name,
                    count: 0,
                    income: 0,
                  };
                }
                acc[company_name].count += 1;
                acc[company_name].income += shipper_freight_before_tax;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
      });

      console.log("before newWork", dataWork);
      const newWork = dataWork.map((item, index) => ({
        ...item,
        sum2: mapGroups.find((e, i) => i === index)?.sum || 0,
        count2: mapGroups.find((e, i) => i === index)?.count || 0,
      }));
      console.log("newWork", newWork);
      const max = Math.max(...response.map((item) => item.sum));
      const niceMaxValue = Math.ceil(max + max * 0.1);
      filterDashboard.display.includes("compare") &&
        setMaxValue((prev) => (prev > niceMaxValue ? prev : niceMaxValue));
      setDataWorkMerge(newWork);
    });
  };
  const getDataGraphInbound = async (fil = filterDashboard) => {
    let _filter = { ...fil };
    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    // delete _filter.project_type;
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardKPIInbound(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
          label:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
        };
      });
      const mapGroupsAll = response?.reduce((acc, item) => {
        return {
          kpi_ontime: (acc.kpi_ontime || 0) + (item.kpi_ontime || 0),
          kpi_delay: (acc.kpi_delay || 0) + (item.kpi_delay || 0),
          count: (acc.count || 0) + (item.count || 0),
          target: item.target,
        };
      }, {});

      const calculatePercentage = {
        ...mapGroupsAll,
        xname: "Inbound",
        percentage:
          mapGroupsAll.count !== 0
            ? ((mapGroupsAll.kpi_ontime / mapGroupsAll.count) * 100).toFixed(2)
            : 0,
      };

      const status =
        mapGroupsAll.count === 0
          ? "-"
          : calculatePercentage.percentage >= calculatePercentage.target
          ? "ผ่าน"
          : "ไม่ผ่าน";

      const finalData = {
        ...calculatePercentage,
        label:
          status !== "-"
            ? `${formatNumber(calculatePercentage.percentage)} %\n${status}`
            : status,
      };
      setDataTotalInbound(finalData);
      setDataGraphInbound(mapGroups);
    });
  };
  const getDataGraphDelivery = async (fil = filterDashboard) => {
    let _filter = { ...fil };
    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    // delete _filter.project_type;
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardKPIDelivery(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
          label:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
        };
      });
      const mapGroupsAll = response?.reduce((acc, item) => {
        return {
          kpi_ontime: (acc.kpi_ontime || 0) + (item.kpi_ontime || 0),
          kpi_delay: (acc.kpi_delay || 0) + (item.kpi_delay || 0),
          count: (acc.count || 0) + (item.count || 0),
          target: item.target,
        };
      }, {});

      const calculatePercentage = {
        ...mapGroupsAll,
        xname: "Delivery",
        percentage:
          mapGroupsAll.count !== 0
            ? ((mapGroupsAll.kpi_ontime / mapGroupsAll.count) * 100).toFixed(2)
            : 0,
      };

      const status =
        mapGroupsAll.count === 0
          ? "-"
          : calculatePercentage.percentage >= calculatePercentage.target
          ? "ผ่าน"
          : "ไม่ผ่าน";

      const finalData = {
        ...calculatePercentage,
        label:
          status !== "-"
            ? `${formatNumber(calculatePercentage.percentage)} %\n${status}`
            : status,
      };
      setDataTotalDelivery(finalData);
      setDataGraphDelivery(mapGroups);
    });
  };
  const getDataGraphDocument = async (fil = filterDashboard) => {
    let _filter = { ...fil };
    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    // delete _filter.project_type;
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardKPIDocument(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
          label:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
        };
      });
      const mapGroupsAll = response?.reduce((acc, item) => {
        return {
          kpi_ontime: (acc.kpi_ontime || 0) + (item.kpi_ontime || 0),
          kpi_delay: (acc.kpi_delay || 0) + (item.kpi_delay || 0),
          count: (acc.count || 0) + (item.count || 0),
          target: item.target,
        };
      }, {});

      const calculatePercentage = {
        ...mapGroupsAll,
        xname: "Document",
        percentage:
          mapGroupsAll.count !== 0
            ? ((mapGroupsAll.kpi_ontime / mapGroupsAll.count) * 100).toFixed(2)
            : 0,
      };

      const status =
        mapGroupsAll.count === 0
          ? "-"
          : calculatePercentage.percentage >= calculatePercentage.target
          ? "ผ่าน"
          : "ไม่ผ่าน";

      const finalData = {
        ...calculatePercentage,
        label:
          status !== "-"
            ? `${formatNumber(calculatePercentage.percentage)} %\n${status}`
            : status,
      };
      setDataTotalDocument(finalData);
      setDataGraphDocument(mapGroups);
    });
  };
  const getDataGraphIn24hr = async (fil = filterDashboard) => {
    let _filter = { ...fil };
    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    // delete _filter.project_type;
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardKPIIn24hr(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
          label:
            item.count !== 0
              ? ((item.kpi_ontime / item.count) * 100).toFixed(2)
              : 0,
        };
      });
      const mapGroupsAll = response?.reduce((acc, item) => {
        return {
          kpi_ontime: (acc.kpi_ontime || 0) + (item.kpi_ontime || 0),
          kpi_delay: (acc.kpi_delay || 0) + (item.kpi_delay || 0),
          count: (acc.count || 0) + (item.count || 0),
          target: item.target,
        };
      }, {});

      const calculatePercentage = {
        ...mapGroupsAll,
        xname: "Accept in 24hr",
        percentage:
          mapGroupsAll.count !== 0
            ? ((mapGroupsAll.kpi_ontime / mapGroupsAll.count) * 100).toFixed(2)
            : 0,
      };

      const status =
        mapGroupsAll.count === 0
          ? "-"
          : calculatePercentage.percentage >= calculatePercentage.target
          ? "ผ่าน"
          : "ไม่ผ่าน";

      const finalData = {
        ...calculatePercentage,
        label:
          status !== "-"
            ? `${formatNumber(calculatePercentage.percentage)} %\n${status}`
            : status,
      };
      setDataTotalIn24hr(finalData);
      setDataGraphIn24hr(mapGroups);
    });
  };
  const getDataGraphMatching = async (fil = filterDashboard) => {
    let _filter = { ...fil };
    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    // delete _filter.project_type;
    delete _filter.display;
    delete _filter.display_count;

    await getDashboardKPIMatching(_filter).then((res) => {
      const response = res.data.data;

      const mapGroups = response?.map((item) => {
        return {
          ...item,
          percentage:
            item.count_work !== 0
              ? (
                  ((item.count_work - item.count_expired) / item.count_work) *
                  100
                ).toFixed(2)
              : 0,
          label:
            item.count_work !== 0
              ? (
                  ((item.count_work - item.count_expired) / item.count_work) *
                  100
                ).toFixed(2)
              : 0,
        };
      });
      const mapGroupsAll = response?.reduce((acc, item) => {
        return {
          count_work: (acc.count_work || 0) + (item.count_work || 0),
          count_expired: (acc.count_expired || 0) + (item.count_expired || 0),
          target: item.target,
        };
      }, {});

      const calculatePercentage = {
        ...mapGroupsAll,
        xname: "Matching",
        percentage:
          mapGroupsAll.count_work !== 0
            ? (
                ((mapGroupsAll.count_work - mapGroupsAll.count_expired) /
                  mapGroupsAll.count_work) *
                100
              ).toFixed(2)
            : 0,
      };

      const status =
        mapGroupsAll.count_work === 0
          ? "-"
          : calculatePercentage.percentage >= calculatePercentage.target
          ? "ผ่าน"
          : "ไม่ผ่าน";

      const finalData = {
        ...calculatePercentage,
        label:
          status !== "-"
            ? `${formatNumber(calculatePercentage.percentage)} %\n${status}`
            : status,
      };
      setDataTotalMatching(finalData);
      setDataGraphMatching(mapGroups);
    });
  };

  useEffect(() => {
    if (profileList.length > 0) {
      getProfile();
    }
  }, [filterProfile.page]);

  const calculatePercentage = (a, b) => {
    if (a === 0 || a === undefined) {
      return null;
    }
    const c = b - a;
    const d = (c / a) * 100;
    return d.toFixed(2);
  };

  const getProfile = async (filter = filterProfile) => {
    const body = {
      shipper: {},
      carrier: {},
      driver: {},
    };
    await getAllUserAPI(filter, body)
      .then((response) => {
        console.log(response.data.data);
        setProfileList(response.data.data);
        setTotalProfile(response.data.total);
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
        } else if (error.response.data.code === "4105") {
          // removeAccessToken("access_token");
          // window.location.reload();
        }
      });
  };
  const formatNumber = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const abbreviateNumber = (numStr) => {
    if (numStr) {
      const num =
        typeof numStr === "number"
          ? numStr
          : parseFloat(numStr?.replace(/,/g, ""));
      if (isNaN(num)) {
        return null;
      }
      const absNum = Math.abs(num);
      const units = [
        { value: 1e12, suffix: "T" }, // Trillion
        { value: 1e9, suffix: "B" }, // Billion
        { value: 1e6, suffix: "M" }, // Million
        { value: 1e3, suffix: "K" }, // Thousand
      ];
      for (let i = 0; i < units.length; i++) {
        if (absNum >= units[i].value) {
          const abbreviatedNum = num / units[i].value;
          const rounded = parseFloat(abbreviatedNum.toFixed(2));
          return `${rounded} ${units[i].suffix}`;
        }
      }
      return num.toString();
    } else {
      return "0";
    }
  };

  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map(
            (entry, index) =>
              !(
                masterTruck.includes(entry.dataKey) ||
                masterOrigin.includes(entry.dataKey) ||
                masterDestination.includes(entry.dataKey)
              ) && (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: entry.color,
                      marginRight: 5,
                      borderRadius: 50,
                    }}
                  ></div>
                  <span>
                    {entry.value == "sum"
                      ? "รายได้"
                      : entry.value === "sum2"
                      ? "รายได้เมื่อปีที่แล้ว"
                      : entry.value === "target"
                      ? "เป้าหมาย"
                      : entry.value == "sum_ftl"
                      ? "FTL"
                      : entry.value == "sum_stl"
                      ? "STL"
                      : entry.value}
                  </span>
                </div>
              )
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => setShowFav(!showFav)}
            style={{ cursor: "pointer", paddingRight: 20 }}
          >
            {showFav ? (
              <Star size={40} sx={{ color: "#8ba2bb" }} />
            ) : (
              <StarBorder size={40} sx={{ color: "#8ba2bb" }} />
            )}
          </div>
          <div
            onClick={() => setModalUploadTarget(true)}
            style={{ cursor: "pointer", paddingRight: 20 }}
          >
            <PiFlagPennantFill size={20} color="#8ba2bb" />
          </div>
        </div>
      </div>
    );
  };
  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "target"
                ? "เป้าหมาย : "
                : entry.dataKey == "sum2"
                ? "รายได้เมื่อปีที่แล้ว : "
                : entry.dataKey == "sum"
                ? "รายได้ : "
                : entry.dataKey == "sum_ftl"
                ? "FTL :"
                : entry.dataKey == "sum_stl"
                ? "STL :"
                : `${entry.dataKey} :`}
              {formatNumber(Number(entry.value).toFixed(2)) + " บาท"}
            </span>
          </div>
        ))}
        {filterDashboard.display.includes("target") &&
          filterDashboard.display.includes("revenue") && (
            <div>
              <div style={{ fontSize: 10, paddingLeft: 15 }}>
                ส่วนต่าง :
                <span
                  style={{
                    fontSize: 10,
                    color:
                      Number(payload[0].payload.sum) -
                        Number(payload[0].payload.target) >
                      0
                        ? "green"
                        : Number(payload[0].payload.sum) -
                            Number(payload[0].payload.target) <
                          0
                        ? "red"
                        : "primary.main",
                  }}
                >
                  {formatNumber(
                    (
                      Number(payload[0].payload.sum) -
                      Number(payload[0].payload.target)
                    ).toFixed(2)
                  ) + " บาท"}
                </span>
              </div>
              <div style={{ fontSize: 10, paddingLeft: 15 }}>
                ส่วนต่าง :
                <span
                  style={{
                    fontSize: 10,
                    color:
                      calculatePercentage(
                        Number(payload[0].payload.target),
                        Number(payload[0].payload.sum)
                      ) > 0
                        ? "green"
                        : calculatePercentage(
                            Number(payload[0].payload.target),
                            Number(payload[0].payload.sum)
                          ) < 0
                        ? "red"
                        : "primary.main",
                  }}
                >
                  {formatNumber(
                    calculatePercentage(
                      Number(payload[0].payload.target),
                      Number(payload[0].payload.sum)
                    )
                  ) + " %"}
                </span>
              </div>
            </div>
          )}
        {filterDashboard.display.includes("service") && (
          <div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              FTL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {formatNumber(
                  (
                    (Number(payload[0].payload.sum_ftl) /
                      Number(payload[0].payload.sum)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              STL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {formatNumber(
                  (
                    (Number(payload[0].payload.sum_stl) /
                      Number(payload[0].payload.sum)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };
  const CustomLegendCount = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map(
            (entry, index) =>
              !(
                masterCountTruck.includes(entry.dataKey) ||
                masterCountOrigin.includes(entry.dataKey) ||
                masterCountDestination.includes(entry.dataKey)
              ) && (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: entry.color,
                      marginRight: 5,
                      borderRadius: 50,
                    }}
                  ></div>
                  <span>
                    {entry.value == "count"
                      ? "จำนวนงาน"
                      : entry.value === "count2"
                      ? "จำนวนงานเมื่อปีที่แล้ว"
                      : entry.value == "count_ftl"
                      ? "จำนวนงาน FTL"
                      : entry.value == "count_stl"
                      ? "จำนวนงาน STL"
                      : entry.value}
                  </span>
                </div>
              )
          )}
        </div>
      </div>
    );
  };
  const CustomTooltipCount = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "count2"
                ? "จำนวนงานเมื่อปีที่แล้ว : "
                : entry.dataKey == "count"
                ? "จำนวนงาน : "
                : entry.dataKey == "count_ftl"
                ? "จำนวนงาน FTL :"
                : entry.dataKey == "count_stl"
                ? "จำนวนงาน STL :"
                : `${entry.dataKey} :`}
              {formatNumber(Number(entry.value)) + " งาน"}
            </span>
          </div>
        ))}
        {filterDashboard.display_count.includes("service") && (
          <div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              FTL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {formatNumber(
                  (
                    (Number(payload[0].payload.count_ftl) /
                      Number(payload[0].payload.count)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
            <div style={{ fontSize: 10, paddingLeft: 15 }}>
              STL(%) :
              <span
                style={{
                  fontSize: 10,
                }}
              >
                {formatNumber(
                  (
                    (Number(payload[0].payload.count_stl) /
                      Number(payload[0].payload.count)) *
                    100
                  ).toFixed(2)
                ) + " %"}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };
  const CustomLegendGroup = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>
                {entry.value == "totalCarrier"
                  ? "ราคาจ้าง"
                  : entry.value === "totalShipper"
                  ? "ราคาขาย"
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const CustomTooltipGroup = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.title}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>
              {entry.dataKey == "totalCarrier"
                ? "ราคาจ้าง : "
                : entry.dataKey == "totalShipper"
                ? "ราคาขาย : "
                : ""}
              {formatNumber(entry.value)} บาท/กม.
            </span>
          </div>
        ))}
      </div>
    );
  };
  const CustomBarShape = (props) => {
    const { x, y, width, height, fill, stroke } = props;
    const borderRadius = 5;
    const borderColor = stroke;
    const borderWidth = 1;
    if (height <= 0) {
      return null;
    }
    return (
      <path
        d={`
          M${x},${y + height} 
          L${x},${y + borderRadius} 
          Q${x},${y} ${x + borderRadius},${y} 
          L${x + width - borderRadius},${y} 
          Q${x + width},${y} ${x + width},${y + borderRadius} 
          L${x + width},${y + height} 
          Z
        `}
        fill={fill}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
    );
  };
  const CustomTooltipPie = ({ payload, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.payload.fill,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>{`${entry.name} : ${(
              (entry.payload.value / entry.payload.total) *
              100
            ).toFixed(2)}%`}</span>
          </div>
        ))}
        <div>
          <div style={{ fontSize: 10, paddingLeft: 15 }}>
            รายได้ :
            <span
              style={{
                fontSize: 10,
              }}
            >
              {formatNumber(payload[0].payload.value.toFixed(2)) + " บาท"}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const CustomLegendPie = (props) => {
    const { payload } = props;
    const sortedData = payload.sort(
      (a, b) => b.payload.value - a.payload.value
    );
    const rankedData = sortedData.map((item, index) => ({
      name: item.payload.name,
      value: item.payload.value,
      total: item.payload.total,
      rank: index < 3 ? `#${index + 1}` : "",
      color: item.payload.fill,
    }));
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {rankedData &&
            rankedData.map(
              (item, idx) =>
                idx < 3 && (
                  <Grid container key={idx} spacing={1}>
                    <Grid item xs={1}>
                      <div
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {item.rank}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <LightTooltip
                        title={
                          item.name === "FTL"
                            ? "FTL : บริการเหมาคัน"
                            : item.name === "STL"
                            ? "STL : บริการฝากส่ง"
                            : item.name
                        }
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: "12px",
                          }}
                        >
                          {item.name === "FTL"
                            ? "FTL : บริการเหมาคัน"
                            : item.name === "STL"
                            ? "STL : บริการฝากส่ง"
                            : item.name}
                        </div>
                      </LightTooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        style={{
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        {`${((item.value / item.total) * 100).toFixed(2)}%`}
                      </div>
                    </Grid>
                  </Grid>
                )
            )}
        </div>
      </div>
    );
  };
  const CustomLegendCountPie = (props) => {
    const { payload } = props;
    const sortedData = payload.sort(
      (a, b) => b.payload.value - a.payload.value
    );
    const rankedData = sortedData.map((item, index) => ({
      name: item.payload.name,
      value: item.payload.value,
      total: item.payload.total,
      rank: index < 3 ? `#${index + 1}` : "",
      color: item.payload.fill,
    }));

    console.log("rankedData", rankedData, sortedData);
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {rankedData &&
            rankedData.map(
              (item, idx) =>
                idx < 3 && (
                  <Grid container key={idx} spacing={1}>
                    <Grid item xs={1}>
                      <div
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {item.rank}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <LightTooltip
                        title={
                          item.name === "FTL"
                            ? "FTL : บริการเหมาคัน"
                            : item.name === "STL"
                            ? "STL : บริการฝากส่ง"
                            : item.name
                        }
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: "12px",
                          }}
                        >
                          {item.name === "FTL"
                            ? "FTL : บริการเหมาคัน"
                            : item.name === "STL"
                            ? "STL : บริการฝากส่ง"
                            : item.name}
                        </div>
                      </LightTooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        style={{
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        {`${((item.value / item.total) * 100).toFixed(2)}%`}
                      </div>
                    </Grid>
                  </Grid>
                )
            )}
        </div>
      </div>
    );
  };
  const CustomTooltipCountPie = ({ payload, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    console.log("payload CustomTooltipCountPie", payload);
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 5,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 10 }}>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.payload.fill,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span style={{ fontSize: 10 }}>{`${entry.name} : ${(
              (entry.payload.count / entry.payload.total) *
              100
            ).toFixed(2)}%`}</span>
          </div>
        ))}
        <div>
          <div style={{ fontSize: 10, paddingLeft: 15 }}>
            จำนวนนงาน :
            <span
              style={{
                fontSize: 10,
              }}
            >
              {formatNumber(payload[0].payload.count.toFixed(0)) + " งาน"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderFilterBar = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px 20px 0px 20px",
          borderRadius: "10px",
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldSelect
              name={"แสดงตาม"}
              options={[
                { value: "year", name: "รายปี" },
                { value: "month", name: "รายเดือน" },
                { value: "week", name: "รายสัปดาห์" },
                { value: "day", name: "รายวัน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"day"}
              value={filterDashboard.type_data_set}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  type_data_set: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterDashboard.start_date ?? firstDayOfMonth,
                end_date: filterDashboard.end_date ?? yesterday,
              }}
              style={{
                width: "-webkit-fill-available",
              }}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldSelect
              name={"ประเภทงานขนส่ง"}
              options={[
                { value: true, name: "3PL" },
                { value: false, name: "งานอื่นๆที่ไม่ใช่ 3PL" },
                { value: "all", name: "ทั้งหมด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={true}
              value={filterDashboard?.on_dashboard}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  on_dashboard: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={2.4}
          >
            <FieldSelect
              name={"ประเภทโครงการ"}
              options={[
                { value: "all", name: "ทั้งหมด" },
                ...ProjectFrequencyType,
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"all"}
              value={filterDashboard.project_type}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  project_type: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={2.4}
          >
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <div style={{ flex: 0.7, paddingRight: 20 }}>
                <FieldInput
                  value={filterDashboard.profile_name ?? ""}
                  label={"ชื่อโปรไฟล์"}
                  style={{ width: "-webkit-fill-available" }}
                  readOnly
                  closePaddingBottom
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 0.3,
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "secondary.main",
                  }}
                  onClick={() => {
                    setShowGetProfile(true);
                  }}
                >
                  <Search sx={{ color: "white", fontSize: 30 }} />
                </Avatar>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "white",
                  }}
                  onClick={() => {
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: null,
                      profile_name: null,
                    });
                  }}
                >
                  <CleaningServices
                    sx={{ color: "secondary.main", fontSize: 30 }}
                  />
                </Avatar>
                {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px" }}
              color="secondary"
              onClick={() => {
                // getProject();
                setShowGetProfile(true);
              }}
            >
              ค้นหา
            </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่1(ก่อน)"}
              options={optionCalculate}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph1}
              onChange={handleFirstDropdownChange}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่2(หลัง)"}
              options={optionCalculate2}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph2}
              onChange={(value) => setGraph2(value)}
              closePaddingBottom={true}
              readOnly={graph1 ? false : true}
            />
          </Grid>
        </Grid> */}
      </div>
    );
  };

  const handleGetFav = async (fil = filterDashboard) => {
    setLoadingFav(true);
    setDataFavShipper([]);
    let results = [];
    let _filter = { ...fil };

    if (_filter.on_dashboard === "all") {
      delete _filter.on_dashboard;
    }
    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    delete _filter.display;
    const res = await getProfileByGrade({ shipper_grade_type: 4 });
    if (res.data.code === 1000) {
      for (let value of res?.data?.data) {
        _filter.profile_id = value.id;
        await getDashboardWorkByProfile(_filter).then(async (res) => {
          const response = res.data.data;
          const mapGroups = response?.map((item) => {
            return {
              ...item,
              groups: Object.values(
                item.groups?.reduce(
                  (acc, { company_name, shipper_freight_before_tax }) => {
                    if (!acc[company_name]) {
                      acc[company_name] = {
                        company_name,
                        count: 0,
                        income: 0,
                      };
                    }
                    acc[company_name].count += 1;
                    acc[company_name].income += shipper_freight_before_tax;
                    return acc;
                  },
                  {}
                )
              ).sort((a, b) => b.income - a.income),
            };
          });
          const max = Math.max(...response.map((item) => item.sum));
          const maxTarget = Math.max(...response.map((item) => item.target));
          const niceMaxValue =
            maxTarget > max
              ? Math.ceil(maxTarget + maxTarget * 0.1)
              : Math.ceil(max + max * 0.1);

          let mergeValue;
          let niceMaxValue2;
          let filterYearAgo = {
            ..._filter,
            start_date: moment(_filter.start_date).subtract(1, "year"),
            end_date: moment(_filter.end_date).subtract(1, "year"),
          };
          await getDashboardWorkByProfile(filterYearAgo).then((res2) => {
            const response2 = res2.data.data;
            const mapGroups2 = response2?.map((item) => {
              return {
                ...item,
                groups: Object.values(
                  item.groups?.reduce(
                    (acc, { company_name, shipper_freight_before_tax }) => {
                      if (!acc[company_name]) {
                        acc[company_name] = {
                          company_name,
                          count: 0,
                          income: 0,
                        };
                      }
                      acc[company_name].count += 1;
                      acc[company_name].income += shipper_freight_before_tax;
                      return acc;
                    },
                    {}
                  )
                ).sort((a, b) => b.income - a.income),
              };
            });
            mergeValue = mapGroups.map((item, index) => ({
              ...item,
              sum2: mapGroups2.find((e, i) => i === index)?.sum || 0,
            }));
            const max2 = Math.max(...response2.map((item) => item.sum));
            niceMaxValue2 = Math.ceil(max2 + max2 * 0.1);
          });

          results.push({
            profile_id: _filter.profile_id,
            company_name: value.company_name,
            data_graph: mergeValue,
            max_graph:
              niceMaxValue2 > niceMaxValue
                ? niceMaxValue2
                : niceMaxValue > 100
                ? niceMaxValue
                : 100,
          });
        });
      }
      setDataFavShipper(results);
      setLoadingFav(false);
    } else {
      setLoadingFav(false);
    }
  };

  const handleGetDataGraphByGroup = async (
    fil = filterDashboard,
    filByGroup = filterGraphLoc
  ) => {
    let _filter = { ...fil, ...filByGroup };
    const truck_group = [
      "รถกระบะ 4 ล้อ",
      "รถบรรทุก 6 ล้อ",
      "รถบรรทุก 10 ล้อ",
      "รถบรรทุก 18 ล้อ",
      "รถบรรทุก 22 ล้อ",
    ];

    if (_filter.project_type === "all") {
      delete _filter.project_type;
    }
    if (_filter.distance_range === "all") {
      delete _filter.distance_range;
    }
    delete _filter.display;
    let results = [];
    for (let value of truck_group) {
      const res = await getDataGraphByGroup({ ..._filter, truck_group: value });
      if (res.data.code === 1000) {
        const response = res.data.data;
        const mapGroups = response?.map((item) => {
          const avgShipperFreight =
            Number(item.avg_shipper_freight_per_km) || 0;
          const avgCarrierFreight =
            Number(item.avg_carrier_freight_per_km) || 0;

          let diff =
            ((avgShipperFreight / avgCarrierFreight - 1) * 100).toFixed(2) ?? 0;

          return {
            ...item,
            avg_shipper_freight_per_km:
              Number(item.avg_shipper_freight_per_km).toFixed(2) ?? 0,
            avg_carrier_freight_per_km:
              Number(item.avg_carrier_freight_per_km).toFixed(2) ?? 0,
            label_shipper: isNaN(diff)
              ? "-"
              : `${Number(item.avg_shipper_freight_per_km).toFixed(2) ?? 0},${
                  diff > 0 ? `+${diff}` : diff < 0 ? `${diff}` : ""
                }`,
            label_carrier: `${
              Number(item.avg_carrier_freight_per_km).toFixed(2) ?? 0
            }`,
            // groups: Object.values(
            //   item.groups?.reduce(
            //     (
            //       acc,
            //       {
            //         company_name,
            //         carrier_freight_per_km,
            //         shipper_freight_per_km,
            //       }
            //     ) => {
            //       if (!acc[company_name]) {
            //         acc[company_name] = {
            //           company_name,
            //           carrier_freight_per_km,
            //           shipper_freight_per_km,
            //           count: 0,
            //         };
            //       }
            //       acc[company_name].count += 1;
            //       return acc;
            //     },
            //     {}
            //   )
            // ),
          };
        });
        const maxCR = Math.max(
          ...response.map((item) => item.avg_carrier_freight_per_km)
        );
        const maxSH = Math.max(
          ...response.map((item) => item.avg_shipper_freight_per_km)
        );
        const niceMaxValueCR = Math.ceil(maxCR + maxCR * 0.6);
        const niceMaxValueSH = Math.ceil(maxSH + maxSH * 0.6);

        let totalCarrier = 0;
        let countCarrier = 0;
        let totalShipper = 0;
        let countShipper = 0;
        mapGroups.forEach((value) => {
          value?.groups?.map((item) => {
            if (
              !isNaN(Number(item.carrier_freight_per_km)) &&
              Number(item.carrier_freight_per_km) !== 0
            ) {
              totalCarrier += Number(item.carrier_freight_per_km);
              countCarrier++;
            }
            if (
              !isNaN(Number(item.shipper_freight_per_km)) &&
              Number(item.shipper_freight_per_km) !== 0
            ) {
              totalShipper += Number(item.shipper_freight_per_km);
              countShipper++;
            }
          });
        });
        let avgCarrier =
          countCarrier > 0 ? (totalCarrier / countCarrier).toFixed(2) : 0;
        let avgShipper =
          countShipper > 0 ? (totalShipper / countShipper).toFixed(2) : 0;
        let diffTotal = ((avgShipper / avgCarrier - 1) * 100).toFixed(2) ?? 0;

        results.push({
          title: value,
          data: mapGroups,
          totalShipper: Number(avgShipper),
          totalCarrier: Number(avgCarrier),
          labelShipper: isNaN(diffTotal)
            ? "-"
            : `${Number(avgShipper).toFixed(2) ?? 0},${
                diffTotal > 0
                  ? `+${diffTotal}`
                  : diffTotal < 0
                  ? `${diffTotal}`
                  : ""
              }`,
          maxValueY:
            niceMaxValueSH > niceMaxValueCR
              ? niceMaxValueSH > 0
                ? niceMaxValueSH
                : niceMaxValueCR > 0
                ? niceMaxValueCR
                : 1
              : niceMaxValueCR > 0
              ? niceMaxValueCR
              : 1,
        });
      } else {
        console.log("error handleGetDataGraphGroup");
      }
    }
    setDataGraphByGroup(results);
  };

  return (
    <Container>
      <SubMenu
        menus={subDashboard.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `งานขนส่ง` ? true : false,
          };
        })}
        breadcrumbs={breadcrumbs}
      />
      {renderFilterBar()}
      <div>
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "stretch", marginTop: "10px" }}
        >
          <Grid item xs={8}>
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                borderRadius: "10px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    fontSize={18}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    รายได้
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container>
                    <Grid style={{ paddingRight: "20px" }} item xs={6}>
                      <FieldSelect
                        name={"แสดงผล"}
                        options={[
                          { value: "revenue", name: "รายได้" },
                          { value: "target", name: "เป้าหมาย" },
                          { value: "compare", name: "ช่วงนี้เมื่อปีที่แล้ว" },
                          { value: "service", name: "ประเภทบริการ" },
                          { value: "truck_type", name: "ประเภทรถ" },
                          { value: "origin", name: "ภูมิภาคต้นทาง" },
                          { value: "destination", name: "ภูมิภาคปลายทาง" },
                        ]}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                        // defaultValue={"day"}
                        value={filterDashboard.display}
                        defaultValue={[]}
                        multiSelect={true}
                        onChange={(val) =>
                          setFilterDashboard({
                            ...filterDashboard,
                            display: val,
                          })
                        }
                        closePaddingBottom={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {/* <div>
                    <Typography
                      fontSize={18}
                      style={{
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                    >
                      รายได้สะสม
                    </Typography>
                  </div> */}
                  <div
                    style={{ display: "flex", justifyContent: "end", gap: 10 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "end",
                          color: "primary.main",
                          fontSize: "14px",
                        }}
                      >
                        {"เป้าหมายสะสม : " +
                          formatNumber(allTarget.toFixed(2)) +
                          " บาท"}
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          color: "primary.main",
                          fontSize: "14px",
                        }}
                      >
                        รายได้สะสม :{" "}
                        {helper.addComma(allIncome.toFixed(2)) + " บาท" ?? "-"}
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          fontSize: "14px",
                        }}
                      >
                        ส่วนต่าง :{" "}
                        {formatNumber((allIncome - allTarget).toFixed(2)) +
                          " บาท" ?? "-"}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {calculatePercentage(
                            Number(allTarget),
                            Number(allIncome)
                          ) > 0 ? (
                            <FaArrowTrendUp color="#27CE88" size={40} />
                          ) : calculatePercentage(
                              Number(allTarget),
                              Number(allIncome)
                            ) < 0 ? (
                            <FaArrowTrendDown color="#FF316F" size={40} />
                          ) : (
                            "-"
                            // <FaWindowMinimize color="#FFC675" size={40} />
                          )}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: 500,
                            color:
                              calculatePercentage(
                                Number(allTarget),
                                Number(allIncome)
                              ) > 0
                                ? "#27CE88"
                                : calculatePercentage(
                                    Number(allTarget),
                                    Number(allIncome)
                                  ) < 0
                                ? "#FF316F"
                                : "#FFC675",
                          }}
                        >
                          {allTarget && allIncome
                            ? formatNumber(
                                calculatePercentage(
                                  Number(allTarget),
                                  Number(allIncome)
                                )
                              ) + " %"
                            : "-"}
                          {/* <p style={{ margin: 0, lineHeight: 1 }}>%</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <ResponsiveContainer width="100%" height={430}>
                <ComposedChart
                  width={730}
                  height={434}
                  data={dataWorkMerge}
                  margin={{
                    top: 30,
                    right: 50,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e1e9ea" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#e1e9ea" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey={"xname"}
                    style={{ fontSize: "10px", fontWeight: 500 }}
                  />
                  <YAxis
                    style={{ fontSize: "10px", fontWeight: 500 }}
                    tickFormatter={formatNumber}
                    domain={[0, maxValue]}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    contentStyle={{ zIndex: 999999 }}
                  />
                  <Legend content={<CustomLegend />} />
                  <CartesianGrid stroke="#f5f5f5" />
                  {filterDashboard.display.includes("target") && (
                    <Line
                      strokeWidth={2}
                      dataKey="target"
                      stroke={`rgba(255, 49, 111,0.5)`}
                      dot={false}
                      strokeDasharray="5 5"
                    />
                  )}
                  {filterDashboard.display.includes("compare") && (
                    <Area
                      dataKey="sum2"
                      stroke={`#729094`}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    >
                      <LabelList
                        dataKey="sum2"
                        position="top"
                        angle={-90}
                        fill={`#729094`}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          textAnchor: "start",
                        }}
                        formatter={abbreviateNumber}
                      />
                    </Area>
                  )}
                  {filterDashboard.display.includes("revenue") && (
                    <Bar
                      dataKey="sum"
                      barSize={20}
                      fill={`rgba(199,233,224,0.8)`}
                      stroke={`#00C49F`}
                      onClick={(data, index) => {
                        setBarData(data.payload);
                        if (index > 0) {
                          setGraph1(dataWork[index - 1]);
                        } else {
                          setGraph1(null);
                        }
                        setGraph2(data.payload);
                      }}
                      shape={<CustomBarShape />}
                    >
                      <LabelList
                        dataKey="label"
                        content={(props) => {
                          const { x, y, value, width } = props;
                          const lines = value.split("\n");
                          const offset = 40;
                          return (
                            <text
                              x={x}
                              y={y}
                              fill="#00C49F"
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-90, ${x + 10}, ${y})`}
                            >
                              {lines.map((line, index) => (
                                <tspan
                                  key={index}
                                  x={x + 14}
                                  dy={index * 10}
                                  style={{
                                    fontSize: index == 1 ? "12px" : "14px",
                                  }}
                                >
                                  {line}
                                </tspan>
                              ))}
                            </text>
                          );
                        }}
                      />
                    </Bar>
                  )}

                  {filterDashboard.display.includes("service") && (
                    <Bar
                      dataKey="sum_ftl"
                      barSize={20}
                      fill={`rgba(255, 8, 0,0.5)`}
                      stroke={`#ff0800`}
                      stackId="a"
                      onClick={(data, index) => {
                        setBarData(data.payload);
                        if (index > 0) {
                          setGraph1(dataWork[index - 1]);
                        } else {
                          setGraph1(null);
                        }
                        setGraph2(data.payload);
                      }}
                    />
                  )}
                  {filterDashboard.display.includes("service") && (
                    <Bar
                      dataKey="sum_stl"
                      barSize={20}
                      fill={`rgba(0, 72, 255,0.5)`}
                      stroke={`#0048ff`}
                      stackId="a"
                      onClick={(data, index) => {
                        setBarData(data.payload);
                        if (index > 0) {
                          setGraph1(dataWork[index - 1]);
                        } else {
                          setGraph1(null);
                        }
                        setGraph2(data.payload);
                      }}
                      shape={<CustomBarShape />}
                    />
                  )}
                  {filterDashboard.display.includes("truck_type") &&
                    masterTruck?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="b"
                          onClick={(data, index) => {
                            setBarData(data.payload);
                            if (index > 0) {
                              setGraph1(dataWork[index - 1]);
                            } else {
                              setGraph1(null);
                            }
                            setGraph2(data.payload);
                          }}
                        />
                      );
                    })}
                  {filterDashboard.display.includes("origin") &&
                    masterOrigin?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="c"
                          onClick={(data, index) => {
                            setBarData(data.payload);
                            if (index > 0) {
                              setGraph1(dataWork[index - 1]);
                            } else {
                              setGraph1(null);
                            }
                            setGraph2(data.payload);
                          }}
                        />
                      );
                    })}
                  {filterDashboard.display.includes("destination") &&
                    masterDestination?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="d"
                          onClick={(data, index) => {
                            setBarData(data.payload);
                            if (index > 0) {
                              setGraph1(dataWork[index - 1]);
                            } else {
                              setGraph1(null);
                            }
                            setGraph2(data.payload);
                          }}
                        />
                      );
                    })}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} sx={{ alignItems: "stretch" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ height: "100%", alignItems: "stretch" }}
                >
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>รายได้</p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              ประจํา
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {" " + barData?.xname + " "}
                            </p>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {calculatePercentage(
                              Number(barData?.target),
                              Number(barData?.sum)
                            ) > 0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : calculatePercentage(
                                Number(barData?.target),
                                Number(barData?.sum)
                              ) < 0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                calculatePercentage(
                                  Number(barData?.target),
                                  Number(barData?.sum)
                                ) > 0
                                  ? "#27CE88"
                                  : calculatePercentage(
                                      Number(barData?.target),
                                      Number(barData?.sum)
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {barData
                              ? helper.addComma(
                                  calculatePercentage(
                                    Number(barData?.target),
                                    Number(barData?.sum)
                                  )
                                ) + " %"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Grid container sx={{ height: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div style={{ fontSize: 14 }}>
                                เป้าหมาย : {formatNumber(barData?.target)} บาท
                              </div>
                              <div style={{ fontSize: 14 }}>
                                รายได้ : {formatNumber(barData?.sum)} บาท
                              </div>
                              <div style={{ fontSize: 14 }}>
                                ส่วนต่าง :{" "}
                                {formatNumber(
                                  (
                                    Number(barData?.sum) -
                                    Number(barData?.target)
                                  ).toFixed(2)
                                ) + " บาท"}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              เปลี่ยนแปลง
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              จาก
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}{" "}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {graph1?.xname}
                            </p>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {calculatePercentage(graph1?.sum, graph2?.sum) >
                            0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : calculatePercentage(graph1?.sum, graph2?.sum) <
                              0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                Number(
                                  calculatePercentage(graph1?.sum, graph2?.sum)
                                ) > 0
                                  ? "#27CE88"
                                  : Number(
                                      calculatePercentage(
                                        graph1?.sum,
                                        graph2?.sum
                                      )
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {graph1 && graph2
                              ? helper.addComma(
                                  calculatePercentage(graph1.sum, graph2.sum)
                                ) + " %"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Grid
                          container
                          justifyContent="space-between"
                          sx={{ height: "100%" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {!(graph2?.sum === 0 && graph1?.sum === 0)
                                  ? Number(graph2?.sum - graph1?.sum) > 0
                                    ? "สูงกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) < 0
                                    ? "ต่ำกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) === 0
                                    ? "เท่ากับช่วงก่อนหน้า"
                                    : "-"
                                  : "-"}
                              </div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graph1 && graph2
                                  ? helper.addComma(graph2.sum - graph1.sum) +
                                    " บาท"
                                  : "-"}
                              </div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graph1 && graph2
                                  ? formatNumber(
                                      graph2?.count - graph1?.count
                                    ) + " งาน"
                                  : "-"}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ height: 360 }}>
                <Paper
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                    height: "100%",
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontWeight: 500,
                    }}
                  >
                    10 อันดับลูกค้าที่ใช้งานมากที่สุด
                    <p style={{ margin: 0, lineHeight: 1 }}>
                      ประจำ
                      {filterDashboard.type_data_set === "day"
                        ? "วัน"
                        : filterDashboard.type_data_set === "week"
                        ? "สัปดาห์"
                        : filterDashboard.type_data_set === "month"
                        ? "เดือน"
                        : "ปี"}
                      {" " + barData?.xname + " "}
                    </p>
                  </Typography>
                  <Grid container>
                    <Grid item xs={5}>
                      <div style={{ fontWeight: 500 }}>ชื่อ</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>
                        จำนวน
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>
                        รายได้
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>%</div>
                    </Grid>
                  </Grid>
                  {barData?.groups
                    ?.sort((a, b) => b.income - a.income)
                    .map(
                      (row, index) =>
                        index < 10 && (
                          <Grid container key={index} spacing={1}>
                            <Grid item xs={5}>
                              <LightTooltip title={row.company_name}>
                                <div
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "14px",
                                  }}
                                >
                                  {row.company_name}
                                </div>
                              </LightTooltip>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                style={{ textAlign: "end", fontSize: "14px" }}
                              >
                                {formatNumber(row.count)}
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div
                                style={{ textAlign: "end", fontSize: "14px" }}
                              >
                                {helper.addComma(row.income)}
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                style={{ textAlign: "end", fontSize: "14px" }}
                              >
                                {helper.addComma(
                                  (row.income / Number(barData?.sum)) * 100
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )
                    )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={[1, 1]}
          sx={{ paddingTop: "8px" }}
          alignItems="stretch"
        >
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                รายได้แยกตามประเภทบริการ
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barData?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipPie />} />
                  <Legend content={<CustomLegendPie />} />
                  <Pie
                    data={barData?.service
                      .filter(({ value }) => value !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { value }) => sum + value, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barData?.service.filter(
                        (item) => item.value > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.value || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barData &&
                      barData?.service
                        .filter((item) => item.value !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                รายได้แยกตามประเภทรถ
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barData?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipPie />} />
                  <Legend content={<CustomLegendPie />} />
                  <Pie
                    data={barData?.truck
                      .filter(({ value }) => value !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { value }) => sum + value, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barData?.truck.filter(
                        (item) => item.value > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.value || 0),
                        0
                      );
                      if (!total || value === undefined) return "";
                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barData &&
                      barData?.truck
                        .filter((item) => item.value !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                รายได้แยกตามภูมิภาคต้นทาง
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barData?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipPie />} />
                  <Legend content={<CustomLegendPie />} />
                  <Pie
                    data={barData?.zone_origin
                      .filter(({ value }) => value !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { value }) => sum + value, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barData?.zone_origin.filter(
                        (item) => item.value > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.value || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barData &&
                      barData?.zone_origin
                        .filter((item) => item.value !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                รายได้แยกตามภูมิภาคปลายทาง
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barData?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipPie />} />
                  <Legend content={<CustomLegendPie />} />
                  <Pie
                    data={barData?.zone_destination
                      .filter(({ value }) => value !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { value }) => sum + value, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barData?.zone_destination.filter(
                        (item) => item.value > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.value || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barData &&
                      barData?.zone_destination
                        .filter((item) => item.value !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          {/* {dataFavShipper?.map((row, index) => (
                <Grid item xs={4} key={index}>
                  <GraphFavorite
                    profile_id={row.profile_id}
                    data={row?.data_graph}
                    title={row?.company_name}
                    maxValueY={row?.max_graph}
                    typeDataSet={filterDashboard.type_data_set}
                    refresh={() => handleGetFav()}
                  />
                </Grid>
              ))} */}
        </Grid>
        {showFav &&
          (loadingFav ? (
            <div
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 999,
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
            >
              <Loading />
            </div>
          ) : (
            <Grid
              container
              spacing={[1, 1]}
              sx={{ paddingTop: "8px" }}
              alignItems="stretch"
            >
              {dataFavShipper?.map((row, index) => (
                <Grid item xs={4} key={index}>
                  <GraphFavorite
                    type="work"
                    profile_id={row.profile_id}
                    data={row?.data_graph}
                    title={row?.company_name}
                    maxValueY={row?.max_graph}
                    typeDataSet={filterDashboard.type_data_set}
                    refresh={() => handleGetFav()}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "stretch", paddingTop: "8px" }}
        >
          <Grid item xs={8}>
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                borderRadius: "10px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={1.5}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    fontSize={18}
                    style={{
                      textAlign: "start",
                      fontWeight: 500,
                    }}
                  >
                    จำนวนงาน
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container>
                    <Grid style={{ paddingRight: "20px" }} item xs={6}>
                      <FieldSelect
                        name={"แสดงผล"}
                        options={[
                          { value: "count", name: "จำนวนงาน" },
                          { value: "compare", name: "ช่วงนี้เมื่อปีที่แล้ว" },
                          { value: "service", name: "ประเภทบริการ" },
                          { value: "truck_type", name: "ประเภทรถ" },
                          { value: "origin", name: "ภูมิภาคต้นทาง" },
                          { value: "destination", name: "ภูมิภาคปลายทาง" },
                        ]}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                        value={filterDashboard.display_count}
                        defaultValue={[]}
                        multiSelect={true}
                        onChange={(val) =>
                          setFilterDashboard({
                            ...filterDashboard,
                            display_count: val,
                          })
                        }
                        closePaddingBottom={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "end", gap: 10 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "end",
                          color: "primary.main",
                          fontSize: "14px",
                        }}
                      >
                        {"รวมทั้งหมด " + formatNumber(allWork) + " งาน"}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <ResponsiveContainer width="100%" height={445}>
                <ComposedChart
                  width={730}
                  height={434}
                  data={dataWorkMerge}
                  margin={{
                    top: 35,
                    right: 50,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#e1e9ea" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#e1e9ea" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey={"xname"}
                    style={{ fontSize: "10px", fontWeight: 500 }}
                  />
                  <YAxis
                    style={{ fontSize: "10px", fontWeight: 500 }}
                    tickFormatter={formatNumber}
                    // domain={[0, maxValue]}
                  />
                  <Tooltip content={<CustomTooltipCount />} />
                  <Legend content={<CustomLegendCount />} />
                  <CartesianGrid stroke="#f5f5f5" />
                  {filterDashboard.display_count.includes("compare") && (
                    <Area
                      dataKey="count2"
                      stroke={`#729094`}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    >
                      <LabelList
                        dataKey="count2"
                        position="top"
                        angle={-90}
                        fill={`#729094`}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          textAnchor: "start",
                        }}
                        // formatter={abbreviateNumber}
                      />
                    </Area>
                  )}
                  {filterDashboard.display_count.includes("count") && (
                    <Bar
                      dataKey="count"
                      barSize={20}
                      fill={`rgba(66, 71, 227,0.5)`}
                      stroke={`#4247e3`}
                      onClick={(data, index) => {
                        setBarDataCount(data.payload);
                        if (index > 0) {
                          setGraphCount1(dataWork[index - 1]);
                        } else {
                          setGraphCount1(null);
                        }
                        setGraphCount2(data.payload);
                      }}
                      shape={<CustomBarShape />}
                    >
                      <LabelList
                        dataKey="label"
                        content={(props) => {
                          const { x, y, value, width } = props;
                          const lines = value.split("\n");
                          const offset = 40;
                          return (
                            <text
                              x={x}
                              y={y}
                              fill="#4247e3"
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-90, ${x + 8}, ${y + 4})`}
                            >
                              {lines.map(
                                (line, index) =>
                                  index == 1 && (
                                    <tspan
                                      key={index}
                                      x={x + 14}
                                      dy={index * 10}
                                      style={{
                                        fontSize: index == 1 ? "12px" : "14px",
                                      }}
                                    >
                                      {line.replace(/[()]/g, "")}
                                    </tspan>
                                  )
                              )}
                            </text>
                          );
                        }}
                      />
                    </Bar>
                  )}

                  {filterDashboard.display_count.includes("service") && (
                    <Bar
                      dataKey="count_ftl"
                      barSize={20}
                      fill={`rgba(255, 8, 0,0.5)`}
                      stroke={`#ff0800`}
                      stackId="a"
                      onClick={(data, index) => {
                        setBarDataCount(data.payload);
                        if (index > 0) {
                          setGraphCount1(dataWork[index - 1]);
                        } else {
                          setGraphCount1(null);
                        }
                        setGraphCount2(data.payload);
                      }}
                      // shape={<CustomBarShape />}
                    />
                  )}
                  {filterDashboard.display_count.includes("service") && (
                    <Bar
                      dataKey="count_stl"
                      barSize={20}
                      fill={`rgba(0, 72, 255,0.5)`}
                      stroke={`#0048ff`}
                      stackId="a"
                      onClick={(data, index) => {
                        setBarDataCount(data.payload);
                        if (index > 0) {
                          setGraphCount1(dataWork[index - 1]);
                        } else {
                          setGraphCount1(null);
                        }
                        setGraphCount2(data.payload);
                      }}
                      shape={<CustomBarShape />}
                    />
                  )}

                  {filterDashboard.display_count.includes("truck_type") &&
                    masterCountTruck?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="b"
                          onClick={(data, index) => {
                            setBarDataCount(data.payload);
                            if (index > 0) {
                              setGraphCount1(dataWork[index - 1]);
                            } else {
                              setGraphCount1(null);
                            }
                            setGraphCount2(data.payload);
                          }}
                          // shape={<CustomBarShape />}
                        />
                      );
                    })}
                  {filterDashboard.display_count.includes("origin") &&
                    masterCountOrigin?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="c"
                          onClick={(data, index) => {
                            setBarDataCount(data.payload);
                            if (index > 0) {
                              setGraphCount1(dataWork[index - 1]);
                            } else {
                              setGraphCount1(null);
                            }
                            setGraphCount2(data.payload);
                          }}
                          // shape={<CustomBarShape />}
                        />
                      );
                    })}
                  {filterDashboard.display_count.includes("destination") &&
                    masterCountDestination?.map((val, index) => {
                      const baseColor = getRandomRGBA();
                      const fillColor = baseColor.replace(/[\d.]+\)$/g, "0.5)");
                      const strokeColor = baseColor.replace(/[\d.]+\)$/g, "1)");
                      return (
                        <Bar
                          key={val}
                          dataKey={val}
                          barSize={20}
                          fill={fillColor}
                          stroke={strokeColor}
                          stackId="d"
                          onClick={(data, index) => {
                            setBarDataCount(data.payload);
                            if (index > 0) {
                              setGraphCount1(dataWork[index - 1]);
                            } else {
                              setGraphCount1(null);
                            }
                            setGraphCount2(data.payload);
                          }}
                          // shape={<CustomBarShape />}
                        />
                      );
                    })}
                  {/* <LabelList
                      dataKey="target"
                      position="bottom"
                      style={{
                        fontSize: 10,
                        backgroundColor: "black",
                        fontWeight: 500,
                      }}
                      formatter={formatNumber}
                    />
                  </Line> */}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} sx={{ alignItems: "stretch" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ height: "100%", alignItems: "stretch" }}
                >
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>จำนวนงาน</p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              ประจํา
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {" " + barDataCount?.xname + " "}
                            </p>
                          </Typography>
                        </div>
                        {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {calculatePercentage(
                              Number(barData?.target),
                              Number(barData?.sum)
                            ) > 0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : calculatePercentage(
                                Number(barData?.target),
                                Number(barData?.sum)
                              ) < 0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                              // <FaWindowMinimize color="#FFC675" size={40} />
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                calculatePercentage(
                                  Number(barData?.target),
                                  Number(barData?.sum)
                                ) > 0
                                  ? "#27CE88"
                                  : calculatePercentage(
                                      Number(barData?.target),
                                      Number(barData?.sum)
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {barData
                              ? helper.addComma(
                                  calculatePercentage(
                                    Number(barData?.target),
                                    Number(barData?.sum)
                                  )
                                ) + " %"
                              : "-"}
                          </div>
                        </div> */}
                      </div>
                      <div>
                        <Grid container sx={{ height: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div style={{ fontSize: 14 }}>
                                จำนวนงาน :{" "}
                                {formatNumber(barDataCount?.count.toFixed(0))}{" "}
                                งาน
                              </div>
                              {/* <div style={{ fontSize: 14 }}>
                                เป้าหมาย : {formatNumber(barData?.target)} บาท
                              </div>
                              <div style={{ fontSize: 14 }}>
                                ส่วนต่าง :
                                {formatNumber(
                                  (
                                    Number(barData?.sum) -
                                    Number(barData?.target)
                                  ).toFixed(2)
                                ) + " บาท"}
                              </div> */}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ height: 172 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <Typography
                            fontSize={16}
                            style={{
                              textAlign: "start",
                              fontWeight: 500,
                            }}
                          >
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              เปลี่ยนแปลง
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              จาก
                              {filterDashboard.type_data_set === "day"
                                ? "วัน"
                                : filterDashboard.type_data_set === "week"
                                ? "สัปดาห์"
                                : filterDashboard.type_data_set === "month"
                                ? "เดือน"
                                : "ปี"}{" "}
                            </p>
                            <p style={{ margin: 0, lineHeight: 1 }}>
                              {graphCount1?.xname}
                            </p>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 0.4,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {calculatePercentage(
                              graphCount1?.count,
                              graphCount2?.count
                            ) > 0 ? (
                              <FaArrowTrendUp color="#27CE88" size={40} />
                            ) : calculatePercentage(
                                graphCount1?.count,
                                graphCount2?.count
                              ) < 0 ? (
                              <FaArrowTrendDown color="#FF316F" size={40} />
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                Number(
                                  calculatePercentage(
                                    graphCount1?.count,
                                    graphCount2?.count
                                  )
                                ) > 0
                                  ? "#27CE88"
                                  : Number(
                                      calculatePercentage(
                                        graphCount1?.count,
                                        graphCount2?.count
                                      )
                                    ) < 0
                                  ? "#FF316F"
                                  : "#FFC675",
                            }}
                          >
                            {graphCount1 && graph2
                              ? helper.addComma(
                                  calculatePercentage(
                                    graphCount1?.count,
                                    graphCount2?.count
                                  )
                                ) + " %"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Grid
                          container
                          justifyContent="space-between"
                          sx={{ height: "100%" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {!(
                                  graphCount2?.count === 0 &&
                                  graphCount1?.count === 0
                                )
                                  ? Number(
                                      graphCount2?.count - graphCount1?.count
                                    ) > 0
                                    ? "สูงกว่าช่วงก่อนหน้า"
                                    : Number(
                                        graphCount2?.count - graphCount1?.count
                                      ) < 0
                                    ? "ต่ำกว่าช่วงก่อนหน้า"
                                    : Number(
                                        graphCount2?.count - graphCount1?.count
                                      ) === 0
                                    ? "เท่ากับช่วงก่อนหน้า"
                                    : "-"
                                  : "-"}
                              </div>
                              {/* <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graph1 && graph2
                                  ? helper.addComma(graph2.sum - graph1.sum) +
                                    " บาท"
                                  : "-"}
                              </div> */}
                              <div
                                style={{
                                  textAlign: "start",
                                  fontSize: "14px",
                                }}
                              >
                                {graphCount1 && graphCount2
                                  ? formatNumber(
                                      graphCount2?.count - graphCount1?.count
                                    ) + " งาน"
                                  : "-"}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ height: 360 }}>
                <Paper
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    borderRadius: "10px",
                    height: "100%",
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    fontSize={16}
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontWeight: 500,
                    }}
                  >
                    10 อันดับลูกค้าที่ใช้งานมากที่สุด
                    <p style={{ margin: 0, lineHeight: 1 }}>
                      ประจำ
                      {filterDashboard.type_data_set === "day"
                        ? "วัน"
                        : filterDashboard.type_data_set === "week"
                        ? "สัปดาห์"
                        : filterDashboard.type_data_set === "month"
                        ? "เดือน"
                        : "ปี"}
                      {" " + barDataCount?.xname + " "}
                    </p>
                  </Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <div style={{ fontWeight: 500 }}>ชื่อ</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>
                        จำนวน
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end", fontWeight: 500 }}>%</div>
                    </Grid>
                  </Grid>
                  {barDataCount?.groups
                    ?.sort((a, b) => b.count - a.count)
                    .map(
                      (row, index) =>
                        index < 10 && (
                          <Grid container key={index} spacing={1}>
                            <Grid item xs={8}>
                              <LightTooltip title={row.company_name}>
                                <div
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "14px",
                                  }}
                                >
                                  {row.company_name}
                                </div>
                              </LightTooltip>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                style={{ textAlign: "end", fontSize: "14px" }}
                              >
                                {formatNumber(row.count)}
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div
                                style={{ textAlign: "end", fontSize: "14px" }}
                              >
                                {helper.addComma(
                                  (row.count / Number(barDataCount?.count)) *
                                    100
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )
                    )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={[1, 1]}
          sx={{ paddingTop: "8px" }}
          alignItems="stretch"
        >
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                จำนวนงานแยกตามประเภทบริการ
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barDataCount?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipCountPie />} />
                  <Legend content={<CustomLegendCountPie />} />
                  <Pie
                    data={barDataCount?.service
                      .filter(({ count }) => count !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { count }) => sum + count, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="count"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barDataCount?.service.filter(
                        (item) => item.count > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.count || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barDataCount &&
                      barDataCount?.service
                        .filter((item) => item.count !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                  {/* <Tooltip
                    formatter={(value, name, props) => {
                      const total = barDataCount?.service.reduce(
                        (sum, item) => sum + item.count,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return [`${percentage}%`, name];
                    }}
                    cursor={{ stroke: "none" }}
                  /> */}
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                จำนวนงานแยกตามประเภทรถ
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barDataCount?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipCountPie />} />
                  <Legend content={<CustomLegendCountPie />} />
                  <Pie
                    data={barDataCount?.truck
                      .filter(({ count }) => count !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { count }) => sum + count, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="count"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barDataCount?.truck.filter(
                        (item) => item.count > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.count || 0),
                        0
                      );
                      if (!total || value === undefined) return "";
                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barDataCount &&
                      barDataCount?.truck
                        .filter((item) => item.count !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                  {/* <Tooltip
                    formatter={(value, name, props) => {
                      const total = barDataCount?.truck.reduce(
                        (sum, item) => sum + item.value,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return [`${percentage}%`, name];
                    }}
                    cursor={{ stroke: "none" }}
                  /> */}
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                จำนวนงานแยกตามภูมิภาคต้นทาง
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barDataCount?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipCountPie />} />
                  <Legend content={<CustomLegendCountPie />} />
                  <Pie
                    data={barDataCount?.zone_origin
                      .filter(({ count }) => count !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { count }) => sum + count, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="count"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData = barDataCount?.zone_origin.filter(
                        (item) => item.count > 0
                      );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.count || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barDataCount &&
                      barDataCount?.zone_origin
                        .filter((item) => item.count !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                  {/* <Tooltip
                    formatter={(value, name, props) => {
                      const total = barData?.truck.reduce(
                        (sum, item) => sum + item.value,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return [`${percentage}%`, name];
                    }}
                    cursor={{ stroke: "none" }}
                  /> */}
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              sx={{
                bgcolor: "white",
                padding: "15px",
                borderRadius: "10px",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: 1,
                }}
              >
                จำนวนงานแยกตามภูมิภาคปลายทาง
              </Typography>
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                {barDataCount?.xname}
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart onClick={() => null}>
                  <Tooltip content={<CustomTooltipCountPie />} />
                  <Legend content={<CustomLegendCountPie />} />
                  <Pie
                    data={barDataCount?.zone_destination
                      .filter(({ count }) => count !== 0)
                      .map((item, _, arr) => ({
                        ...item,
                        total: arr.reduce((sum, { count }) => sum + count, 0),
                      }))}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={30}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="count"
                    fontSize={16}
                    fontWeight={500}
                    label={({ value }) => {
                      const filteredData =
                        barDataCount?.zone_destination.filter(
                          (item) => item.count > 0
                        );
                      const total = filteredData.reduce(
                        (sum, item) => sum + (item.count || 0),
                        0
                      );
                      if (!total || value === undefined) return "";

                      return `${((value / total) * 100).toFixed(2)}%`;
                    }}
                  >
                    {barDataCount &&
                      barDataCount?.zone_destination
                        .filter((item) => item.count !== 0)
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={getRandomRGBA(1)} />
                        ))}
                  </Pie>
                  {/* <Tooltip
                    formatter={(value, name, props) => {
                      const total = barData?.truck.reduce(
                        (sum, item) => sum + item.value,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return [`${percentage}%`, name];
                    }}
                    cursor={{ stroke: "none" }}
                  /> */}
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          {/* {dataFavShipper?.map((row, index) => (
                <Grid item xs={4} key={index}>
                  <GraphFavorite
                    profile_id={row.profile_id}
                    data={row?.data_graph}
                    title={row?.company_name}
                    maxValueY={row?.max_graph}
                    typeDataSet={filterDashboard.type_data_set}
                    refresh={() => handleGetFav()}
                  />
                </Grid>
              ))} */}
        </Grid>
        <Grid
          container
          spacing={[1, 1]}
          sx={{ paddingTop: "8px" }}
          alignItems="stretch"
        >
          <Grid item xs={4}>
            <GraphKPI
              data={[
                { ...dataTotalIn24hr, barColor: "#f59e36" },
                { ...dataTotalMatching, barColor: "#48d050" },
                { ...dataTotalInbound, barColor: "#3cbaec" },
                { ...dataTotalDelivery, barColor: "#8d6aeb" },
                { ...dataTotalDocument, barColor: "#e430b8" },
              ]}
              title="KPI สะสมรวมทุกด้าน"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              // barColor="#e93a3a"
              lineColor={`rgba(255, 49, 111,0.5)`}
              refresh={() => {
                getDataGraphInbound();
                getDataGraphDelivery();
                getDataGraphDocument();
                getDataGraphIn24hr();
                getDataGraphMatching();
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphKPI
              data={dataGraphIn24hr}
              title="KPI รับงานภายใน 24 ชั่วโมง"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              barColor="#f59e36"
              lineColor={`rgba(255, 49, 111,0.5)`}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphKPI
              data={dataGraphMatching}
              title="KPI จับคู่งานสำเร็จ"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              barColor="#48d050"
              lineColor={`rgba(255, 49, 111,0.5)`}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphKPI
              data={dataGraphInbound}
              title="KPI เข้ารับสินค้าตรงเวลา"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              barColor="#3cbaec"
              lineColor={`rgba(255, 49, 111,0.5)`}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphKPI
              data={dataGraphDelivery}
              title="KPI จัดส่งสินค้าตรงเวลา"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              barColor="#8d6aeb"
              lineColor={`rgba(255, 49, 111,0.5)`}
            />
          </Grid>
          <Grid item xs={4}>
            <GraphKPI
              data={dataGraphDocument}
              title="KPI จัดส่งเอกสารนำกลับตรงเวลา"
              maxValueY={100}
              onClickBar={() => {}}
              dataKeyX="xname"
              dataKeyBar="percentage"
              height={250}
              width={730}
              barColor="#e430b8"
              lineColor={`rgba(255, 49, 111,0.5)`}
            />
          </Grid>
        </Grid>
        <div
          style={{
            backgroundColor: "white",
            marginTop: "8px",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={3}>
              <FieldSelectArea
                levelArea={["provinces"]}
                name="จังหวัดต้นทาง"
                onChange={(val, item) => {
                  console.log("จังหวัดต้นทาง", val, item);
                  setFilterGraphLoc({
                    ...filterGraphLoc,
                    origin: val,
                  });
                }}
                value={filterGraphLoc.origin}
                showChild={true}
                valueType="name"
                closePaddingBottom
                selectAll
              />
            </Grid>
            <Grid item xs={3}>
              <div style={{ width: `100%` }}>
                <FieldSelectArea
                  levelArea={["provinces"]}
                  name="จังหวัดปลายทาง"
                  onChange={(val) => {
                    setFilterGraphLoc({
                      ...filterGraphLoc,
                      destination: val,
                    });
                  }}
                  value={filterGraphLoc.destination}
                  showChild={true}
                  valueType="name"
                  closePaddingBottom
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ width: `100%` }}>
                <FieldSelect
                  name={"ช่วง กม."}
                  options={[
                    { value: "all", name: "ทั้งหมด" },
                    { value: "<50 km", name: "<50 km" },
                    { value: "51-100 km", name: "51-100 km" },
                    { value: "101-400 km", name: "101-400 km" },
                    { value: ">400 km", name: ">400 km" },
                  ]}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  // defaultValue={"day"}
                  value={filterGraphLoc.distance_range}
                  onChange={(val) =>
                    setFilterGraphLoc({
                      ...filterGraphLoc,
                      distance_range: val,
                    })
                  }
                  closePaddingBottom
                  // closePaddingBottom={true}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              {/* <div style={{ flex: 0.7 }}> */}
              <FieldInput
                value={filterGraphLoc.greater_than ?? 0}
                label={"ไม่รวมงานที่น้อยกว่า"}
                style={{ width: "100%" }}
                onChange={(val) =>
                  setFilterGraphLoc({
                    ...filterGraphLoc,
                    greater_than: val.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">กม.</InputAdornment>
                }
                closePaddingBottom
              />
              {/* </div>
              <div
                style={{ flex: 0.3, display: "flex", justifyContent: "center" }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "secondary.main",
                  }}
                  onClick={() => {
                    setShowGetProfile(true);
                  }}
                >
                  <Search sx={{ color: "white", fontSize: 30 }} />
                </Avatar>
              </div> */}
            </Grid>
          </Grid>
        </div>
        {dataGraphByGroup && (
          <Grid
            container
            spacing={[1, 1]}
            sx={{ paddingTop: "8px" }}
            alignItems="stretch"
          >
            <Grid item xs={4}>
              <Paper
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  borderRadius: "10px",
                  height: "100%",
                  boxShadow: "none",
                }}
              >
                <div style={{ flex: 0.4, fontSize: "18px", fontWeight: 500 }}>
                  รวมทุกกลุ่มรถ
                </div>
                {console.log("dataGraphByGroup?.data", dataGraphByGroup)}
                <ResponsiveContainer width={"100%"} height={260}>
                  <BarChart
                    width={730}
                    height={400}
                    data={dataGraphByGroup}
                    margin={{
                      top: 80,
                      right: 50,
                    }}
                  >
                    <XAxis
                      dataKey="title"
                      style={{ fontSize: "10px", fontWeight: 500 }}
                      interval={0}
                      tick={{ angle: -10, textAnchor: "end" }}
                    />
                    <YAxis
                      style={{ fontSize: "10px", fontWeight: 500 }}
                      tickFormatter={formatNumber}
                      // domain={[0, 50]}
                    />
                    <Tooltip content={<CustomTooltipGroup />} />
                    <Legend content={<CustomLegendGroup />} width={"100%"} />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar
                      dataKey="totalCarrier"
                      stroke={`rgba(53, 91, 170,1)`}
                      fill={`rgba(53, 91, 170,0.5)`}
                      shape={<CustomBarShape />}
                    >
                      <LabelList
                        dataKey="totalCarrier"
                        fill={`#355BAA`}
                        content={(props) => {
                          const { x, y, value, width, target, fill } = props;
                          return (
                            <text
                              x={x + 10}
                              y={y + 10}
                              fill={fill}
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-70, ${x}, ${y})`}
                            >
                              {Number(value) === 0
                                ? "-"
                                : helper.formatNumber(value)}
                            </text>
                          );
                        }}
                      />
                    </Bar>
                    <Bar
                      dataKey="totalShipper"
                      stroke={`rgba(255, 139, 0,1)`}
                      fill={`rgba(255, 139, 0,0.5)`}
                      shape={<CustomBarShape />}
                    >
                      <LabelList
                        dataKey="labelShipper"
                        fill={`rgba(255, 139, 0,1)`}
                        content={(props) => {
                          const { x, y, value, width, target, fill } = props;
                          const val = value.split(",");
                          return (
                            <text
                              x={x + 10}
                              y={y + 10}
                              fill={fill}
                              textAnchor="top"
                              fontSize="14px"
                              fontWeight="500"
                              transform={`rotate(-70, ${x}, ${y})`}
                            >
                              <tspan>
                                {Number(val[0]) === 0
                                  ? "-"
                                  : helper.formatNumber(val[0])}
                              </tspan>
                              <tspan> </tspan>
                              <tspan
                                fill={
                                  Number(val[1]) > 0
                                    ? "rgb(72, 208, 80)"
                                    : Number(val[1]) < 0
                                    ? "rgb(255, 49, 111)"
                                    : "#FFC675"
                                }
                              >
                                {val[1] ? `${val[1]} %` : ""}
                              </tspan>
                            </text>
                          );
                        }}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
            {dataGraphByGroup?.map((row, index) => (
              <Grid item xs={4} key={index}>
                <GraphByTruckGroup
                  data={row?.data}
                  title={row?.title}
                  maxValueY={row?.maxValueY}
                  height={200}
                  width={730}
                  filter={{ ...filterDashboard, ...filterGraphLoc }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <ModalWork
        close={() => {
          setShowGetProfile(false);
          setProfileList([]);
          setTotalProfile(0);
          setFilterProfile({
            ...filterProfile,
            search_val: "",
            page: 1,
            per_page: 3,
            total: 0,
          });
        }}
        open={showGetProfile}
        minHeight
        enableClose
      >
        <div style={{ padding: 20 }}>
          <div style={{ textAlign: "center" }}>โปรไฟล์ลูกค้า</div>
          <Line />
          <TextField
            id="search_val"
            variant="standard"
            style={{
              paddingTop: "16px",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={filterProfile.search_val}
            onChange={(val) => {
              setFilterProfile({
                ...filterProfile,
                search_val: val.target.value,
              });
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            color="secondary"
            onClick={() => {
              getProfile();
            }}
          >
            ค้นหา
          </Button>
          <div style={{ marginTop: 10 }}>
            {profileList.map((ele, index) => {
              return (
                <div
                  key={"profile" + index}
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    color:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    console.log(ele);
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: ele.user_profile.profile.id,
                      profile_name: ele.user_profile.profile.company_name,
                    });
                  }}
                >
                  <div>{ele.user_code}</div>
                  <div style={{ fontSize: 20, fontWeight: 500 }}>
                    {ele.user_profile.profile.company_name}
                  </div>
                  <div>
                    {"สร้างบัญชีเมื่อ: " +
                      helper.momentDateTime(ele.created_at)}
                  </div>
                </div>
              );
            })}
            <Pagination
              pagination={{
                page: filterProfile.page,
                per_page: filterProfile.per_page,
                total: totalProfile,
              }}
              setPagination={(val) => {
                console.log(val);
                setFilterProfile({
                  ...filterProfile,
                  per_page: val.per_page,
                  page: val.page,
                });
              }}
            />
            {filterDashboard.profile_id && profileList.length > 0 ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                color="secondary"
                onClick={async () => {
                  setShowGetProfile(false);
                  setProfileList([]);
                  setTotalProfile(0);
                  setFilterProfile({
                    ...filterProfile,
                    search_val: "",
                    page: 1,
                    per_page: 3,
                    total: 0,
                  });
                }}
              >
                ยืนยัน
              </Button>
            ) : null}
          </div>
        </div>
      </ModalWork>
      {modalUploadTarget && (
        <ModalAddTarget
          type="target"
          open={modalUploadTarget}
          handleClose={() => {
            setModalUploadTarget(false);
            getDataCount();
          }}
        />
      )}
    </Container>
  );
};

export default NewDashboard;
