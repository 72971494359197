import instance from "../../axiosInstance";

const getDashboardWork = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardWorkByProfile = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/work-by-profile`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIInbound = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/kpi-inbound`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIDelivery = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/kpi-delivery`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIDocument = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/kpi-document`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIIn24hr = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/kpi-in24hr`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardKPIMatching = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/kpi-matching`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getProfileByGrade = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/get-profile-by-grade`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const uploadTaget = (body) => {
  try {
    return instance.post(`/api/new-dashboard/work/upload-target`, body, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};
const downloadTaget = () => {
  try {
    return instance.get(`/api/new-dashboard/work/target`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      responseType: "blob",
    });
  } catch (error) {
    console.log("error", error);
  }
};

const downloadTagetProfile = (profileID) => {
  try {
    return instance.get(`/api/new-dashboard/work/target-profile/${profileID}`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      responseType: "blob",
    });
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetProfile = (profileID, body) => {
  try {
    return instance.post(
      `/api/new-dashboard/work/upload-target-profile/${profileID}`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getDataGraphByGroup = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/get-graph-by-group`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getListTruckByGroup = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work/get-list-truck-by-group`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getTargetKPI = async () => {
  try {
    return instance.get(`/api/new-dashboard/work/get-target-kpi`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const editTargetKPI = async (body) => {
  try {
    return instance.patch(`/api/new-dashboard/work/update-target-kpi`, body, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardTrade = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/trade`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const downloadTagetTrade = (industryID) => {
  try {
    return instance.get(
      `/api/new-dashboard/trade/target-industry/${industryID}`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetIndustry = (profileID, body) => {
  try {
    return instance.post(
      `/api/new-dashboard/trade/upload-target-industry/${profileID}`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getAllTradeProjectForGraph = (param) => {
  try {
    return instance.get(`/api/new-dashboard/trade/all-trade-project`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getFavProjectTrade = (project_id, param) => {
  try {
    return instance.get(
      `/api/new-dashboard/trade/project-on-dashboard/${project_id}`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        params: param,
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const downloadTagetProject = (project_id) => {
  try {
    return instance.get(
      `/api/new-dashboard/trade/target-project/${project_id}`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetProject = (project_id, body) => {
  try {
    return instance.post(
      `/api/new-dashboard/trade/upload-target-project/${project_id}`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getDashboardTruckRental = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/truck-rental`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetTruckRental = (body) => {
  try {
    return instance.post(
      `/api/new-dashboard/truck-rental/upload-target`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};
const downloadTagetTruckRental = () => {
  try {
    return instance.get(`/api/new-dashboard/truck-rental/target`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      responseType: "blob",
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllTruckTenantProjectForGraph = (param) => {
  try {
    return instance.get(
      `/api/new-dashboard/truck-rental/all-truck-tenant-project`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        params: param,
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getFavTruckTenantProject = (truck_tenant_project_id, param) => {
  try {
    return instance.get(
      `/api/new-dashboard/truck-rental/favorite-truck-tenant-project/${truck_tenant_project_id}`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        params: param,
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};
const downloadTagetTruckTenantProject = (truck_tenant_project_id) => {
  try {
    return instance.get(
      `/api/new-dashboard/truck-rental/target-truck-tenant-project/${truck_tenant_project_id}`,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const uploadTagetTruckTenantProject = (truck_tenant_project_id, body) => {
  try {
    return instance.post(
      `/api/new-dashboard/truck-rental/upload-target-truck-tenant-project/${truck_tenant_project_id}`,
      body,
      {
        baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getDashboardWork,
  getDashboardWorkByProfile,
  getDashboardKPIInbound,
  getDashboardKPIDelivery,
  getDashboardKPIDocument,
  getDashboardKPIIn24hr,
  getDashboardKPIMatching,
  getProfileByGrade,
  downloadTagetProfile,
  uploadTagetProfile,
  downloadTaget,
  uploadTaget,
  getDataGraphByGroup,
  getListTruckByGroup,
  getTargetKPI,
  editTargetKPI,
  getDashboardTrade,
  downloadTagetTrade,
  uploadTagetIndustry,
  getAllTradeProjectForGraph,
  getFavProjectTrade,
  downloadTagetProject,
  uploadTagetProject,
  getDashboardTruckRental,
  uploadTagetTruckRental,
  downloadTagetTruckRental,
  getAllTruckTenantProjectForGraph,
  getFavTruckTenantProject,
  downloadTagetTruckTenantProject,
  uploadTagetTruckTenantProject,
};
