import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuRentalFinance } from "utils/submenuoptions";
import FieldDatePicker from "components/field/FieldDatePicker";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import UpdateStatusButton from "components/button/finance/UpdateStatusButton";
import * as currency from "currency.js";
import SelectRowValue from "components/button/SelectRowValue";
import { createInvoice, getUnpaid } from "utils/api/rental-finance/tenant";
import { wht_options } from "utils/wht_options";
import AuthContext from "components/context/AuthContext";

const TruckTenantUnpiad = () => {
  const { adminAuth } = useContext(AuthContext);
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "create_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    trade_transport_ids: [],
    rental_amount: 0,
    rental_ot: 0,
    amount_per_trip: 0,
    vat_per_trip: 0,
    total_per_trip: 0,
    decimal: 2,
    unit_price: "",
    unit_price_ot: "",
    credit_remark: "",
    payment_due_date: moment().format("YYYY-MM-DD kk:mm"),
    wht_seleted: `{ wht_rate: 5, wht_service: "Rent" }`,
    wht_service: "Rent",
    wht_rate: 5,
    wht: 0,
  });
  const [error, setError] = useState({
    actual_date: false,
    trade_transport_ids: false,
    rental_amount: false,
    amount_per_trip: false,
    vat_per_trip: false,
    total_per_trip: false,
    decimal: false,
    payment_due_date: false,
    wht_service: false,
    wht_rate: false,
    wht: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const [calculateWHT, setCalculateWHT] = useState(0);

  useEffect(() => {
    const totalPerTrip = parseFloat(
      String(updateStatusForm?.total_per_trip).replace(/,/g, "")
    );
    setCalculateWHT(totalPerTrip - updateStatusForm?.wht);
  }, [updateStatusForm]);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    // setActiveSubMenu(0);
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการเช่ารถ",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้เช่า",
        path: null,
      },
      {
        label: "รอแจ้งหนี้",
        path: null,
      },
    ]);
    handleSearch();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuRentalFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(0);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const truck_tenant_id = rowSelected[0].truck_tenant_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.truck_tenant_id !== truck_tenant_id) {
            // if (item.key !== rowSelected[0].key) {
            //เปลี่นนเป็นให้เลือกได้รายการเดียว
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getUnpaid(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.truck_tenant_id)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const truck_tenant_id = rowSelected[0].truck_tenant_id;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = truck_tenant_id !== item.truck_tenant_id;
              // rowDisabled = rowSelected[0].key !== item.key;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (name === "actual_date" || name === "payment_due_date") {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "decimal") {
      const value = event;
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["rent_order_ids"].push(val["key"]);
            } else if (["total_tenant"].includes(key)) {
              result["amount_per_trip"] = currency(
                result["amount_per_trip"]
              ).add(val[key]).value;
            } else if (["tenant_price"].includes(key)) {
              if (val[key] !== null) {
                result["rental_amount"] = currency(result["rental_amount"]).add(
                  val[key]
                ).value;
              }
            } else if (["tenant_ot"].includes(key)) {
              if (val[key] !== null) {
                result["rental_ot"] = currency(result["rental_ot"]).add(
                  val[key]
                ).value;
              }
            }
          });
          return result;
        },
        {
          rent_order_ids: [],
          amount_per_trip: 0,
          rental_amount: 0,
          rental_ot: 0,
        }
      );
      const vat = currency(groupdata.amount_per_trip, {
        precision: value,
      }).multiply(0.07).value;
      const total = currency(groupdata.amount_per_trip).add(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display",
        value
      );
      const vat_per_trip = helper.formatNumeric(vat, "display", value);
      const total_per_trip = helper.formatNumeric(total, "display", value);
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
      }));
    } else if (name === "wht") {
      let correctString = event.replace(/(\w+)\s*:/g, '"$1":');
      let jsonObject = JSON.parse(correctString);
      const wht = currency(updateStatusForm.amount_per_trip)
        .multiply(jsonObject.wht_rate)
        .divide(100).value;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        wht_seleted: event,
        wht_rate: jsonObject.wht_rate,
        wht_service: jsonObject.wht_service,
        wht: wht,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      if (name === "vat_per_trip") {
        const amount = updateStatusForm.amount_per_trip;
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
          total_per_trip: currency(amount, {
            precision: updateStatusForm.decimal,
            symbol: "",
          })
            .add(value)
            .format(),
        }));
      } else if (name === "credit_remark") {
        const lines = value.split("\n").slice(0, 8);
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          credit_remark: lines.join("\n"),
        }));
      } else {
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["trade_transport_ids"].push(val["key"]);
            } else if (["total_tenant"].includes(key)) {
              result["amount_per_trip"] = currency(
                result["amount_per_trip"]
              ).add(val[key]).value;
            } else if (["payment_date_forecast"].includes(key)) {
              if (val[key] !== null) {
                result["payment_date_forecast"].push(val[key]);
              }
            } else if (["tenant_price"].includes(key)) {
              if (val[key] !== null) {
                result["rental_amount"] = currency(result["rental_amount"]).add(
                  val[key]
                ).value;
              }
            } else if (["tenant_ot"].includes(key)) {
              if (val[key] !== null) {
                result["rental_ot"] = currency(result["rental_ot"]).add(
                  val[key]
                ).value;
              }
            }
          });
          return result;
        },
        {
          trade_transport_ids: [],
          amount_per_trip: 0,
          payment_date_forecast: [],
          rental_amount: 0,
          rental_ot: 0,
        }
      );
      const rental_amount = helper.formatNumeric(
        groupdata.rental_amount,
        "display"
      );
      const rental_ot = helper.formatNumeric(groupdata.rental_ot, "display");
      const vat = currency(groupdata.amount_per_trip).multiply(0.07).value;
      const total = currency(groupdata.amount_per_trip).add(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display"
      );
      const vat_per_trip = helper.formatNumeric(vat, "display");
      const total_per_trip = helper.formatNumeric(total, "display");
      let min_payment_date_forecast = null;
      const wht = currency(amount_per_trip)
        .multiply(updateStatusForm.wht_rate)
        .divide(100).value;
      if (groupdata.payment_date_forecast.length > 0) {
        min_payment_date_forecast = new Date(
          Math.min(
            ...groupdata.payment_date_forecast.map((date) => new Date(date))
          )
        ).toISOString();
      }
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        trade_transport_ids: groupdata.trade_transport_ids,
        rental_amount: rental_amount,
        rental_ot: rental_ot,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        decimal: 2,
        credit_remark: "",
        payment_due_date:
          min_payment_date_forecast === null
            ? moment().format("YYYY-MM-DD kk:mm")
            : moment(min_payment_date_forecast).format("YYYY-MM-DD kk:mm"),
        wht: wht,
      }));
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      trade_transport_ids: updateStatusForm.trade_transport_ids,
      actual_date: updateStatusForm.actual_date,
      amount_per_trip: currency(updateStatusForm.amount_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      vat_per_trip: currency(updateStatusForm.vat_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      total_per_trip: currency(updateStatusForm.total_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      decimal: updateStatusForm.decimal,
      unit_price: updateStatusForm.unit_price,
      unit_price_ot: updateStatusForm.unit_price_ot,
      credit_remark: updateStatusForm.credit_remark,
      payment_due_date: updateStatusForm.payment_due_date,
      rental_amount: currency(updateStatusForm.rental_amount, {
        precision: updateStatusForm.decimal,
      }).value,
      rental_ot: currency(updateStatusForm.rental_ot, {
        precision: updateStatusForm.decimal,
      }).value,
      unit_price_ot: updateStatusForm.unit_price_ot,
      wht_rate: updateStatusForm.wht_rate,
      wht_service: updateStatusForm.wht_service,
      wht: updateStatusForm.wht,
    };
    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (
        [
          "actual_date",
          "trade_transport_ids",
          "rental_amount",
          "amount_per_trip",
          "vat_per_trip",
          "total_per_trip",
          "decimal",
          "payment_due_date",
        ].includes(key)
      ) {
        if (
          value === undefined ||
          value === null ||
          value === ""
          // || value === 0
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });
    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    const check_payment_due_date = moment(
      updateStatusForm.payment_due_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    newError.actual_date = !check_actual_date;
    newError.payment_due_date = !check_payment_due_date;
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createInvoice(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
        return false;
      }
    } else {
      setError(newError);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "create_date", name: "วันที่สร้างรายการ" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่ใบสั่งซื้อ" },
                { value: "id", name: "ไอดี" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton
              title="แจ้งหนี้"
              onClick={handleOnclickUpdateStatusButton}
              onConfirm={handleSubmitUpdateStatus}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton>
            <FormControl>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการแจ้งหนี้ *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันที่กำหนดชำระเงิน*"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
              value={updateStatusForm.payment_due_date}
              onChange={(e) =>
                handleUpdatestatusFormChange("payment_due_date", e)
              }
              showTime
              error={error.payment_due_date}
              helperText={error.payment_due_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนใบสั่งซื้อ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.trade_transport_ids.length}
              readOnly
              error={error.trade_transport_ids}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="จำนวนทศนิยม"
              options={[
                { value: 2, name: "2" },
                { value: 4, name: "4" },
                { value: 6, name: "6" },
              ]}
              value={updateStatusForm.decimal}
              onChange={(e) => handleUpdatestatusFormChange("decimal", e)}
              error={error.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาสินค้าที่เช่า"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.rental_amount}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.rental_amount}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคา OT ผู้เช่า"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.rental_ot}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.rental_ot}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาเช่า"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.amount_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.amount_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมภาษีมูลค่าเพิ่ม"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.vat_per_trip}
              onChange={(e) => handleUpdatestatusFormChange("vat_per_trip", e)}
              type="float"
              precision={updateStatusForm.decimal}
              error={error.vat_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="ราคารวมภาษีมูลค่าเพิ่ม"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.total_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.total_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="อัตรา หัก ณ ที่จ่าย"
              options={wht_options}
              value={updateStatusForm.wht_seleted}
              onChange={(e) => handleUpdatestatusFormChange("wht", e)}
              error={error.decimal}
              type="wht"
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label=" หัก ณ ที่จ่าย"
              value={updateStatusForm.wht}
              onChange={(e) =>
                handleUpdatestatusFormChange(" หัก ณ ที่จ่าย", e)
              }
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมราคาเช่าสุทธิหลังหักภาษี"
              value={helper.addComma(calculateWHT)}
              // onChange={(e) =>
              //   handleUpdatestatusFormChange(" หัก ณ ที่จ่าย", e)
              // }
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="Unit Price ค่าเช่า (free key)"
              value={updateStatusForm.unit_price}
              onChange={(e) => handleUpdatestatusFormChange("unit_price", e)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="Unit Price OT (free key)"
              value={updateStatusForm.unit_price_ot}
              onChange={(e) => handleUpdatestatusFormChange("unit_price_ot", e)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="หมายเหตุ"
              value={updateStatusForm.credit_remark}
              onChange={(e) => handleUpdatestatusFormChange("credit_remark", e)}
              multiline={true}
              maxRows={4}
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "order_code",
        label: "เลขที่ใบสั่งซื้อ",
        tdclass: "text-center",
        render: "key",
      },
      {
        key: "puchase_date",
        label: "วันที่สั่งซื้อ / วันสุดท้ายของรอบ",
        render: "puchase&end_date",
      },
      {
        key: "tenant_name",
        label: "ผู้เช่า",
      },
      {
        key: "project_name",
        label: "โครงการ",
      },
      {
        key: "lessor_name",
        label: "ผู้ให้เช่า",
      },
      {
        key: "product_name",
        label: "ชื่อสินค้า",
      },
      {
        key: "tenant_amount",
        tdclass: "text-end",
        label: "จำนวนสินค้าที่เช่า",
        render: "amount&unit",
      },
      {
        key: "tenant_price",
        label: "รวมราคาสินค้าที่เช่า (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "tenant_ot",
        label: "รวมราคา OT ผู้เช่า",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "lessor_price",
        label: "รวมราคาสินค้าที่ให้เช่า (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "lessor_ot",
        label: "รวมราคา OT ผู้ให้เช่า",
        tdclass: "text-end",
        render: "currency",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="buyer"
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TruckTenantUnpiad;
